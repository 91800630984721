import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	container: {
		display: "flex",
		gap: "var(--spacing-x3, 12px)",
		width: "100%",
		alignItems: "center"
	},
	title: {
		display: "flex",
		flexDirection: "column",
		alignItems: "start",
		overflow: "hidden",
		justifyContent: "center",
		width: "100%"
	},
	content: {
		"&.deleted": {
			"&::after": {
				color: "var(--color-red)",
				content: "'*'"
			}
		}
	},
	text: {
		width: "100%"
	},
	iconWithEmail: {
		fontSize: "28px",
		height: "28px",
		width: "28px"
	},
	icon: {
		fontSize: "24px",
		height: "24px",
		width: "24px"
	}
});
