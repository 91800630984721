import { getUser } from "api/user";
import { UserModel } from "models/UserModel";
import { setLogrocketUserScope } from "utils/logrocket";
import constate from "constate";
import { useCallback, useEffect, useRef, useState } from "react";
import SessionExpiredError from "utils/errors/sessionExpiredError";
import { useAuthContext } from "./authContext";

const useAuthenticatedUser = () => {
	const [user, setUser] = useState<UserModel | null>(null);
	const [error, setError] = useState<Error | null>(null);
	const { isLoggedIn, getTokenData, signOut } = useAuthContext();
	const loadingRef = useRef(false);

	const updateUser = useCallback((newUser: UserModel) => {
		setUser(newUser);
		setLogrocketUserScope(newUser);
	}, []);

	const loadUser = useCallback(async () => {
		if (!isLoggedIn) {
			return;
		}
		if (loadingRef.current) {
			return;
		}
		setError(null);
		try {
			loadingRef.current = true;
			const newUser = await getUser();
			updateUser(newUser);
		} catch (err) {
			if (err instanceof SessionExpiredError) {
				window.location.reload();
				return;
			}
			setError(err as Error);
		}
		loadingRef.current = false;
	}, [isLoggedIn, updateUser]);

	const clearUser = useCallback(() => {
		setUser(null);
		setError(null);
	}, []);

	useEffect(() => {
		if (user) {
			const tokenData = getTokenData();
			if (!tokenData || tokenData.userRole !== user.role) {
				signOut();
			}
		}
	}, [getTokenData, signOut, user]);

	return {
		state: { user, error },
		actions: { loadUser, clearUser, updateUser }
	};
};

export const [AuthenticatedUserProvider, useAuthenticatedUserContext] = constate(useAuthenticatedUser);
