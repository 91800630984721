import { List, Map } from "immutable";
import { IntegrationActorModel } from "models/IntegrationActorModel";
import { IntegrationModel } from "models/IntegrationModel";
import { IntegrationResourceModel } from "models/IntegrationResourceModel";
import { IntegrationResourceRoleModel } from "models/IntegrationResourceRoleModel";
import { UserEntitlementModel } from "models/UserEntitlementModel";
import { apiReq, toQuerystring } from "utils/api/apiReq";

type TUserEntitlementsFilters = {
	roleId?: string;
	resourceId?: string;
	resourceType?: string;
	integrationId: string;
	accountId?: string;
	withUnmanaged?: boolean;
};

export async function getUserEntitlements(filters: TUserEntitlementsFilters): Promise<List<UserEntitlementModel>> {
	const query = toQuerystring(filters);
	const { data } = await apiReq("GET", `/v1/user/entitlements?${query}`);
	return List(data.map((permission: unknown) => UserEntitlementModel.fromServerData(permission)));
}

export async function getUserEntitlementsIntegrations(): Promise<Map<string, IntegrationModel>> {
	const { data } = await apiReq("GET", `/v1/user/entitlements/integrations`);

	return Map<string, IntegrationModel>(
		data
			.map((integration: unknown) => IntegrationModel.fromServerData(integration))
			.map((integration: IntegrationModel) => [integration.id, integration])
	);
}

export async function getUserEntitlementsResourceTypes(integrationId: string, accountId?: string): Promise<string[]> {
	const query = toQuerystring({ integrationId, accountId });
	const { data } = await apiReq("GET", `/v1/user/entitlements/resourceTypes?${query}`);

	return data;
}

export async function getUserEntitlementsResources(
	integrationId: string,
	resourceType?: string,
	accountId?: string
): Promise<Map<string, IntegrationResourceModel>> {
	const query = toQuerystring({ integrationId, accountId, resourceType });
	const { data } = await apiReq("GET", `/v1/user/entitlements/resources?${query}`);

	return Map<string, IntegrationResourceModel>(
		data
			.map((resource: unknown) => IntegrationResourceModel.fromServerData(resource))
			.map((resource: IntegrationResourceModel) => [resource.id, resource])
	);
}

export async function getUserEntitlementsRoles(
	resourceId: string,
	accountId?: string
): Promise<Map<string, IntegrationResourceRoleModel>> {
	const query = toQuerystring({ resourceId, accountId });
	const { data } = await apiReq("GET", `/v1/user/entitlements/roles?${query}`);

	return Map<string, IntegrationResourceRoleModel>(
		data
			.map((role: unknown) => IntegrationResourceRoleModel.fromServerData(role))
			.map((role: IntegrationResourceRoleModel) => [role.id, role])
	);
}

export async function getUserEntitlementsAccounts(integrationId: string): Promise<Map<string, IntegrationActorModel>> {
	const query = toQuerystring({ integrationId });
	const { data } = await apiReq("GET", `/v1/user/entitlements/accounts?${query}`);

	return Map<string, IntegrationActorModel>(
		data
			.map((account: unknown) => IntegrationActorModel.fromServerData(account))
			.map((account: IntegrationActorModel) => [account.id, account])
	);
}
