import React from "react";
import { WorkflowsIcon } from "components/ui/Icons/WorkflowsIcon";
import { ApprovalAlgorithmModel } from "models/ApprovalAlgorithmModel";
import { TooltipOnOverflow } from "components/ui/TooltipOnOverflow";
import { SelectOption } from "../SelectOption/SelectOption";

const INHERIT = "inherit" as const;
type TInherit = typeof INHERIT;

export interface IWorkflowOptionProps {
	disabled?: boolean;
	onSelect: (event: React.SyntheticEvent, value: ApprovalAlgorithmModel | TInherit) => void;
	selected?: boolean;
	workflow: ApprovalAlgorithmModel | TInherit;
	text?: string;
}
export const WorkflowOption: FC<IWorkflowOptionProps> = ({
	disabled = false,
	onSelect,
	workflow,
	text,
	selected = false
}) => {
	return (
		<SelectOption
			onSelect={onSelect}
			selected={selected}
			disabled={disabled}
			value={workflow}
			PrefixIcon={workflow !== INHERIT ? WorkflowsIcon : undefined}>
			{workflow === INHERIT ? (
				<TooltipOnOverflow content={text} textVariant="body_reg" />
			) : (
				<TooltipOnOverflow content={workflow.name} textVariant="body_reg" />
			)}
		</SelectOption>
	);
};
