import React, { useCallback, useEffect, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Map } from "immutable";
import { useStepperContext } from "components/common/Stepper/stepperContext";
import { PageTitleContent } from "components/templates/PageTitleContentTemplate";
import { IconButton } from "components/ui/IconButton";
import { CloseIcon } from "components/ui/Icons/CloseIcon";
import { DURATION_MAP, TDurationUnit, TTicketDuration } from "utils/durationsOptions";
import { DurationCard } from "components/common/DurationCard";
import { IconPrefix } from "components/ui/IconPrefix";
import { DurationIcon } from "components/ui/Icons/DurationIcon";
import { useAvailableDurations } from "hooks/useAvailableDurations";
import { useNewRequestFormContext } from "../../newRequestFormContext";
import { useStyles } from "./styles";

type TDurationRowProps = {
	unit: TDurationUnit | "unlimited";
	availableDurations: TTicketDuration[];
	selectedDuration?: TTicketDuration | null;
	onSelect: (duration: TTicketDuration) => void;
};

const DurationRow: FC<TDurationRowProps> = ({ unit, availableDurations, selectedDuration, onSelect }) => {
	const classes = useStyles();
	const { t } = useTranslation();

	const sortedDurations = useMemo(() => availableDurations.sort((left, right) => left - right), [availableDurations]);

	return (
		<div className={classes.durationUnitRow}>
			<IconPrefix size="large" semibold Icon={DurationIcon} content={t(`common.durations.names.long.${unit}`)} />
			<div className={classes.durationUnitRowCards}>
				{sortedDurations.map(duration => (
					<DurationCard
						key={duration}
						duration={duration}
						selected={duration === selectedDuration}
						onClick={onSelect}
					/>
				))}
			</div>
		</div>
	);
};

export const SelectDurationStep: FC<{ onExit: () => void }> = ({ className, innerRef, onExit }) => {
	const { t } = useTranslation();
	const classes = useStyles();
	const {
		state: { requestTargets, receiverUser, duration: selectedDuration },
		actions: { changeDuration }
	} = useNewRequestFormContext();
	const { durations } = useAvailableDurations(requestTargets, receiverUser);
	const hasMovedToNextStep = useRef(Boolean(selectedDuration));
	const {
		state: { canContinue },
		actions: { setCanContinue, nextStep }
	} = useStepperContext();

	useEffect(() => {
		setCanContinue(!!selectedDuration);
	}, [selectedDuration, setCanContinue]);

	useEffect(() => {
		if (canContinue && !hasMovedToNextStep.current) {
			nextStep();
			hasMovedToNextStep.current = true;
		}
	}, [canContinue, nextStep]);

	const toggleDuration = useCallback(
		(newDuration: TTicketDuration) => {
			changeDuration(newDuration !== selectedDuration ? newDuration : null);
		},
		[changeDuration, selectedDuration]
	);

	const durationsByUnit = useMemo(() => {
		const grouped = durations.reduce((acc, availableDuration) => {
			if (availableDuration === -1) {
				acc = acc.set("unlimited", [-1]);
				return acc;
			}
			const [_, durationUnit] = DURATION_MAP.get(`${availableDuration}`) || [null, null];
			if (!durationUnit) return acc;
			if (!acc.has(durationUnit)) {
				acc = acc.set(durationUnit, []);
			}
			acc.get(durationUnit)!.push(availableDuration);
			return acc;
		}, Map<TDurationUnit | "unlimited", TTicketDuration[]>());
		return (
			grouped
				.entrySeq()
				// sort by the duration, so smaller durations are first, and unlimited is last
				.sortBy(([_, groupedDurations]) => (groupedDurations[0] === -1 ? Number.MAX_SAFE_INTEGER : groupedDurations[0]))
				.toArray()
		);
	}, [durations]);

	return (
		<PageTitleContent innerRef={innerRef} className={className} noTransition>
			<PageTitleContent.Header>
				<PageTitleContent.Header.Top>
					<PageTitleContent.Header.Actions>
						<IconButton size="large" onClick={onExit}>
							<CloseIcon />
						</IconButton>
					</PageTitleContent.Header.Actions>
				</PageTitleContent.Header.Top>
				<PageTitleContent.Header.Bottom>
					<PageTitleContent.Header.Title
						title={t("pages.newRequest.selectDurationStep.title")}
						subtitle={t("pages.newRequest.selectDurationStep.subtitle")}
					/>
				</PageTitleContent.Header.Bottom>
			</PageTitleContent.Header>
			<PageTitleContent.Body>
				<div className={classes.container}>
					{durationsByUnit.map(([unit, availableDurations]) => (
						<DurationRow
							key={unit}
							unit={unit}
							availableDurations={availableDurations}
							selectedDuration={selectedDuration}
							onSelect={toggleDuration}
						/>
					))}
				</div>
			</PageTitleContent.Body>
		</PageTitleContent>
	);
};
