import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import constate from "constate";
import { Map as ImmutableMap } from "immutable";
import { useSearchParams } from "react-router-dom";
import { TGraphData, getGraph } from "api/identityGraph";
import { useLoadingState } from "hooks/useLoadingState";
import { useElementDimensions } from "hooks/useElementDimensions";
import { notEmpty } from "utils/comparison";
import { useOnMount } from "hooks/useOnMount";
import { EdgeModel } from "models/IdentityGraph/EdgeModel";
import { usePageContext } from "context/pageContext";

type TGraphForm = {
	userIds: string[];
	integrationId: string;
	resourceId: string;
	resourceType: string;
	roleId: string;
};

const useGraphHistory = () => {
	const [historyMap, setHistoryMap] = useState<{ currentHistoryIndex: number; history: URLSearchParams[] }>({
		currentHistoryIndex: -1,
		history: []
	});

	const addHistory = useCallback((searchParams: URLSearchParams) => {
		setHistoryMap(current => {
			const { currentHistoryIndex, history } = current;
			if (currentHistoryIndex < history.length - 1) {
				return {
					currentHistoryIndex: currentHistoryIndex + 1,
					history: [...history.slice(0, currentHistoryIndex + 1), searchParams]
				};
			} else {
				return { currentHistoryIndex: currentHistoryIndex + 1, history: [...history, searchParams] };
			}
		});
	}, []);

	const hasBack = useMemo(() => {
		return historyMap.currentHistoryIndex > 0;
	}, [historyMap]);

	const goBack = useCallback(() => {
		if (hasBack) {
			const { currentHistoryIndex, history } = historyMap;
			setHistoryMap(current => ({ ...current, currentHistoryIndex: currentHistoryIndex - 1 }));
			return history.at(currentHistoryIndex - 1);
		}
		return;
	}, [hasBack, historyMap]);

	const hasForward = useMemo(() => {
		return historyMap.currentHistoryIndex < historyMap.history.length - 1;
	}, [historyMap]);

	const goForward = useCallback(() => {
		if (hasForward) {
			const { currentHistoryIndex, history } = historyMap;
			setHistoryMap(current => ({ ...current, currentHistoryIndex: currentHistoryIndex + 1 }));
			return history.at(currentHistoryIndex + 1);
		}
		return;
	}, [historyMap, hasForward]);

	return { history, addHistory, goBack, goForward, hasBack, hasForward };
};

const getFormData = (searchParams: URLSearchParams): TGraphForm => ({
	userIds: searchParams.get("userIds")?.split(",") || [],
	integrationId: searchParams.get("integrationId") || "",
	resourceId: searchParams.get("resourceId") || "",
	resourceType: searchParams.get("resourceType") || "",
	roleId: searchParams.get("roleId") || ""
});

const useGraphForm = () => {
	const [searchParams, setSearchParams] = useSearchParams();
	const searchParamsRef = useRef(searchParams);
	const { setLastState: setPageLastState, pageLastState } = usePageContext();
	const { addHistory, goBack, goForward, hasBack, hasForward } = useGraphHistory();

	const [form, setForm] = useState<TGraphForm>(getFormData(searchParams));

	useOnMount(() => {
		const lastPageSearchParams = pageLastState.get("IdentityGraph")?.searchParams as URLSearchParams | undefined;
		if (lastPageSearchParams && !searchParams.toString().length) {
			setSearchParams(lastPageSearchParams);
			setForm(getFormData(lastPageSearchParams));
			addHistory(lastPageSearchParams);
			searchParamsRef.current = lastPageSearchParams;
		}
	});

	const setFormValue = useCallback(
		(key: keyof TGraphForm, value: string | string[] | undefined) => {
			setForm(prevForm => ({
				...prevForm,
				[key]: value
			}));
			const newSearchParams = new URLSearchParams(searchParamsRef.current);
			if (value && value.length) {
				newSearchParams.set(key, typeof value === "string" ? value : value.join(","));
			} else {
				newSearchParams.delete(key);
			}
			if (newSearchParams.toString() !== searchParamsRef.current.toString()) {
				setSearchParams(newSearchParams);
				setPageLastState("IdentityGraph", { searchParams: newSearchParams });
				addHistory(newSearchParams);
				searchParamsRef.current = newSearchParams;
			}
		},
		[addHistory, setSearchParams, setPageLastState]
	);

	const canSend = useMemo(() => {
		return !!form.userIds.length || !!form.resourceId || !!form.roleId || !!form.integrationId;
	}, [form]);

	const toState = useCallback(
		(direction: "forward" | "back") => {
			const newSearchParams = direction === "forward" ? goForward() : goBack();
			if (newSearchParams) {
				setSearchParams(newSearchParams);
				setForm(getFormData(newSearchParams));
				searchParamsRef.current = newSearchParams;
				setPageLastState("IdentityGraph", { searchParams: newSearchParams });
			}
		},
		[goBack, goForward, setPageLastState, setSearchParams]
	);

	return { form, canSend, setFormValue, toState, hasBack, hasForward };
};

export type TLine = {
	connectionType: EdgeModel["connectionType"];
	end: { current: HTMLElement; id: string };
	id: string;
	start: { current: HTMLElement; id: string };
};

const useGraphLines = (graphData?: TGraphData, isLoading?: boolean) => {
	const [refMap, setRefMap] = useState<ImmutableMap<string, HTMLElement>>(ImmutableMap());
	const [lines, setLines] = useState<TLine[]>([]);

	const setRef = useCallback((ids: string[]) => {
		return (element: unknown) => {
			if (element) {
				const asHtmlElement = element as HTMLElement;
				const newMap = ImmutableMap<string, HTMLElement>(ids.map(id => [id, asHtmlElement]));
				setRefMap(prevMap => prevMap.merge(newMap));
			}
		};
	}, []);

	const refMapSize = refMap.size;

	const calculateLines = useCallback(() => {
		if (isLoading || !graphData) return;
		if (!refMapSize) return;
		setLines([]);
		const newLines = graphData.edges
			.map(edge => {
				const startVertex = refMap.get(edge.start);
				const endVertex = refMap.get(edge.end);
				if (!startVertex || !endVertex) return null;
				return {
					connectionType: edge.connectionType,
					end: { current: endVertex, id: edge.end },
					id: edge.id,
					start: { current: startVertex, id: edge.start }
				};
			})
			.filter(notEmpty)
			.toArray();
		setLines(newLines);
	}, [isLoading, refMapSize, graphData, refMap]);

	useEffect(() => {
		calculateLines();
	}, [calculateLines]);

	const clearVerticesLinesFromMap = useCallback((verticesIds?: string[] | null) => {
		if (!verticesIds) return;
		setRefMap(currentMap => {
			let newMap = currentMap;
			verticesIds.forEach(vertexId => {
				newMap = newMap.delete(vertexId);
			});
			return newMap;
		});
	}, []);

	return {
		clearVerticesLinesFromMap,
		lines,
		refMap,
		setRef,
		setRefMap
	};
};

const useIdentityGraph = () => {
	const { elementRef } = useElementDimensions();
	const { form, canSend, setFormValue, hasBack, hasForward, toState } = useGraphForm();
	const { isLoading, withLoader } = useLoadingState();
	const [graphData, setGraphData] = useState<TGraphData>();
	const stepMapRef = useRef<ImmutableMap<string, number>>(ImmutableMap());

	useEffect(() => {
		if (!graphData) {
			if (stepMapRef.current.size) {
				stepMapRef.current = ImmutableMap();
			}
			return;
		}
		const newMap = ImmutableMap<string, number>(graphData.vertices.map(vertex => [vertex.id, vertex.step]));
		stepMapRef.current = newMap;
	}, [graphData]);

	const { refMap, lines, clearVerticesLinesFromMap, setRef } = useGraphLines(graphData, isLoading);

	const sendGraphRequest = useCallback(async () => {
		if (!canSend) return;
		const data = await getGraph({ ...form, withUnmanaged: true });
		setGraphData(data);
	}, [canSend, form]);

	const isUserSelected = useCallback(
		(id: string) => {
			return form.userIds.includes(id);
		},
		[form]
	);

	const isResourceRoleSelected = useCallback(
		(options: { resourceIds?: string[]; roleIds?: string[]; integrationId?: string }) => {
			const noResourceOrRoleIds = !options.resourceIds?.length && !options.roleIds?.length;
			if (noResourceOrRoleIds) {
				return !!options.integrationId && options.integrationId === form.integrationId;
			}
			return options.resourceIds?.includes(form.resourceId) || options.roleIds?.includes(form.roleId);
		},
		[form]
	);

	useEffect(() => {
		withLoader(sendGraphRequest());
	}, [sendGraphRequest, withLoader]);

	useEffect(() => {
		if (!canSend && graphData) {
			setGraphData(undefined);
		}
	}, [canSend, graphData]);

	return {
		state: { elementRef, form, graphData, isLoading, refMap, lines, hasBack, hasForward, stepMapRef },
		actions: {
			setFormValue,
			clearVerticesLinesFromMap,
			setRef,
			isUserSelected,
			isResourceRoleSelected,
			toState
		}
	};
};

export const [IdentityGraphProvider, useIdentityGraphContext] = constate(useIdentityGraph);
