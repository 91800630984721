import React, { useCallback, useMemo, useState } from "react";
import { Select, type TTargetValue } from "components/ui/Select";
import { UserModel } from "models/UserModel";
import { UserOption } from "components/common/UserOption";
import { sortByFullName } from "utils/sortUtils";
import { useNewTicketFormContext } from "components/pages/NewTicketPage/newTicketContext";
import { useTranslation } from "react-i18next";
import { Form } from "components/ui/Form";
import { useUsersSelect } from "hooks/useUsersSelect";

const OPTIONS_LIMIT = 100;

const getLabel = (user: UserModel) => user?.fullName;
const equality = (option: UserModel, value: UserModel) => option?.id === value?.id;
const renderLabel = (option: UserModel) => <UserOption option={option} noEmail />;

export const BehalfOfField: FC = () => {
	const {
		state: { receiverUser },
		actions: { setReceiverUser }
	} = useNewTicketFormContext();

	const { t } = useTranslation(undefined, { keyPrefix: "pages.newTicket.newTicketForm.onBehalfOfInput" });

	const [query, setQuery] = useState("");
	const { items: users, isLoading } = useUsersSelect(query, { includeDeleted: false });

	const options = useMemo(() => users?.toArray()?.filter(user => user.fullName.trim() !== "") || [], [users]);

	const onInputChange = useCallback(
		(event: TTargetValue | React.ChangeEvent<HTMLInputElement>) => setQuery(event.target.value),
		[]
	);

	return (
		<Form.Field>
			<Select
				filter={null}
				getOptionLabel={getLabel}
				inputValue={query}
				isOptionEqualToValue={equality}
				label={t("label")}
				limit={OPTIONS_LIMIT}
				loading={isLoading}
				onChange={setReceiverUser}
				onInputChange={onInputChange}
				options={options}
				renderLabel={renderLabel}
				sort={sortByFullName}
				value={receiverUser || null}
				optionType="userWithEmail"
			/>
		</Form.Field>
	);
};
