import React, { useMemo } from "react";
import { IntegrationModel } from "models/IntegrationModel";
import { IntegrationIcon } from "components/ui/Icons/IntegrationIcon";
import { getDynamicSizeIcon } from "components/ui/dynamicSizeIcon";
import { TooltipOnOverflow } from "components/ui/TooltipOnOverflow";
import { SelectOption } from "../SelectOption/SelectOption";

export interface IIntegrationSelectOptionProps {
	disabled?: boolean;
	onSelect: (event: React.SyntheticEvent, value: IntegrationModel) => void;
	selected?: boolean;
	integration: IntegrationModel;
}

export const IntegrationOption: FC<IIntegrationSelectOptionProps> = ({
	disabled = false,
	onSelect,
	integration,
	selected = false
}) => {
	const Icon = useMemo(() => {
		return integration.imageUrl ? getDynamicSizeIcon(<img src={integration.imageUrl} />) : IntegrationIcon;
	}, [integration.imageUrl]);

	return (
		<SelectOption onSelect={onSelect} selected={selected} disabled={disabled} value={integration} PrefixIcon={Icon}>
			<TooltipOnOverflow textVariant="body_reg" content={integration.name} />
		</SelectOption>
	);
};
