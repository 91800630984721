import React, { useCallback, useEffect, useMemo, useState } from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { useIntegrationsContext } from "context/integrationsContext";
import { LoadingSpinner } from "components/ui/LoadingSpinner";
import { PageTemplate } from "components/templates/PageTemplate";
import { useIntegrations } from "hooks/useIntegrations";
import useErrorModalState from "hooks/useErrorModalState";
import { ErrorModal } from "components/ui/ErrorModal";
import { AreYouSureModal } from "components/common/AreYouSureModal";
import { useIsOpenState } from "hooks/useIsOpenState";
import { ApprovalAlgorithmBlock } from "components/common/ApprovalAlgorithmBlock";
import { syncIntegration, TIntegrationUpdateData } from "api/integrations";
import { MaintainersBlock } from "components/common/MaintainersBlock";
import { PrerequisitePermissionsBlock } from "components/common/PrerequisitePermissionsBlock";
import { Breadcrumbs } from "components/common/Breadcrumbs";
import { useUser } from "hooks/useUser";
import { UserMaintainerModel } from "models/UserMaintainerModel";
import { IntegrationHeader } from "./components/IntegrationHeader";
import { ResourceFormModal } from "./components/ResourceFormModal";
import { useStyles } from "./styles";
import { ResourcesBlock } from "./components/ResourcesBlock";
import { AuditLogsBlock } from "./components/AuditLogsBlock";
import type { List } from "immutable";

export const IntegrationPage: FC = ({ className }) => {
	const params = useParams<{ integrationId: string }>();
	const integrationId = params.integrationId;

	const {
		state: { fullyLoaded: fullIntegrations },
		actions: { loadIntegration, deleteIntegration, updateIntegration, setIntegration }
	} = useIntegrationsContext();

	const integrations = useIntegrations();

	const integration = integrations?.get(integrationId || "");
	const integrationOwner = useUser(integration?.ownerId);
	const { t } = useTranslation();
	const navigate = useNavigate();
	const classes = useStyles();
	const {
		errorModalSetError,
		errorModalIsOpen,
		errorModalError,
		errorModalClose: closeErrorModal
	} = useErrorModalState();
	const areYouSureModal = useIsOpenState();
	const resourceFormModal = useIsOpenState();
	const [isDeleted, setIsDeleted] = useState(false);
	const [nextSyncTime, setNextSyncTime] = useState<Date | null>(integration?.nextSyncTime || null);
	const [prerequisitePermissionId, setPrerequisitePermissionId] = useState<string | null>(
		integration?.prerequisitePermissionId || null
	);

	useEffect(() => {
		if (integrationId && !fullIntegrations.get(integrationId) && !isDeleted) {
			loadIntegration(integrationId);
		}
	}, [fullIntegrations, integrationId, isDeleted, loadIntegration]);

	useEffect(() => {
		if (integration) {
			setNextSyncTime(integration.nextSyncTime);
			setPrerequisitePermissionId(integration.prerequisitePermissionId);
		}
	}, [integration]);

	const onDelete = useCallback(async () => {
		if (!integrationId) return;
		try {
			setIsDeleted(true);
			await deleteIntegration(integrationId);
			areYouSureModal.close();
			navigate("/integrations");
		} catch (e) {
			setIsDeleted(false);
			errorModalSetError(e as Error);
		}
	}, [areYouSureModal, deleteIntegration, errorModalSetError, integrationId, navigate]);

	const onDeleteButtonClick = useCallback(() => {
		if (integrationId) {
			areYouSureModal.open();
		}
	}, [integrationId, areYouSureModal]);

	const onSyncClick = useCallback(async () => {
		if (integrationId) {
			try {
				const { nextSyncTime: newNextSyncTime } = await syncIntegration(integrationId);
				setNextSyncTime(newNextSyncTime);
			} catch (err) {
				setNextSyncTime(null);
				errorModalSetError(err as Error);
			}
		}
	}, [errorModalSetError, integrationId]);

	const onUpdate = useCallback(
		async (integrationData: TIntegrationUpdateData & { id: string }) => {
			try {
				await updateIntegration(integrationData);
			} catch (e) {
				errorModalSetError(e as Error);
			}
		},
		[errorModalSetError, updateIntegration]
	);

	const onIntegrationRename = useCallback(
		async (newName: string) => {
			const isNameValid = newName.length >= 2 && newName.length <= 50;
			if (!integrationId || !newName || !isNameValid) {
				return;
			}
			await onUpdate({ id: integrationId, name: newName });
		},
		[integrationId, onUpdate]
	);

	const onApprovalAlgorithmChange = useCallback(
		async (newApprovalAlgorithmId: string) => {
			if (!integrationId || !newApprovalAlgorithmId) {
				return;
			}
			await onUpdate({ id: integrationId, defaultApprovalAlgorithmId: newApprovalAlgorithmId });
		},
		[integrationId, onUpdate]
	);

	const onResourceChange = useCallback(async () => {
		if (integrationId) {
			await loadIntegration(integrationId);
		}
	}, [integrationId, loadIntegration]);

	const updateMaintainers = useCallback(
		async (maintainers: List<TMaintainerModel>) => {
			if (!integration) return;
			const mappedMaintainers = maintainers.map(m => ({
				id: m.entityId,
				type: m instanceof UserMaintainerModel ? ("user" as const) : ("directoryGroup" as const)
			}));
			await onUpdate({
				id: integration.id,
				maintainers: mappedMaintainers.toArray()
			});
		},
		[integration, onUpdate]
	);

	const updatePrerequisitePermissionId = useCallback(
		(prerequisitePermissionId: string | null) => {
			setPrerequisitePermissionId(prerequisitePermissionId);
			const updatedIntegration = integration!.set("prerequisitePermissionId", prerequisitePermissionId);
			setIntegration(updatedIntegration);
		},
		[integration, setIntegration]
	);

	const breadcrumbs = useMemo(() => {
		if (!integration) return [];
		return [
			{
				title: t("navigation.integrations"),
				url: "/integrations"
			},
			{
				title: integration.name
			}
		];
	}, [integration, t]);

	const shouldShowResourcesBlock = integration?.adapterless || (integration?.resourcesCount || 0) > 0;

	return (
		<PageTemplate className={classNames("integrationPage", className)} subPage>
			<AreYouSureModal isOpen={areYouSureModal.isOpen} onClose={areYouSureModal.close} onAction={onDelete} />
			<ErrorModal error={errorModalError} isOpen={errorModalIsOpen} onClose={closeErrorModal} />

			{integration && (integration.manual || integration.virtual) && (
				<ResourceFormModal
					isOpen={resourceFormModal.isOpen}
					onClose={resourceFormModal.close}
					integrationId={integration.id}
					onUpdate={onResourceChange}
				/>
			)}
			{!integration ? (
				<LoadingSpinner className={classes.spinner} />
			) : (
				<>
					<PageTemplate.Title>
						<Breadcrumbs parts={breadcrumbs} />
					</PageTemplate.Title>
					<PageTemplate.Content noBorder noBackground className={classes.page}>
						<div className={classes.pageContent}>
							{!integrationOwner ? (
								<LoadingSpinner className={classes.spinner} />
							) : (
								<IntegrationHeader
									onSave={onUpdate}
									integration={integration}
									lastSync={integration.lastFullSync}
									onDeleteButtonClick={onDeleteButtonClick}
									onRename={onIntegrationRename}
									onInitialImageChange={setIntegration}
									sync={{
										onClick: onSyncClick,
										nextSyncTime
									}}
									enableOverrideDurations
								/>
							)}
							<div className={classes.content}>
								<ApprovalAlgorithmBlock
									onChangeAlgorithm={onApprovalAlgorithmChange}
									approvalAlgorithmId={integration.defaultApprovalAlgorithm?.id || ""}
								/>
								{shouldShowResourcesBlock && (
									<ResourcesBlock
										integration={integration}
										onAddResource={integration?.adapterless ? resourceFormModal.open : undefined}
										withUnmanaged
									/>
								)}
								{integrationOwner && (
									<MaintainersBlock
										maintainers={integration.maintainers}
										onUpdate={updateMaintainers}
										entity={integration}
									/>
								)}
								<AuditLogsBlock integration={integration} />
								{!integration?.virtual && (
									<PrerequisitePermissionsBlock
										prerequisitePermissionId={prerequisitePermissionId}
										afterAction={updatePrerequisitePermissionId}
										asset={{
											id: integration.id,
											name: integration.name,
											type: "integrations"
										}}
									/>
								)}
							</div>
						</div>
					</PageTemplate.Content>
				</>
			)}
		</PageTemplate>
	);
};
