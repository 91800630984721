import React, { useCallback, useEffect, useMemo, useState } from "react";
import classNames from "classnames";
import { List } from "immutable";
import uniq from "lodash/uniq";
import { useTranslation } from "react-i18next";
import { TitleBody } from "components/ui/TitleBody";
import { Button } from "components/ui/Button";
import { changeProfile } from "api/user";
import { IconPrefix } from "components/ui/IconPrefix";
import { getIntegrationIcon } from "components/common/IntegrationImage";
import { useNewRequestFormContext } from "components/pages/NewRequestPage/newRequestFormContext";
import { Modal } from "components/ui/Modal";
import { Input } from "components/ui/Input";
import { AccountNode } from "components/common/Nodes/AccountNode";
import { TooltipOnOverflow } from "components/ui/TooltipOnOverflow";
import { getIntegrationActors } from "api/integrationActors";
import { useAuthenticatedUser } from "hooks/useAuthenticatedUser";
import { useUpdateUser } from "hooks/useUpdateUser";
import { useLoadingState } from "hooks/useLoadingState";
import { VirtualScroll } from "components/ui/VirtualScroll";
import { LoadingSpinner } from "components/ui/LoadingSpinner";
import { ACTOR_HEIGHT, useStyles } from "./styles";
import type { IntegrationActorModel } from "models/IntegrationActorModel";
import type { IntegrationModel } from "models/IntegrationModel";
type TProps = {
	close: () => void;
	isOpen: boolean;
	actorIntegration: IntegrationModel | null;
};

const Integration: FC<{ integration?: IntegrationModel }> = ({ integration }) => {
	if (!integration) return null;
	return <IconPrefix size="huge" semibold Icon={getIntegrationIcon(integration)} content={integration.name} />;
};

const TRANSLATION_PREFIX = "pages.newRequest.summaryStep.chooseActorModal";

const MAXIMUM_SHOWN_ACTORS = 5;

const useIntegrationActors = (integrationId: string | undefined) => {
	const [actors, setActors] = useState<List<IntegrationActorModel>>(List());
	useEffect(() => {
		if (!integrationId) return;
		getIntegrationActors(integrationId).then(actorRes => setActors(actorRes));
	}, [integrationId]);

	return { actors };
};

export const ChooseActorModal: FC<TProps> = ({ className, innerRef, actorIntegration, isOpen, close }) => {
	const { t } = useTranslation();
	const { user } = useAuthenticatedUser();
	const [selectedIntegrationActor, setSelectedIntegrationActor] = useState<IntegrationActorModel | null>(null);
	const [query, setQuery] = useState("");
	const updateUser = useUpdateUser();
	const { isLoading, withLoader } = useLoadingState();
	const { actors: integrationActors } = useIntegrationActors(actorIntegration?.id);
	const {
		state: { fullReceiverUser },
		actions: { addReceiverIntegrationActor }
	} = useNewRequestFormContext();

	const currentIntegrationUserActors = useMemo(() => {
		if (!fullReceiverUser || !fullReceiverUser.integrationActors || !actorIntegration) return null;
		return fullReceiverUser.integrationActors.filter(actor => actor.integrationId === actorIntegration.id);
	}, [fullReceiverUser, actorIntegration]);

	const isMultipleActors = useMemo(
		() => currentIntegrationUserActors?.size && currentIntegrationUserActors.size > 1,
		[currentIntegrationUserActors?.size]
	);

	const actors = isMultipleActors ? currentIntegrationUserActors : integrationActors;

	const actorListHeight = ACTOR_HEIGHT * Math.min(MAXIMUM_SHOWN_ACTORS, actors?.size || 0);
	const classes = useStyles({ actorListHeight });

	const fillMissingActors = useCallback(async () => {
		const newIntegrationActors = uniq([
			selectedIntegrationActor?.id || "",
			...(user?.integrationActors?.map(actor => actor.id) || [])
		]);
		const newUser = await withLoader(
			changeProfile({
				integrationActors: newIntegrationActors
			})
		);
		updateUser(newUser);
	}, [selectedIntegrationActor?.id, user?.integrationActors, withLoader, updateUser]);

	const setIntegrationActor = useCallback(async () => {
		if (!selectedIntegrationActor || !actorIntegration) return;
		if (isMultipleActors) {
			addReceiverIntegrationActor(actorIntegration.id, selectedIntegrationActor.id);
		} else {
			await fillMissingActors();
		}
		close();
	}, [
		selectedIntegrationActor,
		actorIntegration,
		isMultipleActors,
		close,
		addReceiverIntegrationActor,
		fillMissingActors
	]);

	const selectActor = useCallback((actor: IntegrationActorModel) => setSelectedIntegrationActor(actor), []);

	const handleSelectActor = useCallback((actor: IntegrationActorModel) => () => selectActor(actor), [selectActor]);

	const renderAccountNode = useCallback(
		(actor: IntegrationActorModel) => (
			<AccountNode
				key={actor.id}
				content={<TooltipOnOverflow textVariant="text_sm_sb" content={actor.displayName} />}
				selected={selectedIntegrationActor?.id === actor.id}
				onClick={handleSelectActor(actor)}
				className={classes.accountNode}
			/>
		),
		[classes.accountNode, handleSelectActor, selectedIntegrationActor?.id]
	);
	const modalContent = useMemo(() => {
		const filteredActors =
			actors?.filter(actor => actor.displayName.toLowerCase().includes(query.toLowerCase())).toArray() || [];
		const title = t(`${TRANSLATION_PREFIX}.${isMultipleActors ? "assignAccountTitle" : "missingAccountTitle"}`);
		const content = t(`${TRANSLATION_PREFIX}.${isMultipleActors ? "assignAccountContent" : "missingAccountContent"}`);
		return (
			<div className={classes.content}>
				<TitleBody size="large" title={title} body={content} />
				{actorIntegration ? <Integration integration={actorIntegration} /> : null}
				{!actors?.size ? <LoadingSpinner /> : null}
				<div className={classes.inputAndList}>
					{actors?.size || 0 > 5 ? (
						<Input value={query} placeholder={t(`${TRANSLATION_PREFIX}.inputPlaceholder`)} onValueChange={setQuery} />
					) : null}
					<VirtualScroll
						estimateSize={ACTOR_HEIGHT}
						rows={filteredActors}
						renderRow={renderAccountNode}
						className={classes.actorList}
					/>
				</div>
			</div>
		);
	}, [
		actors,
		actorIntegration,
		t,
		isMultipleActors,
		classes.content,
		classes.inputAndList,
		classes.actorList,
		query,
		renderAccountNode
	]);

	const modalActions = useMemo(() => {
		return (
			<>
				<Button variant="secondary" size="medium" onClick={close}>
					{t("buttons.cancel")}
				</Button>
				<Button size="medium" onClick={setIntegrationActor} disabled={!selectedIntegrationActor} loading={isLoading}>
					{t(`${TRANSLATION_PREFIX}.assignAccount`)}
				</Button>
			</>
		);
	}, [close, isLoading, selectedIntegrationActor, setIntegrationActor, t]);

	if (!modalContent) return null;
	return (
		<Modal
			actions={modalActions}
			className={classNames(classes.modal, className)}
			content={modalContent}
			innerRef={innerRef}
			isOpen={isOpen}
			onClose={close}
		/>
	);
};
