import React from "react";
import { TooltipOnOverflow } from "components/ui/TooltipOnOverflow";
import { SelectOption } from "../SelectOption/SelectOption";
import type { TIconProps } from "components/ui/Icon";

export interface ITextSelectOptionProps<T> {
	disabled?: boolean;
	onSelect: (event: React.SyntheticEvent, value: T) => void;
	selected?: boolean;
	value: T;
	option: string;
	PrefixIcon?: FC<TIconProps>;
}

export const TextOption = <T,>({
	className,
	disabled = false,
	onSelect,
	option,
	value,
	selected = false,
	PrefixIcon
}: TProps<ITextSelectOptionProps<T>>) => {
	return (
		<SelectOption onSelect={onSelect} selected={selected} disabled={disabled} value={value} PrefixIcon={PrefixIcon}>
			<TooltipOnOverflow textVariant="body_reg" content={option} />
		</SelectOption>
	);
};
