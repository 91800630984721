import React from "react";
import { UserCard } from "components/common/UserCard";
import { UserModel } from "models/UserModel";
import { useUser } from "hooks/useUser";
import { SelectOption } from "../SelectOption/SelectOption";
import { useStyles } from "./styles";

export interface IUserNodeOptionProps<T> {
	disabled?: boolean;
	onSelect: (event: React.SyntheticEvent, value: T) => void;
	selected?: boolean;
	user: UserModel | string;
	value: T;
}

export const UserNodeOption = <T,>({
	disabled = false,
	onSelect,
	user,
	value,
	selected = false
}: TProps<IUserNodeOptionProps<T>>) => {
	const classes = useStyles();

	const currentUser = useUser(typeof user === "string" ? user : undefined);
	const wantedUser = typeof user === "string" && currentUser ? currentUser : (user as UserModel);

	return (
		<SelectOption onSelect={onSelect} disabled={disabled} value={value} readonly>
			<UserCard user={wantedUser} selected={selected} className={classes.user} />
		</SelectOption>
	);
};
