import { UserModel } from "models/UserModel";
import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Typography } from "components/ui/legacy/Typography";
import { UserEntitlementModel } from "models/UserEntitlementModel";
import { LoadingDots } from "components/ui/LoadingDots";
import { TColumn, VirtualTable } from "components/ui/legacy/VirtualTable";
import { useEntitlementsTable } from "./useEntitlementsTable";
import { ActionsCellComponent, ActorCell, ExpiresCell, RoleCell, TicketsCell } from "./EntitlementRow";
import { useStyles } from "./styles";

const TABLE_TRANSLATION_PREFIX = "pages.user.entitlements.permissionsTable" as const;

export const EntitlementsTable: FC<{ user: UserModel }> = ({ user }) => {
	const { t } = useTranslation();
	const classes = useStyles();
	const { displayedEntitlements, onRevokePermission, loading } = useEntitlementsTable(user, true);

	const ActionCell = useCallback(
		(permission: UserEntitlementModel) => {
			return <ActionsCellComponent permission={permission} onRevokePermission={onRevokePermission} />;
		},
		[onRevokePermission]
	);

	const columns: TColumn<UserEntitlementModel>[] = useMemo(
		() =>
			[
				{
					renderCell: ActorCell,
					header: t(`${TABLE_TRANSLATION_PREFIX}.account`),
					key: "account",
					width: "minmax(200px, 2fr)"
				},
				{
					renderCell: RoleCell,
					header: t(`${TABLE_TRANSLATION_PREFIX}.role`),
					key: "role",
					width: "minmax(200px, 2fr)"
				},
				{
					renderCell: TicketsCell,
					header: t(`${TABLE_TRANSLATION_PREFIX}.requests`),
					key: "requests",
					width: "minmax(120px, 1fr)"
				},
				{
					renderCell: ExpiresCell,
					header: t(`${TABLE_TRANSLATION_PREFIX}.expires`),
					key: "expires",
					width: "minmax(120px, 1fr)"
				},
				{
					renderCell: ActionCell,
					key: "actions",
					width: "10rem"
				}
			] as TColumn<UserEntitlementModel>[],
		[ActionCell, t]
	);

	if (loading) {
		return <LoadingDots />;
	}

	if (!displayedEntitlements.length) {
		return <Typography variant="standard">{t("pages.user.entitlements.noEntitlements")}</Typography>;
	}

	return (
		<div className={classes.tableContainer}>
			<VirtualTable columns={columns} rows={displayedEntitlements} totalRows={displayedEntitlements.length} />
		</div>
	);
};
