import React, { useCallback, useRef } from "react";
import { FilterExpression } from "components/ui/filters/FilterExpression";
import { useSessionAuditLogsContext } from "components/pages/AuditLogsPage/sessionAuditLogContext";
import { useOnMount } from "hooks/useOnMount";
import { useStyles } from "components/ui/filters/FilterExpression/components/DateTimeFilter/styles";
interface IDateTime {
	title: string;
	filterName: string;
	onFilterRemove: () => void;
}

const OPERATOR_SUFFIX = "Operator";

export const IntegrationLogsDateTimeSelectFilterExpression: FC<IDateTime> = ({ title, filterName, onFilterRemove }) => {
	const {
		state: { filters, dateRange },
		actions: { setSearchFilters, handleDateRangeChange, setTotalFilters }
	} = useSessionAuditLogsContext();

	const ref = useRef<HTMLDivElement>(null);
	const classes = useStyles();

	const handleDateSelection = useCallback(
		(dateRange: { from: Date; to: Date }) => {
			if (dateRange.from && dateRange.to) {
				handleDateRangeChange(dateRange);
				setTotalFilters(curr => {
					if (!curr.find(filter => filter.field === filterName))
						return [...curr, { field: filterName, operator: "is", value: "date" }];
					return curr;
				});
			}
		},
		[filterName, handleDateRangeChange, setTotalFilters]
	);

	const removeFromURLSearchParams = useCallback(
		(value?: string) => {
			const params = new URLSearchParams(filters);
			if (value) {
				params.delete(filterName, value);
				if (!params.get(filterName)) {
					params.delete(filterName);
					params.delete(`${filterName}${OPERATOR_SUFFIX}`);
				}
			} else {
				params.delete(filterName);
				params.delete(`${filterName}${OPERATOR_SUFFIX}`);
			}
			setTotalFilters(curr => {
				return curr.filter(filter => filter.value !== value);
			});
			setSearchFilters(params);
		},
		[filterName, filters, setSearchFilters, setTotalFilters]
	);

	const handleFilterRemove = useCallback(() => {
		onFilterRemove();
		if (filters.size !== 0) {
			removeFromURLSearchParams();
		}

		if (dateRange?.from !== undefined && dateRange.to !== undefined) {
			handleDateRangeChange({ from: undefined, to: undefined });
		}
		setTotalFilters(curr => curr.filter(filter => filter.field !== filterName));
	}, [
		dateRange?.from,
		dateRange?.to,
		filterName,
		filters.size,
		handleDateRangeChange,
		onFilterRemove,
		removeFromURLSearchParams,
		setTotalFilters
	]);

	useOnMount(() => {
		ref.current?.scrollIntoView({ behavior: "smooth", block: "start" });
	});

	return (
		<FilterExpression
			className={classes.filterContainer}
			onOptionSelect={handleDateSelection}
			innerRef={ref}
			onRemoveFilter={handleFilterRemove}
			title={title}
			type="datetime"
		/>
	);
};
