import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { FilterExpressionEmptyState } from "components/common/FilterExpressionEmptyState";
import { UsersIcon } from "components/ui/Icons/UsersIcon";
import { useUsersSelect } from "hooks/useUsersSelect";
import { IntegrationLogsMultiSelectFilterExpression } from "../IntegrationLogsMultiSelectFilterExpression";

type TIntegrationFilterExpressionProps = {
	onRemove: () => void;
};

export const UserFilterExpression: FC<TIntegrationFilterExpressionProps> = ({ onRemove }) => {
	const { t } = useTranslation();
	const [searchQuery, setSearchQuery] = useState<string>("");
	const { items: users } = useUsersSelect(searchQuery);

	return (
		<IntegrationLogsMultiSelectFilterExpression
			fetchValues={users}
			title={t("pages.auditLog.integrationLogs.filter.user")}
			inputPlaceholder={t("pages.auditLog.integrationLogs.filter.userPlaceholder")}
			filterEmptyState={
				<FilterExpressionEmptyState text={t("pages.auditLog.integrationLogs.filter.userEmptyState")} Icon={UsersIcon} />
			}
			onFilterRemove={onRemove}
			onInputChange={setSearchQuery}
			renderType="UserCard"
			filterName="userId"
			optionType="userNode"
		/>
	);
};
