import { Typography } from "components/ui/legacy/Typography";
import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { IntegrationResourceRoleModel } from "models/IntegrationResourceRoleModel";
import { Link } from "components/common/Link";
import { Table } from "components/ui/Table";
import { CommonAccordion } from "components/common/CommonAccordion";
import { useLocalPagination } from "hooks/useLocalPagination";
import { PageSelect } from "components/ui/PageSelect";
import { useIntegrations } from "hooks/useIntegrations";
import { LogoAvatar } from "components/common/LogoAvatar";
import { Button } from "components/ui/Button";
import { useApplications } from "hooks/useApplications";
import { useLoadingState } from "hooks/useLoadingState";
import { LegacyInheritOwnerOption } from "components/common/InheritOwnerOption";
import { UserWithEmail } from "components/common/UserWithEmail";
import { GiveAccessToIcon } from "components/ui/Icons/GiveAccessToIcon";
import { useUser } from "hooks/useUser";
import { useStyles } from "./styles";
import type { List } from "immutable";
import type { TIntegrationResourceModel } from "models/IntegrationResourceModel";
import type { NotNull } from "types/utilTypes";

type TGivesAccessResource = TIntegrationResourceModel & { roleId: string; roleName: string };

interface IReadProps {
	entitlesRoles: List<IntegrationResourceRoleModel>;
	actions?: React.ReactNode;
	manual?: never;
	onDelete?: never;
}

interface IManualProps {
	entitlesRoles: List<IntegrationResourceRoleModel>;
	actions?: React.ReactNode;
	manual: boolean;
	onDelete: (roleId: string) => Promise<void>;
}

type TGivesAccessProps = IReadProps | IManualProps;

const hasIntegrationResource = (
	role: IntegrationResourceRoleModel
): role is NotNull<IntegrationResourceRoleModel, "integrationResource"> => {
	return Boolean(role.integrationResource);
};

const GivesAccessRow: FC<{ resource: TGivesAccessResource; onDelete?: (roleId: string) => Promise<void> }> = ({
	resource,
	onDelete
}) => {
	const integrations = useIntegrations();
	const integration = integrations?.get(resource.integrationId);
	const resourceOwnerId = resource.ownerId;
	const user = useUser(resourceOwnerId || integration?.ownerId);
	const applications = useApplications();
	const classes = useStyles();
	const { t } = useTranslation();
	const { withLoader, isLoading } = useLoadingState();
	const deleteRow = useCallback(async () => {
		if (!onDelete) return;
		await withLoader(onDelete(resource.roleId));
	}, [onDelete, resource, withLoader]);

	if (!integration) {
		return null;
	}
	const integrationName = integration.name;
	const integrationImage =
		integration.imageUrl || applications?.find(({ id }) => integration.applicationId === id)?.imageUrl;

	return (
		<Table.Row key={resource.id}>
			<Table.Cell className={classes.resource}>
				{integrationImage && (
					<LogoAvatar size="small">
						<img src={integrationImage} />
					</LogoAvatar>
				)}
				<Link className={classes.link} noDecoration to={`/integrations/${resource.integrationId}`}>
					{integrationName}
				</Link>
				/
				<Link
					className={classes.link}
					noDecoration
					to={`/integrations/${resource.integrationId}/resources/${resource.id}`}>
					{`${resource.name}`}
				</Link>
				/
				<Link
					className={classes.link}
					noDecoration
					to={`/integrations/${resource.integrationId}/resources/${resource.id}/roles/${resource.roleId}`}>
					{resource.roleName}
				</Link>
			</Table.Cell>
			<Table.Cell>
				{user ? (
					resourceOwnerId ? (
						<UserWithEmail user={user} />
					) : (
						<LegacyInheritOwnerOption user={user} emphasis="user" />
					)
				) : (
					<Typography>{t("pages.integrationResource.unknown")}</Typography>
				)}
			</Table.Cell>
			{onDelete && (
				<Table.Cell>
					<div className={classes.cell}>
						<Button variant="text" size="medium" onClick={deleteRow} loading={isLoading}>
							{t("buttons.delete")}
						</Button>
					</div>
				</Table.Cell>
			)}
		</Table.Row>
	);
};

export const GivesAccessTable: FC<TGivesAccessProps> = ({ actions, entitlesRoles, manual = false, onDelete }) => {
	const classes = useStyles();
	const { t } = useTranslation();

	const resources: List<TGivesAccessResource> = useMemo(() => {
		return entitlesRoles.filter(hasIntegrationResource).map(role => ({
			...(role.integrationResource.toJS() as TIntegrationResourceModel),
			roleName: role.name,
			roleId: role.id
		}));
	}, [entitlesRoles]);

	const headers: string[] = useMemo(() => {
		const baseHeaders = [
			t("pages.integrationResource.givesAccessToTableHeaders.role"),
			t("pages.integrationResource.givesAccessToTableHeaders.owner")
		];
		if (manual) baseHeaders.push("");
		return baseHeaders;
	}, [manual, t]);

	const { page, currentPageNumber, setCurrentPage, totalPages } = useLocalPagination(resources);

	return (
		<CommonAccordion
			rounded
			noMargin
			title={
				<div className={classes.titleContainer}>
					<Typography variant="h3" prefixIcon={<GiveAccessToIcon />}>
						{t("pages.integrationResource.givesAccess")}
					</Typography>

					{actions ? <div>{actions}</div> : null}
				</div>
			}>
			<Table gridColumns={`2fr 1fr ${manual ? "15rem" : ""}`}>
				<Table.Row>
					{headers.map(title => (
						<Table.Header key={title}>
							<Typography>{title}</Typography>
						</Table.Header>
					))}
				</Table.Row>
				{page.map(resource => (
					<GivesAccessRow
						key={resource.id + resource.roleId}
						resource={resource}
						onDelete={manual ? onDelete : undefined}
					/>
				))}
			</Table>
			{totalPages > 1 && (
				<PageSelect
					changePage={setCurrentPage}
					currentPageNumber={currentPageNumber}
					pagesCount={totalPages}
					pagesShownAmount={3}
				/>
			)}
		</CommonAccordion>
	);
};
