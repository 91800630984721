import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Select, type ISelectProps, type TTargetValue } from "components/ui/Select";
import { UserModel } from "models/UserModel";
import { useIntegrations } from "hooks/useIntegrations";
import { InheritOwnerOption } from "components/common/InheritOwnerOption";
import { UserOption } from "components/common/UserOption";
import { useUser } from "hooks/useUser";
import { useUsersSelect } from "hooks/useUsersSelect";
import type { TUserOption } from "utils/ui/selectUtils";

interface IProps {
	autoAssigned?: boolean;
	integrationId?: string;
	ownerId: string | null;
	required?: boolean;
	selectInputProps?: Partial<TProps<ISelectProps<TOwnerOption>>>;
	setOwner: (owner: UserModel | null) => void | Promise<void>;
	showLabel?: boolean;
}

type TOwnerOption = {
	user: UserModel;
	optionValue: string | null;
};

export const OwnerSelect: FC<IProps> = ({
	autoAssigned,
	integrationId,
	ownerId,
	required = true,
	selectInputProps,
	setOwner,
	showLabel = true
}) => {
	const { t } = useTranslation();
	const [query, setQuery] = useState("");
	const { items: users, isLoading } = useUsersSelect(query, { includeDeleted: false });
	const integrations = useIntegrations();
	const integration = integrations?.get(integrationId || "");

	const user = useUser(ownerId || integration?.ownerId, false);
	const integrationOwner = useUser(integration?.ownerId, false);

	const getFullNameLabel = useCallback(
		(option: TOwnerOption) => (option.optionValue === null ? t("shared.integrationDefault") : option.user.fullName),
		[t]
	);

	const owner: TOwnerOption | null = useMemo(() => (user ? { user, optionValue: ownerId } : null), [user, ownerId]);
	const ownerOptions: TOwnerOption[] = useMemo(() => {
		const userOptions: TOwnerOption[] =
			users.toArray().map(usr => {
				return {
					user: usr,
					optionValue: usr.id
				};
			}) || [];
		const defaultOptions: TOwnerOption[] = integrationOwner
			? [
					{
						user: integrationOwner,
						optionValue: null
					}
				]
			: [];
		return defaultOptions.concat(userOptions);
	}, [integrationOwner, users]);

	const onOwnerChange = useCallback(
		(option: TOwnerOption | null) => {
			if (!users) return;
			setOwner(users.find(({ id }) => id === option?.optionValue) || null);
		},
		[setOwner, users]
	);

	const onInputChange = useCallback(
		(event: TTargetValue | React.ChangeEvent<HTMLInputElement>) => setQuery(event.target.value),
		[]
	);

	const getOptionType = useCallback((option: TOwnerOption): TUserOption => {
		return { type: "userWithEmail", option: option.user };
	}, []);

	return (
		<Select
			{...selectInputProps}
			description={autoAssigned ? t("pages.integrationResource.ownerAutoAssigned") : undefined}
			filter={null}
			getOptionKey={option => `${option.user.id}-${option.optionValue}`}
			getOptionLabel={getFullNameLabel}
			inputValue={query}
			isOptionEqualToValue={userEquality}
			label={showLabel ? t("pages.integration.owner") : undefined}
			loading={isLoading}
			onChange={onOwnerChange}
			onInputChange={onInputChange}
			options={ownerOptions}
			renderLabel={renderLabel}
			required={required}
			value={owner || null}
			optionType={getOptionType}
		/>
	);
};

const userEquality = (option: TOwnerOption, value: TOwnerOption) => option.optionValue === value.optionValue;
const renderLabel = (option: TOwnerOption | null) => {
	return option ? (
		option.optionValue ? (
			<UserOption option={option.user} noEmail />
		) : (
			<InheritOwnerOption user={option.user} />
		)
	) : (
		<></>
	);
};
