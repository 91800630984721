import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Map } from "immutable";
import { PageTitleContent } from "components/templates/PageTitleContentTemplate";
import { IconButton } from "components/ui/IconButton";
import { ArrowLeftIcon } from "components/ui/Icons/ArrowLeftIcon";
import { Tabs, type ITabOptions } from "components/ui/Tabs";
import { IntegrationIcon } from "components/ui/Icons/IntegrationIcon";
import { ResourcesIcon } from "components/ui/Icons/ResourcesIcon";
import { RoleIcon } from "components/ui/Icons/RoleIcon";
import { Drawer } from "components/templates/Drawer";
import { useIsOpenState } from "hooks/useIsOpenState";
import { useGoBack } from "hooks/useGoBack";
import { useStyles } from "./styles";
import { TableSection } from "./components/TableSection";
import { INTEGRATION, RESOURCE, ROLE } from "./utils";
import { FilterForm } from "./components/FilterForm";
import { BulkActions } from "./components/BulkActions";
import { BulkActionsProvider, useBulkActionsContext } from "./bulkActions.context";
import type { TBulkActionTabOption, TFormFilters } from "./types";

const EMPTY_MAP: TFormFilters = Map();

const BulkActionsPage: FC = ({ className }) => {
	const { t } = useTranslation();
	const classes = useStyles();
	const [pageRef, setPageRef] = useState<HTMLDivElement | null>(null);
	const { close: closeFilters, isOpen: isFiltersOpen, toggle: toggleFilters } = useIsOpenState();
	const goBack = useGoBack();

	const {
		state: { isBulkActionSelected, selectedTab, filters },
		actions: { onChangeTab, onChangeFilter, onToggleFilter, onClearFilters }
	} = useBulkActionsContext();

	const tabs: ITabOptions<TBulkActionTabOption>[] = useMemo(
		() => [
			{
				id: INTEGRATION,
				header: t("pages.bulkActions.tabs.integration"),
				Icon: IntegrationIcon,
				content: <TableSection isFiltersOpen={isFiltersOpen} toggleFilters={toggleFilters} type={INTEGRATION} />
			},
			{
				id: RESOURCE,
				header: t("pages.bulkActions.tabs.resource"),
				Icon: ResourcesIcon,
				content: <TableSection isFiltersOpen={isFiltersOpen} toggleFilters={toggleFilters} type={RESOURCE} />
			},
			{
				id: ROLE,
				header: t("pages.bulkActions.tabs.role"),
				Icon: RoleIcon,
				content: <TableSection isFiltersOpen={isFiltersOpen} toggleFilters={toggleFilters} type={ROLE} />
			}
		],
		[isFiltersOpen, t, toggleFilters]
	);

	return (
		<PageTitleContent innerRef={setPageRef} className={className}>
			<Drawer size={600} open={isFiltersOpen} parent={pageRef}>
				<FilterForm
					filters={filters.get(selectedTab, EMPTY_MAP)}
					onChangeFilter={onChangeFilter}
					onClear={onClearFilters}
					onClose={closeFilters}
					onToggleFilter={onToggleFilter}
					type={selectedTab}
				/>
			</Drawer>
			<PageTitleContent.Header>
				<PageTitleContent.Header.Top
					breadcrumbs={
						<IconButton variant="primary" size="large" onClick={goBack}>
							<ArrowLeftIcon />
						</IconButton>
					}
				/>
				<PageTitleContent.Header.Bottom>
					<PageTitleContent.Header.Title title={t("pages.bulkActions.title")} />
				</PageTitleContent.Header.Bottom>
			</PageTitleContent.Header>
			<PageTitleContent.Body className={classes.bodyOrder}>
				<BulkActions />
				<Tabs
					activeTab={selectedTab}
					className={classes.container}
					defaultTab={INTEGRATION}
					disabled={isBulkActionSelected}
					onChangeTab={onChangeTab}
					tabs={tabs}
				/>
			</PageTitleContent.Body>
		</PageTitleContent>
	);
};

const WrappedBulkActionsPage: FC = props => {
	return (
		<BulkActionsProvider>
			<BulkActionsPage {...props} />
		</BulkActionsProvider>
	);
};

export { WrappedBulkActionsPage as BulkActionsPage };
