import React from "react";
import { SearchOption } from "components/pages/NewTicketPage/components/NewTicketForm/components/NewTicketBySearchForm/components/SearchOption";
import { SelectOption } from "../SelectOption/SelectOption";
import type { TNewTicketOption } from "components/pages/NewTicketPage/components/NewTicketForm/types";

export interface IPermissionOptionProps {
	disabled?: boolean;
	onSelect: (event: React.SyntheticEvent, value: TNewTicketOption) => void;
	selected?: boolean;
	option: TNewTicketOption;
	query: string | null;
}

export const PermissionOption: FC<IPermissionOptionProps> = ({
	disabled = false,
	onSelect,
	option,
	query,
	selected = false
}) => {
	return (
		<SelectOption onSelect={onSelect} selected={selected} disabled={disabled} value={option}>
			<SearchOption key={option.value.id} {...option} query={query} />
		</SelectOption>
	);
};
