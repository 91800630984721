import { List, Map } from "immutable";
import { EdgeModel } from "models/IdentityGraph/EdgeModel";
import { VertexModel } from "models/IdentityGraph/VertexModel";
import { IntegrationModel } from "models/IntegrationModel";
import { IntegrationResourceModel } from "models/IntegrationResourceModel";
import { IntegrationResourceRoleModel } from "models/IntegrationResourceRoleModel";
import { apiReq, toQuerystring } from "utils/api/apiReq";

type TGraphQueryString = {
	roleId?: string;
	resourceId?: string;
	resourceType?: string;
	integrationId?: string;
	account?: string;
	userIds?: string[];
	withUnmanaged?: boolean;
};

export type TGraphData = {
	vertices: List<VertexModel>;
	edges: List<EdgeModel>;
};

export async function getGraph(queryData: TGraphQueryString): Promise<TGraphData> {
	const query = toQuerystring(queryData);
	if (!query || query === "") throw new Error("No query string provided");
	const { data } = await apiReq("GET", `/v1/identityGraph?${query}`);

	return {
		vertices: List<VertexModel>(data.vertices.map(VertexModel.fromServerData)),
		edges: List<EdgeModel>(data.edges.map(EdgeModel.fromServerData))
	};
}
export async function getGraphIntegrations(): Promise<Map<string, IntegrationModel>> {
	const { data } = await apiReq("GET", `/v1/identityGraph/integrations`);

	return Map<string, IntegrationModel>(
		data
			.map((integration: unknown) => IntegrationModel.fromServerData(integration))
			.map((integration: IntegrationModel) => [integration.id, integration])
	);
}

export async function getGraphResourceTypes(integrationId: string): Promise<string[]> {
	const { data } = await apiReq("GET", `/v1/identityGraph/resourceTypes?integrationId=${integrationId}`);

	return data;
}

export async function getGraphResources(
	integrationId: string,
	resourceType?: string
): Promise<Map<string, IntegrationResourceModel>> {
	const { data } = await apiReq(
		"GET",
		`/v1/identityGraph/resources?integrationId=${integrationId}` + (resourceType ? `&resourceType=${resourceType}` : "")
	);

	return Map<string, IntegrationResourceModel>(
		data
			.map((resource: unknown) => IntegrationResourceModel.fromServerData(resource))
			.map((resource: IntegrationResourceModel) => [resource.id, resource])
	);
}

export async function getGraphRoles(
	resourceId: string,
	withUnmanaged = false
): Promise<Map<string, IntegrationResourceRoleModel>> {
	const { data } = await apiReq(
		"GET",
		`/v1/identityGraph/roles?resourceId=${resourceId}&withUnmanaged=${withUnmanaged}`
	);

	return Map<string, IntegrationResourceRoleModel>(
		data
			.map((role: unknown) => IntegrationResourceRoleModel.fromServerData(role))
			.map((role: IntegrationResourceRoleModel) => [role.id, role])
	);
}
