import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	content: {
		"&.deleted": {
			"&::after": {
				color: "var(--color-red)",
				content: "'*'"
			}
		}
	}
});
