import React from "react";
import { TooltipOnOverflow } from "components/ui/TooltipOnOverflow";
import { BundleModel } from "models/BundleModel";
import { BundleIcon } from "components/ui/Icons/BundleIcon";
import { SelectOption } from "../SelectOption/SelectOption";

export interface IBundleOptionProps<T> {
	disabled?: boolean;
	onSelect: (event: React.SyntheticEvent, value: T) => void;
	selected?: boolean;
	bundle: BundleModel;
	value: T;
}

export const BundleOption = <T,>({
	disabled = false,
	onSelect,
	bundle,
	value,
	selected = false
}: TProps<IBundleOptionProps<T>>) => {
	return (
		<SelectOption onSelect={onSelect} selected={selected} disabled={disabled} value={value} PrefixIcon={BundleIcon}>
			<TooltipOnOverflow textVariant="body_reg" content={bundle.name} />
		</SelectOption>
	);
};
