import React, { useCallback, useMemo } from "react";
import { matchSorter } from "match-sorter";
import { IRenderChipParams, MultipleSelect } from "components/ui/MultipleSelect";
import { DirectoryGroupModel } from "models/DirectoryGroupModel";
import { GroupChip } from "components/ui/chips/GroupChip";
import { getNameAndIcon } from "utils/ui/directoryGroups";
import { useRankedSort } from "hooks/useRankedSort";
import { OnCallIntegrationScheduleModel } from "models/OnCallIntegrationScheduleModel";
import { type TCustomerEntitiesSelectedIds, useCustomerEntitiesSelect } from "hooks/useCustomerEntitiesSelect";
import type { List } from "immutable";

type TGroupOrScheduleType = "group" | "schedule";
type TGroupOrScheduleIds = { type: TGroupOrScheduleType; id: string };
type TGroupOrSchedule = DirectoryGroupModel | OnCallIntegrationScheduleModel;

interface IProps {
	chipClassName?: string;
	disabled?: boolean;
	label?: string;
	onChange: (ids: TGroupOrScheduleIds[] | null) => void;
	placeholder?: string;
	selectedIdsByType: TCustomerEntitiesSelectedIds;
}

const getOptionLabel = ({ name }: TGroupOrSchedule) => name;

const isGroup = (group: TGroupOrSchedule): group is DirectoryGroupModel => group instanceof DirectoryGroupModel;

const renderChip = ({ option, onRemove }: IRenderChipParams<TGroupOrSchedule>) => {
	if (!option) return <></>;
	return <GroupChip variant="regular" selected onDelete={onRemove} value={option} />;
};

const SORT_OPTIONS = {
	keys: [
		{
			key: (item: TGroupOrSchedule) => (isGroup(item) ? getNameAndIcon(item.name ?? "").name : item.name),
			threshold: matchSorter.rankings.MATCHES
		}
	]
};

export const GroupsAndSchedulesSelectInput: FC<IProps> = ({
	selectedIdsByType,
	onChange,
	className,
	label,
	disabled,
	placeholder
}) => {
	const { sort, onInputChange, query } = useRankedSort<TGroupOrSchedule>(SORT_OPTIONS);

	const {
		items,
		selectedItems,
		isLoading: isLoadingEntities
	} = useCustomerEntitiesSelect(query, {
		entities: ["directoryGroup", "onCallIntegrationSchedule"],
		selectedIdsByType
	});

	const values = useMemo(() => selectedItems.toList().map(({ id }) => id), [selectedItems]);

	const optionFilter = useCallback((item: TGroupOrSchedule) => !item.isDeleted || values.includes(item.id), [values]);

	const getOptionType = useCallback((option: TGroupOrSchedule) => {
		return isGroup(option) ? "directoryGroup" : "onCallSchedule";
	}, []);

	const options: TGroupOrSchedule[] = useMemo(
		() => (items as List<TGroupOrSchedule>).filter(optionFilter).toArray(),
		[items, optionFilter]
	);

	const handleChange = useCallback(
		(newValues: TGroupOrSchedule[] | null) => {
			onChange(
				newValues
					? newValues.map<TGroupOrScheduleIds>(value => ({ id: value.id, type: isGroup(value) ? "group" : "schedule" }))
					: null
			);
		},
		[onChange]
	);

	const selectedOptions = useMemo(() => selectedItems.toArray() as TGroupOrSchedule[], [selectedItems]);

	return (
		<MultipleSelect
			className={className}
			disabled={disabled}
			getOptionLabel={getOptionLabel}
			inputValue={query}
			label={label}
			loading={isLoadingEntities}
			onChange={handleChange}
			onInputChange={onInputChange}
			options={options}
			placeholder={placeholder}
			renderChip={renderChip}
			sort={sort}
			value={selectedOptions}
			optionType={getOptionType}
		/>
	);
};
