import React, { ReactNode, useCallback, useMemo } from "react";
import classNames from "classnames";
import { useControlled } from "hooks/useControlled";
import { ButtonGroup } from "../ButtonGroup";
import { useStyles } from "./styles";
import type { TIconProps } from "components/ui/Icon";

export interface ITabOptions<TabId extends string> {
	id: TabId;
	header: string;
	Icon: FC<TIconProps>;
	content?: ReactNode;
}

interface ITabsBaseProps<TabId extends string> {
	tabs: ITabOptions<TabId>[];
	disabled?: boolean;
	headerClassName?: string;
	contentClassName?: string;
}
interface ITabsUncontrolledProps<TabId extends string> extends ITabsBaseProps<TabId> {
	defaultTab?: TabId;
	activeTab?: never;
	onChangeTab?: (id: TabId) => void;
}

interface ITabsControlledProps<TabId extends string> extends ITabsBaseProps<TabId> {
	defaultTab?: TabId;
	activeTab: TabId | null;
	onChangeTab: (id: TabId) => void;
}

type TTabsProps<TabId extends string> = ITabsUncontrolledProps<TabId> | ITabsControlledProps<TabId>;

export const Tabs = <TabId extends string = string>({
	className,
	contentClassName,
	innerRef,
	tabs,
	disabled = false,
	headerClassName,
	activeTab: propActiveTab,
	defaultTab,
	onChangeTab
}: TProps<TTabsProps<TabId>>) => {
	const classes = useStyles();
	const [activeTabId, setActiveTabId] = useControlled<TabId>({
		controlled: propActiveTab,
		default: defaultTab
	});

	const changeTab = useCallback(
		(id: TabId) => {
			if (!disabled) {
				setActiveTabId(id);
				onChangeTab?.(id);
			}
		},
		[disabled, onChangeTab, setActiveTabId]
	);

	const activeTab = useMemo(() => tabs.find(tab => tab.id === activeTabId), [activeTabId, tabs]);

	return (
		<div className={classNames(classes.container, className)} ref={innerRef}>
			<div className={classes.headersContainer}>
				<ButtonGroup
					activeButton={activeTabId || tabs[0].id}
					onActivateButton={changeTab}
					disabled={disabled}
					buttons={tabs}
					className={classNames(classes.tabHeaders, { [classes.containerDisabled]: disabled }, headerClassName)}
					size="large"
				/>
			</div>
			<div className={classNames(contentClassName, classes.tabContent)}>{activeTab?.content}</div>
		</div>
	);
};
