import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "components/ui/Button";
import { VirtualScroll } from "components/ui/VirtualScroll";
import { exportAuditLogsToCSV } from "api/auditLogs";
import { Select } from "components/ui/Select";
import { DownloadIcon } from "components/ui/Icons/DownloadIcon";
import { TimezoneButtonDropdown, type IToggleOption } from "components/ui/DropdownTimezoneButton";
import { StaticChip } from "components/ui/chips/StaticChip";
import { Typography } from "components/ui/Typography";
import { Section } from "components/ui/Section";
import classNames from "classnames";
import { getDateOptionLabel, useAuditLogsContext } from "../../auditLogsContext";
import { AuditLog } from "../AuditLog";
import { useStyles } from "./styles";
import type { TAuditLogModel } from "models/auditLogs";
import type { TTextOption } from "utils/ui/selectUtils";

const TRANSLATION_PREFIX = "pages.auditLog.";
const AUDIT_LOG_ESTIMATED_HEIGHT = 90;

export const EntitleLogsSection: FC = ({ className, innerRef }) => {
	const { t } = useTranslation();
	const classes = useStyles();
	const {
		state: { auditLogs, pagination, timezone, dates, selectedDate, isLoadingDates },
		actions: { selectDate, setTimezone, getNextPage }
	} = useAuditLogsContext();

	const currentTimezoneToggleOptions: [IToggleOption, IToggleOption] = useMemo(
		() => [
			{
				value: "local",
				label: t(`${TRANSLATION_PREFIX}timezoneToggle.local`),
				dropdownLabel: t(`${TRANSLATION_PREFIX}timezoneToggle.localGmtTime`, {
					timezone: new Date().getTimezoneOffset() / 60
				})
			},
			{
				value: "global",
				label: t(`${TRANSLATION_PREFIX}timezoneToggle.globalUtcTime`),
				dropdownLabel: t(`${TRANSLATION_PREFIX}timezoneToggle.globalUtcTime`)
			}
		],
		[t]
	);

	const exportToCSV = useCallback(() => {
		selectedDate && exportAuditLogsToCSV(selectedDate, timezone);
	}, [selectedDate, timezone]);

	const title = useMemo(() => {
		return (
			<>
				<Typography variant="body_med"> {t("pages.auditLog.total")}</Typography>
				<StaticChip size="small" variant="regular">
					{t("number", { value: pagination.totalAuditLogs })}
				</StaticChip>
			</>
		);
	}, [pagination.totalAuditLogs, t]);

	const getOptionType = useCallback(
		(option: Date): TTextOption => ({ type: "text", option: option.toLocaleDateString() }),
		[]
	);

	const EntitleLogsActions = useMemo(() => {
		return (
			<div className={classes.rightTitle}>
				<Select
					disabled={!dates || dates.length === 0}
					getOptionLabel={getDateOptionLabel}
					loading={isLoadingDates}
					onChange={selectDate}
					options={dates || []}
					placeholder={t(`${TRANSLATION_PREFIX}selectDate` as const)}
					value={selectedDate}
					required
					size="medium"
					optionType={getOptionType}
				/>
				<TimezoneButtonDropdown
					options={currentTimezoneToggleOptions}
					selectedOption={timezone}
					onSelect={setTimezone}
				/>
				<Button size="medium" onClick={exportToCSV} suffix={<DownloadIcon />}>
					{t("buttons.downloadAsCSV")}
				</Button>
			</div>
		);
	}, [
		classes.rightTitle,
		currentTimezoneToggleOptions,
		dates,
		exportToCSV,
		getOptionType,
		isLoadingDates,
		selectDate,
		selectedDate,
		setTimezone,
		t,
		timezone
	]);

	const renderAuditLog = useCallback(
		(auditLog: TAuditLogModel) => <AuditLog key={auditLog?.id} auditLog={auditLog} timezone={timezone} />,
		[timezone]
	);

	return (
		<Section
			titleActions={EntitleLogsActions}
			innerRef={innerRef}
			fullHeight
			title={title}
			className={classNames(classes.entitleLogsSection, className)}>
			<VirtualScroll
				estimateSize={AUDIT_LOG_ESTIMATED_HEIGHT}
				rows={auditLogs}
				renderRow={renderAuditLog}
				canFetchMore={pagination.lastPageNumber < pagination.totalPages}
				fetchMore={getNextPage}
			/>
		</Section>
	);
};
