import React, { useCallback, useMemo } from "react";
import isNil from "lodash/isNil";
import { InheritWorkflowChip, WorkflowChip } from "components/ui/chips/WorkflowChip/WorkflowChip";
import { useTranslation } from "react-i18next";
import { useApprovalAlgorithms } from "hooks/useApprovalAlgorithms";
import { WorkflowsIcon } from "components/ui/Icons/WorkflowsIcon";
import { ApprovalAlgorithmModel } from "models/ApprovalAlgorithmModel";
import { TUpdateExpressionProps } from "../../updates.types";
import { SingleSelectAttributeExpression } from "../SingleSelectAttributeExpression";
import { useStyles } from "./styles";
import type { TIconPrefixOption } from "utils/ui/selectUtils";

const ATTRIBUTE = "approvalAlgorithmId" as const;
const INHERIT_WORKFLOW = "inherit" as const;
type TOption = ApprovalAlgorithmModel | typeof INHERIT_WORKFLOW;
export const WorkflowAttributeExpression: FC<TUpdateExpressionProps<"approvalAlgorithmId">> = ({
	innerRef,
	selected,
	ruleType,
	onRemoveAttribute,
	setUpdatesAttribute
}) => {
	const classes = useStyles();
	const { t } = useTranslation("translation", { keyPrefix: "pages.rules.updates" });
	const { t: sharedTranslation } = useTranslation("translation", { keyPrefix: "shared" });

	const onReset = useCallback(() => {
		setUpdatesAttribute(ATTRIBUTE, undefined);
	}, [setUpdatesAttribute]);

	const onOptionSelect = useCallback(
		(value: TOption) => setUpdatesAttribute(ATTRIBUTE, value === INHERIT_WORKFLOW ? null : value.id),
		[setUpdatesAttribute]
	);

	const renderSelected = useCallback(
		(value: TOption) =>
			value === INHERIT_WORKFLOW ? (
				<InheritWorkflowChip
					noTooltip
					size="large"
					variant="regular"
					selected
					onDelete={onReset}
					from={ruleType === "resources" ? "integration" : "resource"}
				/>
			) : (
				<WorkflowChip size="large" variant="regular" value={value} selected onDelete={onReset} />
			),
		[onReset, ruleType]
	);

	const approvalAlgorithms = useApprovalAlgorithms();
	const options = useMemo(
		() => [INHERIT_WORKFLOW as TOption].concat(approvalAlgorithms?.valueSeq().toArray() ?? []),
		[approvalAlgorithms]
	);

	const filterOptions = useCallback(
		(options: TOption[], inputValue: string) =>
			options.filter(
				option => option !== INHERIT_WORKFLOW && option.name.toLowerCase().includes(inputValue.toLowerCase())
			),
		[]
	);

	const selectedValue = useMemo(
		() =>
			isNil(selected)
				? selected === null
					? INHERIT_WORKFLOW
					: undefined
				: (approvalAlgorithms?.get(selected) ?? undefined),
		[selected, approvalAlgorithms]
	);

	const getOptionType = useCallback(
		(option: ApprovalAlgorithmModel | typeof INHERIT_WORKFLOW): TIconPrefixOption => {
			return {
				type: "iconPrefix",
				Icon: WorkflowsIcon,
				text:
					option === INHERIT_WORKFLOW
						? sharedTranslation(ruleType === "resources" ? "integrationDefault" : "resourceDefault")
						: option.name
			};
		},
		[ruleType, sharedTranslation]
	);

	return (
		<SingleSelectAttributeExpression
			innerRef={innerRef}
			onRemoveAttribute={onRemoveAttribute}
			title={t(`${ruleType!}.${ATTRIBUTE}`)}
			inputPlaceholder={t(`placeholders.${ATTRIBUTE}`)}
			options={options}
			filter={filterOptions}
			sort={null}
			renderSelected={renderSelected}
			onReset={onReset}
			onOptionSelect={onOptionSelect}
			selected={selectedValue}
			emptyState={<div className={classes.emptyState} />}
			optionsType={getOptionType}
		/>
	);
};
