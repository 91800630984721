import React, { useCallback } from "react";
import classNames from "classnames";
import { useStyles } from "./styles";
import type { TIconProps } from "components/ui/Icon";

export interface ISelectOptionProps<T> {
	disabled?: boolean;
	onSelect: (event: React.SyntheticEvent, value: T) => void;
	PrefixIcon?: FC<TIconProps>;
	selected?: boolean;
	readonly?: boolean;
	value: T;
}

export const SelectOption = <T,>({
	children,
	className,
	disabled = false,
	onSelect,
	PrefixIcon,
	selected = false,
	readonly = false,
	value
}: TProps<ISelectOptionProps<T>>) => {
	const classes = useStyles();

	const onClick = useCallback(
		(event: React.MouseEvent) => {
			!disabled && onSelect(event, value);
		},
		[disabled, onSelect, value]
	);

	return (
		<div
			onClick={onClick}
			className={classNames(
				classes.option,
				{
					[classes.disabled]: disabled,
					[classes.selected]: selected,
					[classes.readonly]: readonly
				},
				className
			)}>
			{PrefixIcon && <PrefixIcon size={24} />}
			{children}
		</div>
	);
};
