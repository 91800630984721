import React from "react";
import { useIntegrations } from "hooks/useIntegrations";
import { useTranslation } from "react-i18next";
import { FilterExpressionEmptyState } from "components/common/FilterExpressionEmptyState";
import { IntegrationIcon } from "components/ui/Icons/IntegrationIcon";
import { IntegrationLogsMultiSelectFilterExpression } from "../IntegrationLogsMultiSelectFilterExpression";

type TIntegrationFilterExpressionProps = {
	onRemove: () => void;
};

export const IntegrationFilterExpression: FC<TIntegrationFilterExpressionProps> = ({ onRemove }) => {
	const integrations = useIntegrations();
	const { t } = useTranslation();

	return (
		<IntegrationLogsMultiSelectFilterExpression
			fetchValues={integrations!.toList()}
			title={t("pages.auditLog.integrationLogs.filter.integration")}
			inputPlaceholder={t("pages.auditLog.integrationLogs.filter.integrationPlaceholder")}
			filterEmptyState={
				<FilterExpressionEmptyState
					text={t("pages.auditLog.integrationLogs.filter.integrationEmptyState")}
					Icon={IntegrationIcon}
				/>
			}
			onFilterRemove={onRemove}
			renderType="IntegrationChip"
			filterName="sessionAuditLogIntegrationId"
			optionType="integration"
		/>
	);
};
