import React, { useMemo } from "react";
import { ApplicationModel } from "models/ApplicationModel";
import { TooltipOnOverflow } from "components/ui/TooltipOnOverflow";
import { getDynamicSizeIcon } from "components/ui/dynamicSizeIcon";
import { SelectOption } from "../SelectOption/SelectOption";

export interface IApplicationOptionProps {
	disabled?: boolean;
	onSelect: (event: React.SyntheticEvent, value: ApplicationModel) => void;
	selected?: boolean;
	application: ApplicationModel;
}

export const ApplicationOption: FC<IApplicationOptionProps> = ({
	disabled = false,
	onSelect,
	application,
	selected = false
}) => {
	const Icon = useMemo(() => {
		return application.imageUrl ? getDynamicSizeIcon(<img src={application.imageUrl} />) : undefined;
	}, [application.imageUrl]);

	return (
		<SelectOption onSelect={onSelect} selected={selected} disabled={disabled} value={application} PrefixIcon={Icon}>
			<TooltipOnOverflow textVariant="body_reg" content={application.name} />
		</SelectOption>
	);
};
