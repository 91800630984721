import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import partition from "lodash/partition";
import { useApprovalAlgorithms } from "hooks/useApprovalAlgorithms";
import { Form } from "components/ui/Form";
import { Input } from "components/ui/Input";
import { Select, TTargetValue } from "components/ui/Select";
import { ApprovalAlgorithmModel } from "models/ApprovalAlgorithmModel";
import { UserOption } from "components/common/UserOption";
import { Button } from "components/ui/Button";
import { TIntegrationResourceBody, TIntegrationResourceCreateBody } from "api/integrationResources";
import { removeRedundantSpaces } from "utils/strings";
import { useAuthenticatedUser } from "hooks/useAuthenticatedUser";
import { sortByFullName, sortByName } from "utils/sortUtils";
import { List } from "immutable";
import { TTicketDuration } from "utils/durationsOptions";
import { useCompany } from "hooks/useCompany";
import { useIntegrations } from "hooks/useIntegrations";
import { getNameValidators, getTypeValidators } from "utils/validation/validationUtils";
import { Typography } from "components/ui/legacy/Typography";
import { Tooltip } from "components/ui/Tooltip";
import { InfoIcon } from "components/ui/Icons/InfoIcon";
import { DescribedCheckbox } from "components/common/DescribedCheckbox";
import { DurationsOverrideSelect } from "components/common/DurationsOverrideSelect";
import { VirtualizedRoleSelectInputs } from "components/common/VirtualizedRoleSelectInputs";
import { LegacyInheritOwnerOption } from "components/common/InheritOwnerOption/InheritOwnerOption";
import { notEmpty } from "utils/comparison";
import { useUsersSelect } from "hooks/useUsersSelect";
import { useUser } from "hooks/useUser";
import { useStyles } from "./styles";
import type { UserModel } from "models/UserModel";
import type { IntegrationResourceModel } from "models/IntegrationResourceModel";
import type { TTextOption } from "utils/ui/selectUtils";

interface IProps {
	integrationId: string;
	resource?: IntegrationResourceModel;
	resetFormRef?: React.MutableRefObject<(() => void) | undefined>;
	onSubmit: (resource: TIntegrationResourceBody) => Promise<void>;
}

type TOwnerOption = {
	user: UserModel;
	optionValue: string | null;
};

export const ResourceForm: FC<IProps> = ({ integrationId, resource, onSubmit, resetFormRef }) => {
	const classes = useStyles();
	const { t } = useTranslation();
	const company = useCompany();
	const integrations = useIntegrations();
	const clearVirtualRoleFormRef = useRef<(() => void) | undefined>();
	const integration = integrations?.get(integrationId || "");
	const [virtualizedRoleId, setVirtualizedRoleId] = useState<string | null>(null);

	const { user } = useAuthenticatedUser();
	const [ownerQuery, setOwnerQuery] = useState("");
	const { items: users, isLoading } = useUsersSelect(ownerQuery);

	const approvalAlgorithms = useApprovalAlgorithms();

	const inheritApprovalAlgorithm = useMemo(
		() => new ApprovalAlgorithmModel({ id: "[INTEGRATION_DEFAULT]", name: t("pages.integration.resource.inherit") }),
		[t]
	);

	const [loading, setLoading] = useState(false);
	const [name, setName] = useState(resource?.name || "");
	const [roleName, setRoleName] = useState(resource?.name || "");
	const [type, setType] = useState(resource?.type || "");
	const [approvalAlgorithmId, setApprovalAlgorithmId] = useState<string>(inheritApprovalAlgorithm.id);
	const [ownerId, setOwnerId] = useState<string | null>(
		resource?.ownerId !== undefined ? resource.ownerId : user?.id || null
	);
	const [multirole, setMultirole] = useState(false);
	const [allowedDurations, setAllowedDurations] = useState(List<TTicketDuration>());
	const [overrideAllowedDurations, setOverrideAllowedDurations] = useState(Boolean(resource?.allowedDurations));

	const [approvalAlgorithmError, setApprovalAlgorithmError] = useState("");
	const [ownerError, setOwnerError] = useState("");

	const selectedUser = useUser(ownerId || integration?.ownerId);
	const integrationOwner = useUser(integration?.ownerId);
	const selectedOwner = useMemo(() => {
		if (!selectedUser) return undefined;
		return { user: selectedUser, optionValue: ownerId };
	}, [ownerId, selectedUser]);

	useEffect(() => {
		if (resource?.allowedDurations) {
			setAllowedDurations(resource.allowedDurations);
		} else if (integration?.allowedDurations) {
			setAllowedDurations(integration.allowedDurations);
		} else if (company?.allowedDurations) {
			setAllowedDurations(company.allowedDurations);
		}
	}, [company, integration, resource]);

	const onAlgorithmChange = useCallback(
		(value: ApprovalAlgorithmModel | null) => {
			if (value) {
				const newApprovalAlgorithm = approvalAlgorithms?.get(value?.id || "") || null;
				setApprovalAlgorithmId(newApprovalAlgorithm?.id || inheritApprovalAlgorithm.id);
				setApprovalAlgorithmError("");
			}
		},
		[approvalAlgorithms, inheritApprovalAlgorithm.id]
	);

	const onOwnerInputChange = useCallback((event: TTargetValue | React.ChangeEvent<HTMLInputElement>) => {
		setOwnerQuery(event.target.value);
	}, []);

	const onOwnerChange = useCallback((option: TOwnerOption | null) => {
		setOwnerId(option?.optionValue || null);
		setOwnerError("");
	}, []);

	const clearForm = useCallback(() => {
		setName("");
		setType("");
		setVirtualizedRoleId(null);
		clearVirtualRoleFormRef.current && clearVirtualRoleFormRef.current();
		setApprovalAlgorithmId(inheritApprovalAlgorithm.id);
		user?.id && setOwnerId(user.id);
	}, [inheritApprovalAlgorithm.id, user?.id]);

	const validate = useCallback(() => {
		let valid = true;

		if (approvalAlgorithmId === null) {
			valid = false;
			setApprovalAlgorithmError(
				t("validationErrors.global.cannotBeEmpty", { field: t("modelsFields.integration.defaultApprovalAlgorithm") })
			);
		}

		if (ownerId === null && !selectedOwner) {
			valid = false;
			setOwnerError(t("validationErrors.global.cannotBeEmpty", { field: t("modelsFields.integration.owner") }));
		}

		return valid;
	}, [approvalAlgorithmId, ownerId, selectedOwner, t]);

	const submit = useCallback(async () => {
		const normalizedName = removeRedundantSpaces(name);
		setLoading(true);
		if (validate()) {
			await onSubmit({
				name: normalizedName,
				integrationId,
				approvalAlgorithmId: approvalAlgorithmId === inheritApprovalAlgorithm.id ? undefined : approvalAlgorithmId,
				ownerUserId: ownerId,
				type: type || undefined,
				id: resource?.id || undefined,
				multirole,
				allowedDurations: overrideAllowedDurations ? allowedDurations : null,
				roles: integration?.virtual ? [{ virtualizedRoleId: virtualizedRoleId, name: roleName }] : undefined
			} as TIntegrationResourceCreateBody);
			if (!resource) {
				clearForm();
			}
		}
		setLoading(false);
	}, [
		name,
		validate,
		onSubmit,
		integrationId,
		approvalAlgorithmId,
		inheritApprovalAlgorithm.id,
		ownerId,
		type,
		resource,
		multirole,
		overrideAllowedDurations,
		allowedDurations,
		integration?.virtual,
		virtualizedRoleId,
		roleName,
		clearForm
	]);

	const toggleMultirole = useCallback(() => setMultirole(current => !current), []);

	const approvalAlgorithmOptions = useMemo(
		() => [inheritApprovalAlgorithm].concat(approvalAlgorithms?.toList().toArray() || []),
		[approvalAlgorithms, inheritApprovalAlgorithm]
	);

	const approvalAlgorithm = useMemo(() => {
		if (approvalAlgorithmId === inheritApprovalAlgorithm.id) {
			return inheritApprovalAlgorithm;
		}
		return approvalAlgorithms?.get(approvalAlgorithmId);
	}, [approvalAlgorithmId, approvalAlgorithms, inheritApprovalAlgorithm]);

	const ownerOptions: TOwnerOption[] = useMemo(() => {
		const usersOptions = users
			.filter(notEmpty)
			.toArray()
			.map(usr => ({
				user: usr,
				optionValue: usr.id
			}));
		if (!integrationOwner) return usersOptions;
		const ownerOption: TOwnerOption = {
			user: integrationOwner,
			optionValue: null
		};
		return [ownerOption].concat(usersOptions);
	}, [integrationOwner, users]);

	const nameValidators = useMemo(
		() => getNameValidators(`${t("shared.resourceTypes.resource")} ${t("shared.name")}`),
		[t]
	);
	const typeValidators = useMemo(
		() => getTypeValidators(`${t("shared.resourceTypes.resource")} ${t("pages.integration.resource.type")}`, true),
		[t]
	);

	const isValid = useMemo(() => {
		const resourceFieldsValid =
			!nameValidators.some(validate => validate(name)) && !typeValidators.some(validate => validate(type));
		const virtualFieldsValid = !integration?.virtual || Boolean(virtualizedRoleId);

		return resourceFieldsValid && virtualFieldsValid;
	}, [integration?.virtual, name, nameValidators, type, typeValidators, virtualizedRoleId]);

	const isChanged = useMemo(() => resource?.type !== type, [resource?.type, type]);

	useEffect(() => {
		if (resetFormRef) {
			resetFormRef.current = clearForm;
		}
	}, [resetFormRef, clearForm]);

	const getOptionType = useCallback((option: ApprovalAlgorithmModel): TTextOption => {
		return {
			type: "text",
			option: option.name
		};
	}, []);

	if (resource) {
		return (
			<Form className={classes.resourceForm}>
				<div>
					<Form.Field>
						<Input label={t("pages.integration.resource.type")} value={type} onValueChange={setType} />
					</Form.Field>
					<Form.Actions>
						<Button
							variant="primary"
							type="submit"
							loading={loading}
							disabled={!isValid || !isChanged}
							onClick={submit}>
							{t("buttons.save")}
						</Button>
					</Form.Actions>
				</div>
			</Form>
		);
	}

	return (
		<Form className={classes.resourceForm}>
			<div>
				<Form.Field>
					<Input
						label={t("pages.integration.resource.name")}
						value={name}
						validators={nameValidators}
						onValueChange={setName}
						isRequired
					/>
				</Form.Field>
				<Form.Field className={classes.checkboxField}>
					<DescribedCheckbox
						label={t("pages.integration.resource.multirole")}
						description={t("pages.integration.resource.multiroleHelp")}
						selected={multirole}
						disabled={integration?.virtual}
						onClick={toggleMultirole}
					/>
				</Form.Field>
				<Form.Field>
					<Input
						label={t("pages.integration.resource.type")}
						value={type}
						onValueChange={setType}
						validators={typeValidators}
					/>
				</Form.Field>
				<Form.Field>
					<Select
						disabled={!approvalAlgorithms?.size}
						errors={[approvalAlgorithmError]}
						getOptionLabel={getNameLabel}
						isOptionEqualToValue={workflowEquality}
						label={t("pages.integration.defaultApprovalAlgorithm")}
						onChange={onAlgorithmChange}
						options={approvalAlgorithmOptions}
						sort={sortByName}
						value={approvalAlgorithm || null}
						required
						optionType={getOptionType}
					/>
				</Form.Field>
				<Form.Field>
					<Select
						disabled={!ownerOptions?.length}
						errors={[ownerError]}
						getOptionKey={option => `${option.user.id}-${option.optionValue}`}
						getOptionLabel={getFullNameLabel}
						isOptionEqualToValue={userEquality}
						label={t("pages.integration.resource.owner")}
						loading={isLoading}
						onChange={onOwnerChange}
						options={ownerOptions}
						renderLabel={renderLabel}
						sort={sortOwners}
						value={selectedOwner || null}
						inputValue={ownerQuery}
						onInputChange={onOwnerInputChange}
						optionType="userWithEmail"
					/>
				</Form.Field>
				{integration?.virtual && (
					<Form.Field>
						<div className={classes.defaultRoleForm}>
							<Input
								label={
									<Typography variant="small" className={classes.roleName}>
										<Tooltip content={t("pages.integration.resource.roleTooltip")}>
											<InfoIcon size="small" color="var(--color-blue-light)" />
										</Tooltip>
										{t("pages.integration.resource.roleName")}
									</Typography>
								}
								value={roleName}
								validators={nameValidators}
								onValueChange={setRoleName}
								isRequired
							/>
							<div className={classes.virtualizedRoleSelectInputs}>
								<VirtualizedRoleSelectInputs
									clearVirtualRoleFormRef={clearVirtualRoleFormRef}
									setVirtualizedRoleId={setVirtualizedRoleId}
								/>
							</div>
						</div>
					</Form.Field>
				)}
				<Form.Field>
					<DurationsOverrideSelect
						overrideAllowedDurations={overrideAllowedDurations}
						setOverrideAllowedDurations={setOverrideAllowedDurations}
						allowedDurations={allowedDurations}
						setAllowedDurations={setAllowedDurations}
						disabled={!integration}
						type="resource"
					/>
				</Form.Field>
				<Form.Actions className={classes.actions}>
					<Button variant="primary" type="submit" size="medium" loading={loading} disabled={!isValid} onClick={submit}>
						{t("buttons.create")}
					</Button>
				</Form.Actions>
			</div>
		</Form>
	);
};

const getNameLabel = (option: { name: string }) => option.name;
const workflowEquality = (option: { id: string }, value: { id: string }) => option.id === value.id;
const userEquality = (option: TOwnerOption, value: TOwnerOption) => option.optionValue === value.optionValue;
const getFullNameLabel = (option: TOwnerOption) => option.user.fullName;
const renderLabel = (option: TOwnerOption | null) => {
	return option ? (
		option.optionValue ? (
			<UserOption option={option.user} noEmail />
		) : (
			<LegacyInheritOwnerOption emphasis="user" user={option.user} />
		)
	) : (
		<></>
	);
};

const sortOwners = (options: TOwnerOption[]) => {
	const [inheritOwner, otherOwners] = partition(options, ({ optionValue: value }) => value === null);
	return inheritOwner.concat(
		sortByFullName(otherOwners.map(({ user }) => user)).map(user => ({ user, optionValue: user.id }))
	);
};
