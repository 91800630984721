import React from "react";
import { Tooltip } from "components/ui/Tooltip";
import { NoEditIcon } from "components/ui/Icons/NoEditIcon";
import { useTranslation } from "react-i18next";
import { useStyles } from "./styles";

export const UnmanagedRole: FC<{ size?: number }> = ({ size = 16 }) => {
	const classes = useStyles();
	const { t } = useTranslation();

	return (
		<Tooltip content={t("unmanagedRole")} className={classes.unmanagedTooltip}>
			<NoEditIcon size={size} />
		</Tooltip>
	);
};
