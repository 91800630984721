import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	button: {
		alignItems: "center",
		background: "var(--color-purple-200, #EFE8F2)",
		border: "2px solid var(--color-purple-200, #EFE8F2)",
		borderRadius: "20px",
		color: "var(--color-purple-600, #5E1D79)",
		display: "flex",
		gap: "var(--spacing-x2, 8px)",
		justifyContent: "center",
		maxWidth: "200px",
		minWidth: "160px",
		overflow: "hidden",
		"&$large": {
			padding: "var(--spacing-x2, 8px) var(--spacing-x3, 12px)"
		},
		"&$medium": {
			padding: "var(--spacing-x1, 4px) var(--spacing-x3, 12px);",
			height: "32px"
		},
		"&:hover": {
			background: "var(--color-purple-300, #DFD2E4)",
			border: "2px solid var(--color-purple-300, #5E1D79)",
			cursor: "pointer"
		},
		"&$selected": {
			background: "var(--color-purple-200, #EFE8F2)",
			border: "2px solid var(--color-purple-600, #5E1D79)"
		},
		"&$disabled": {
			background: "var(--color-gray-300, ##F3F1F3)",
			border: "2px solid var(--color-gray-300, #F3F1F3)",
			color: "var(--color-gray-450, #B2B4B8)",
			"&$selected": {
				background: "var(--color-gray-400, #E0DCE2)",
				border: "2px solid var(--color-grey-500, #B2B4B8)"
			}
		}
	},
	buttonsContainer: {
		alignItems: "center",
		borderRadius: "20px",
		background: "var(--color-purple-200, #EFE8F2)",
		display: "flex",
		justifyContent: "center",
		width: "min-content",
		"&$containerDisabled": {
			background: "var(--color-gray-300, #F3F1F3)"
		}
	},
	selected: {},
	disabled: {},
	large: {},
	medium: {}
});
