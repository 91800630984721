import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Select } from "components/ui/Select";
import { IconPrefix } from "components/ui/IconPrefix";
import { GrantedIcon } from "components/ui/Icons/GrantedIcon";
import { CloseIcon } from "components/ui/Icons/CloseIcon";
import { REQUESTABLE_OPTIONS, TRequestableOption } from "components/pages/BulkActionsPage/utils";
import { TIconPrefixOption } from "utils/ui/selectUtils";

type TProps = {
	value: boolean | null;
	onChange: (value: boolean | null) => void;
};

type TOption = TRequestableOption | null;

export const RequestableSelect: FC<TProps> = React.memo(function RequestableSelect({
	className,
	innerRef,
	value,
	onChange
}) {
	const { t } = useTranslation("translation", { keyPrefix: "pages.bulkActions.bulkActions.inputs.toThis" });

	const getLabel = useCallback((option: TOption) => (option ? t(option) : ""), [t]);

	const renderLabel = useCallback(
		(option: TOption) => {
			const Icon = option === "yes" ? GrantedIcon : option === "no" ? CloseIcon : null;
			if (!Icon) {
				return null;
			}
			return <IconPrefix size="small" Icon={Icon} content={getLabel(option)} />;
		},
		[getLabel]
	);

	const handleChange = useCallback(
		(option: TOption) => {
			onChange(option === "yes" ? true : option === "no" ? false : null);
		},
		[onChange]
	);

	const selectValue = value === true ? "yes" : value === false ? "no" : null;

	const getOptionType = useCallback(
		(option: TOption): TIconPrefixOption => {
			return {
				type: "iconPrefix",
				Icon: option === "yes" ? GrantedIcon : option === "no" ? CloseIcon : undefined,
				text: getLabel(option)
			};
		},
		[getLabel]
	);

	return (
		<Select
			className={className}
			getOptionLabel={getLabel}
			innerRef={innerRef}
			label={t("label")}
			onChange={handleChange}
			options={REQUESTABLE_OPTIONS}
			placeholder={t("placeholder", { context: "requestable" })}
			renderLabel={renderLabel}
			sort={null}
			value={selectValue}
			optionType={getOptionType}
		/>
	);
});
