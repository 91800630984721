import React from "react";
import { UserModel } from "models/UserModel";
import { Avatar } from "components/common/Avatar";
import { TooltipOnOverflow } from "components/ui/TooltipOnOverflow";
import classNames from "classnames";
import { SelectOption } from "../SelectOption/SelectOption";
import { useStyles } from "./styles";

export interface IUserWithEmailOptionProps<T> {
	disabled?: boolean;
	onSelect: (event: React.SyntheticEvent, value: T) => void;
	selected?: boolean;
	user: UserModel;
	value: T;
}

export const UserOption = <T,>({
	disabled = false,
	onSelect,
	user,
	value,
	selected = false
}: TProps<IUserWithEmailOptionProps<T>>) => {
	const classes = useStyles();

	return (
		<SelectOption onSelect={onSelect} selected={selected} disabled={disabled} value={value}>
			<div className={classes.container}>
				<Avatar user={user} size="small" />
				<div className={classes.title}>
					<TooltipOnOverflow
						textVariant="body_med"
						content={user.fullName}
						className={classNames(classes.content, { deleted: user.isDeleted })}
					/>
					<TooltipOnOverflow textVariant="text_sm_reg" content={user.email} />
				</div>
			</div>
		</SelectOption>
	);
};
