import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Select } from "components/ui/Select";
import { DURATION_OPTIONS, TApprovalAlgorithmRuleDuration, TSharedDurations } from "utils/durationsOptions";
import type { TTextOption } from "utils/ui/selectUtils";

interface IProps {
	disabled?: boolean;
	onChange: (duration: TApprovalAlgorithmRuleDuration) => void;
	value: TApprovalAlgorithmRuleDuration;
}

const OPTIONS = DURATION_OPTIONS.filter(option => option !== -1 && option !== null) as TSharedDurations[];

export const DurationSelectInput: FC<IProps> = ({ value, onChange, className, disabled }) => {
	const { t } = useTranslation();

	const renderLabel = useCallback(
		(option: TApprovalAlgorithmRuleDuration) => (option ? t(`common.durations.${option}`) : ""),
		[t]
	);

	const getOptionType = useCallback(
		(option: TApprovalAlgorithmRuleDuration): TTextOption => ({
			type: "text",
			option: option ? t(`common.durations.${option}`) : ""
		}),
		[t]
	);

	return (
		<Select
			className={className}
			disabled={disabled}
			getOptionLabel={renderLabel}
			onChange={onChange}
			options={OPTIONS}
			placeholder={t("flow.anyDuration")}
			value={value}
			optionType={getOptionType}
		/>
	);
};
