import React from "react";
import { useTranslation } from "react-i18next";
import { getIntegrationLogsFields } from "api/sessionAuditLogs";
import { FilterExpressionEmptyState } from "components/common/FilterExpressionEmptyState";
import { AccountIcon } from "components/ui/Icons/AccountIcon";
import { IntegrationLogsMultiSelectFilterExpression } from "../IntegrationLogsMultiSelectFilterExpression";

type TAccountFilterExpressionProps = {
	onRemove: () => void;
};

export const AccountFilterExpression: FC<TAccountFilterExpressionProps> = ({ onRemove }) => {
	const { t } = useTranslation();

	return (
		<IntegrationLogsMultiSelectFilterExpression
			title={t("pages.auditLog.integrationLogs.filter.account")}
			inputPlaceholder={t("pages.auditLog.integrationLogs.filter.accountPlaceholder")}
			filterEmptyState={
				<FilterExpressionEmptyState
					text={t("pages.auditLog.integrationLogs.filter.accountEmptyState")}
					Icon={AccountIcon}
				/>
			}
			onFilterRemove={onRemove}
			fetchOptions={getIntegrationLogsFields}
			renderType="AccountNode"
			filterName="account"
			filterField="author_display_name"
			optionType="text"
		/>
	);
};
