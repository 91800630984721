import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	roleLabelWithIcon: {
		display: "flex",
		alignItems: "center",
		justifyContent: "space-between",
		gap: "var(--spacing-x2, 8px)",
		width: "100%",
		height: "100%"
	},
	roleLabel: {
		display: "flex",
		flexDirection: "column",
		alignItems: "flex-start"
	},
	roleContainer: {
		width: "100%",
		textOverflow: "ellipsis",
		whiteSpace: "nowrap",
		overflow: "hidden"
	},
	ellipsis: {
		whiteSpace: "nowrap",
		textOverflow: "ellipsis",
		overflow: "hidden",
		width: "100%"
	}
});
