import React, { useMemo } from "react";
import { DirectoryGroupModel } from "models/DirectoryGroupModel";
import { useDirectoryGroup } from "hooks/useDirectoryGroup";
import { sourceIcons, TSources } from "utils/ui/sourceIcons";
import { requirePropertyOf } from "utils/security";
import { TooltipOnOverflow } from "components/ui/TooltipOnOverflow";
import classNames from "classnames";
import { SelectOption } from "../SelectOption/SelectOption";
import { useStyles } from "./styles";
export interface IDirectoryGroupOptionProps<T> {
	disabled?: boolean;
	onSelect: (event: React.SyntheticEvent, value: T) => void;
	selected?: boolean;
	directoryGroup: DirectoryGroupModel | string;
	value: T;
}

const sourceRegex = new RegExp(/\[([A-z]+)\] (.*)/);

export const DirectoryGroupOption = <T,>({
	disabled = false,
	onSelect,
	directoryGroup,
	value,
	innerRef,
	selected = false
}: TProps<IDirectoryGroupOptionProps<T>>) => {
	const currentGroup = useDirectoryGroup(typeof directoryGroup === "string" ? directoryGroup : undefined);
	const remoteGroup =
		typeof directoryGroup === "string" && currentGroup ? currentGroup : (directoryGroup as DirectoryGroupModel);

	const classes = useStyles();

	const { name, Icon } = useMemo(() => {
		const match = sourceRegex.exec(remoteGroup.name);
		if (!match) {
			return {
				name: remoteGroup.name,
				icon: null
			};
		}

		const iconString = match[1] as TSources;
		const name = match[2];
		const Icon = requirePropertyOf(sourceIcons, iconString);

		return {
			name,
			Icon
		};
	}, [remoteGroup]);

	return (
		<SelectOption onSelect={onSelect} selected={selected} disabled={disabled} value={value}>
			{remoteGroup.email ? (
				<div className={classes.container} ref={innerRef}>
					{Icon && <Icon className={classes.iconWithEmail} />}
					<div className={classes.title}>
						<TooltipOnOverflow
							content={name}
							textVariant="body_med"
							className={classNames(classes.content, { deleted: remoteGroup.isDeleted })}
						/>

						<TooltipOnOverflow content={remoteGroup.email} textVariant="text_sm_reg" />
					</div>
				</div>
			) : (
				<div className={classes.container} ref={innerRef}>
					{Icon && <Icon className={classes.icon} />}
					<TooltipOnOverflow content={name} textVariant="body_med" className={classes.text} />
				</div>
			)}
		</SelectOption>
	);
};
