import { useTicketRenewalContext } from "context/renewalTicketContext";
import { useTicketUpdatesContext } from "context/ticketUpdatesContext";
import { useCallback } from "react";

export const useTicketRenewal = (ticketId: string) => {
	const {
		state: { renewedIds },
		actions: { renewTicket }
	} = useTicketRenewalContext();
	const { notifyTicketUpdate } = useTicketUpdatesContext();

	const renewed = renewedIds.has(ticketId);

	const onRenewTicket = useCallback(
		async (ticketId: string) => {
			await renewTicket(ticketId);
			notifyTicketUpdate(ticketId);
		},
		[renewTicket, notifyTicketUpdate]
	);
	return { renewed, renewTicket: onRenewTicket };
};
