import React from "react";
import { OnCallIntegrationScheduleModel } from "models/OnCallIntegrationScheduleModel";
import { ON_CALL_INTEGRATIONS_LOGOS } from "utils/ui/onCall";
import { TooltipOnOverflow } from "components/ui/TooltipOnOverflow";
import { useOnCallIntegrationSchedules } from "hooks/useOnCallIntegrationSchedules";
import classNames from "classnames";
import { SelectOption } from "../SelectOption/SelectOption";
import { useStyles } from "./styles";

export interface IOnCallIntegrationScheduleOptionProps<T> {
	disabled?: boolean;
	onSelect: (event: React.SyntheticEvent, value: T) => void;
	selected?: boolean;
	onCallIntegrationSchedule: OnCallIntegrationScheduleModel | string;
	value: T;
}

export const OnCallIntegrationScheduleOption = <T,>({
	value,
	disabled = false,
	onSelect,
	onCallIntegrationSchedule,
	selected = false
}: TProps<IOnCallIntegrationScheduleOptionProps<T>>) => {
	const classes = useStyles();

	const onCallIntegrationSchedules = useOnCallIntegrationSchedules();
	const currentOnCallIntegrationSchedule = onCallIntegrationSchedules?.get(
		typeof onCallIntegrationSchedule === "string" ? onCallIntegrationSchedule : onCallIntegrationSchedule.id
	);

	const Icon =
		currentOnCallIntegrationSchedule?.type && ON_CALL_INTEGRATIONS_LOGOS.get(currentOnCallIntegrationSchedule.type);

	return (
		<SelectOption onSelect={onSelect} selected={selected} disabled={disabled} value={value} PrefixIcon={Icon}>
			{currentOnCallIntegrationSchedule ? (
				<TooltipOnOverflow
					textVariant="body_reg"
					content={currentOnCallIntegrationSchedule.name}
					className={classNames(classes.content, { deleted: currentOnCallIntegrationSchedule.isDeleted })}
				/>
			) : null}
		</SelectOption>
	);
};
