import React, { useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useFetchedState } from "hooks/useFetchedState";
import { getUserPastTickets } from "api/user";
import { Typography } from "components/ui/Typography";
import { StaticChip } from "components/ui/chips/StaticChip";
import { useTicketUpdatesContext } from "context/ticketUpdatesContext";
import { LoadingDots } from "components/ui/LoadingDots";
import { RequestBar } from "components/common/RequestBar";
import { Section } from "components/ui/Section";
import { RequestList } from "components/common/RequestList";
import { LinkButton } from "components/ui/Button";
import { routes } from "routes/routes";
import { useStyles } from "./styles";

const SHOWN_REQUESTS_SIZE = 5;
const RECENT_REQUESTS_SECTION_UPDATED_REQUESTS_SUBSCRIPTION_ID = "recentRequestsSectionUpdatedRequests";

const useRecentRequests = () => {
	const getPastRequests = useCallback(
		() =>
			getUserPastTickets({
				pagination: { perPage: SHOWN_REQUESTS_SIZE },
				sort: { sortFields: ["ticketNumber"], order: "DESC" }
			}),
		[]
	);
	const { data, fetchingState, isLoading, loadData, retryAction } = useFetchedState(getPastRequests);

	useEffect(() => {
		loadData();
	}, [loadData]);

	return { data, fetchingState, isLoading, reloadData: retryAction };
};

export const RecentRequestsSection: FC<TProps> = ({ className, innerRef }) => {
	const classes = useStyles();
	const { t } = useTranslation();
	const { data, fetchingState, isLoading, reloadData } = useRecentRequests();
	const { subscribeTicketUpdates, unsubscribeTicketUpdates } = useTicketUpdatesContext();

	const onUpdatedRequest = useCallback(() => {
		reloadData();
	}, [reloadData]);

	useEffect(() => {
		subscribeTicketUpdates(RECENT_REQUESTS_SECTION_UPDATED_REQUESTS_SUBSCRIPTION_ID, onUpdatedRequest);
		return () => {
			unsubscribeTicketUpdates(RECENT_REQUESTS_SECTION_UPDATED_REQUESTS_SUBSCRIPTION_ID);
		};
	}, [onUpdatedRequest, subscribeTicketUpdates, unsubscribeTicketUpdates]);

	const requests = useMemo(() => {
		if (!data) return [];
		return data.result.toArray();
	}, [data]);

	const title = useMemo(() => {
		const pastRequestsTextTitle = t("pages.requests.recentRequestsSection.title");
		return (
			<>
				<Typography variant="body_sb">{pastRequestsTextTitle}</Typography>
				<StaticChip size="small" variant="regular">
					{t("number", { value: Math.min(Number(data?.pagination.totalResults || 0), SHOWN_REQUESTS_SIZE) })}
				</StaticChip>
			</>
		);
	}, [data?.pagination.totalResults, t]);

	const requestBars = useMemo(() => {
		if (isLoading) return <LoadingDots center />;
		if (!requests.length) return null;
		return requests.map(request => {
			return <RequestBar key={request.id} request={request} type="past" openSidebarOnClick />;
		});
	}, [isLoading, requests]);

	const hasNoRequests = fetchingState === "Loaded" && requests.length === 0;

	return (
		<Section
			className={className}
			innerRef={innerRef}
			title={title}
			titleActions={
				<LinkButton size="medium" variant="secondary" to={routes.requests.subRoutes!.past.location}>
					{t("pages.requests.recentRequestsSection.linkText")}
				</LinkButton>
			}>
			{hasNoRequests ? (
				<Typography variant="body_reg" className={classes.emptyStateContainer}>
					{t("pages.requests.recentRequestsSection.emptyMessage")}
				</Typography>
			) : (
				<RequestList shownSize={requests.length >= SHOWN_REQUESTS_SIZE ? SHOWN_REQUESTS_SIZE : requests.length}>
					{requestBars}
				</RequestList>
			)}
		</Section>
	);
};
