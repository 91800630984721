import { MultipleSelect } from "components/ui/MultipleSelect";
import { ticketStatuses, TTicketStatus } from "models/TicketModel";
import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { getArrayValue, IFilterProps } from "../../";
import type { TTextOption } from "utils/ui/selectUtils";

const TRANSLATION_PREFIX = "common.ticketFilters";

export const StatusFilter: FC<IFilterProps> = ({ onFiltersChange, ticketFilters }) => {
	const { t } = useTranslation();
	const statuses = useMemo(
		() => getArrayValue(ticketFilters.status, value => t(`common.ticketStatus.${value}`)),
		[t, ticketFilters.status]
	);
	const statusOptions = useMemo(
		() =>
			ticketStatuses.map(ticketStatus => ({
				value: ticketStatus,
				label: t(`common.ticketStatus.${ticketStatus}`)
			})),
		[t]
	);

	const changeStatus = useCallback(
		(status: { value: TTicketStatus; label: string }[] | null) => {
			onFiltersChange(current => ({
				...current,
				status: status?.map(({ value }) => value) || undefined
			}));
		},
		[onFiltersChange]
	);

	const getOptionType = useCallback((option: { value: TTicketStatus; label: string }): TTextOption => {
		return { type: "text", option: option.label };
	}, []);

	return (
		<MultipleSelect
			options={statusOptions}
			value={statuses || []}
			onChange={changeStatus}
			label={t(`${TRANSLATION_PREFIX}.status`)}
			optionType={getOptionType}
		/>
	);
};
