import React from "react";
import { DirectoryGroupModel } from "models/DirectoryGroupModel";
import { GroupCard } from "components/common/GroupCard";
import { useDirectoryGroup } from "hooks/useDirectoryGroup";
import { SelectOption } from "../SelectOption/SelectOption";
import { useStyles } from "./styles";

export interface IGroupNodeOptionProps<T> {
	disabled?: boolean;
	onSelect: (event: React.SyntheticEvent, value: T) => void;
	selected?: boolean;
	group: DirectoryGroupModel | string;
	value: T;
}

export const GroupNodeOption = <T,>({
	disabled = false,
	onSelect,
	group,
	value,
	selected = false
}: TProps<IGroupNodeOptionProps<T>>) => {
	const classes = useStyles();

	const currentGroup = useDirectoryGroup(typeof group === "string" ? group : undefined);
	const remoteGroup = typeof group === "string" && currentGroup ? currentGroup : (group as DirectoryGroupModel);
	return (
		<SelectOption onSelect={onSelect} disabled={disabled} value={value} readonly>
			<GroupCard group={remoteGroup} selected={selected} className={classes.group} />
		</SelectOption>
	);
};
