import React from "react";
import { useTranslation } from "react-i18next";
import { getIntegrationLogsFields } from "api/sessionAuditLogs";
import { FilterExpressionEmptyState } from "components/common/FilterExpressionEmptyState";
import { IntegrationLogsMultiSelectFilterExpression } from "../IntegrationLogsMultiSelectFilterExpression";

type TActionFilterExpressionProps = {
	onRemove: () => void;
};

export const ActionFilterExpression: FC<TActionFilterExpressionProps> = ({ onRemove }) => {
	const { t } = useTranslation();

	return (
		<IntegrationLogsMultiSelectFilterExpression
			fetchOptions={getIntegrationLogsFields}
			title={t("pages.auditLog.integrationLogs.filter.action")}
			filterEmptyState={
				<FilterExpressionEmptyState text={t("pages.auditLog.integrationLogs.filter.actionEmptyState")} />
			}
			inputPlaceholder={t("pages.auditLog.integrationLogs.filter.actionPlaceholder")}
			onFilterRemove={onRemove}
			filterField="action_display_name"
			filterName="action"
			optionType="text"
		/>
	);
};
