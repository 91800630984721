import { revokePermission } from "api/integrationActorPermissions";
import { getUserEntitlements } from "api/users";
import { List } from "immutable";
import { UserEntitlementModel } from "models/UserEntitlementModel";
import { UserModel } from "models/UserModel";
import { useState, useMemo, useCallback, useEffect } from "react";

export const useEntitlementsTable = (user: UserModel, withUnmanaged = false) => {
	const [entitlements, setEntitlements] = useState<List<UserEntitlementModel>>();

	const loadEntitlements = useCallback(async () => {
		setEntitlements(await getUserEntitlements(user.id, withUnmanaged));
	}, [user.id, withUnmanaged]);

	useEffect(() => {
		loadEntitlements();
	}, [loadEntitlements]);

	const onRevokePermission = useCallback(
		async (entitlement: UserEntitlementModel) => {
			const permission = await revokePermission(entitlement.id);
			const index = entitlements?.findIndex(value => value === entitlement);
			setEntitlements(entitlements => entitlements!.setIn([index, "revokeStatus"], permission.revokeStatus));
		},
		[entitlements]
	);

	const displayedEntitlements = useMemo(() => entitlements?.toArray() || [], [entitlements]);

	return {
		loading: !entitlements,
		displayedEntitlements,
		onRevokePermission
	};
};
