import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	container: {
		display: "flex",
		gap: "var(--spacing-x2, 8px)",
		width: "100%",
		alignItems: "center"
	},
	title: {
		display: "flex",
		flexDirection: "column",
		alignItems: "start",
		overflow: "hidden",
		justifyContent: "center"
	},
	content: {
		"&.deleted": {
			"&::after": {
				color: "var(--color-red)",
				content: "'*'"
			}
		}
	}
});
