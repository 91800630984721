import React, { useCallback, useMemo } from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { Select } from "components/ui/Select";
import { useGraphResourceTypes } from "components/pages/IdentityGraphPage/graphHooks";
import { useStyles } from "./styles";

type TProps = {
	disabled?: boolean;
	integrationId: string | null;
	onChange: (resourceType: string | null) => void;
	value: string | null;
};

export const ResourceTypesSelect: FC<TProps> = ({ className, disabled, innerRef, integrationId, onChange, value }) => {
	const { t } = useTranslation();
	const classes = useStyles();
	const { resourceTypes, isLoading } = useGraphResourceTypes(integrationId);
	const options = useMemo(() => resourceTypes ?? [], [resourceTypes]);

	const renderLabel = useCallback((option: string) => <div className={classes.text}>{option}</div>, [classes]);

	return (
		<Select
			className={classNames(classes.container, className)}
			disabled={disabled}
			innerRef={innerRef}
			label={t("common.resourceTypeSelect.label")}
			limit={20}
			loading={isLoading}
			onChange={onChange}
			options={options}
			placeholder={t("common.resourceTypeSelect.placeholder")}
			renderLabel={renderLabel}
			value={value}
			optionType="text"
		/>
	);
};
