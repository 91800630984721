import React, { useCallback, useMemo } from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { Divider } from "components/ui/Divider";
import { IconPrefix } from "components/ui/IconPrefix";
import { StaticChip } from "components/ui/chips/StaticChip";
import { FilterIcon } from "components/ui/Icons/FilterIcon";
import { IconButton } from "components/ui/IconButton";
import { CloseIcon } from "components/ui/Icons/CloseIcon";
import { useStyles } from "./styles";
import { FilterSelectionSection } from "./components/FilterSelectionSection";
import { FilterExpressionsSection } from "./components/FilterExpressionsSection";
import type { TFilterName } from "filters";
import type { IFilter } from "types/filters";
import type { TBulkActionTabOption, TFormFilters } from "../../types";

type TProps = {
	filters: TFormFilters;
	onClear: (type: TBulkActionTabOption) => void;
	onChangeFilter: (filter: IFilter, isValid?: boolean) => void;
	onClose: () => void;
	onToggleFilter: (filterName: TFilterName) => void;
	type: TBulkActionTabOption;
};

export const FilterForm: FC<TProps> = ({
	className,
	filters,
	innerRef,
	onClear,
	onChangeFilter,
	onClose,
	onToggleFilter,
	type
}) => {
	const { t } = useTranslation("translation", { keyPrefix: "pages.bulkActions.filterForm" });
	const { t: globalTranslation } = useTranslation();
	const classes = useStyles();

	const filterNames = useMemo(() => filters.keySeq().toArray(), [filters]);
	const validFilters = useMemo(() => filters.filter(filter => filter.isValid), [filters]);

	const handleFilterChange = useCallback(
		(filter: IFilter | TFilterName, isValid?: boolean) => {
			const removeFilter = typeof filter === "string";
			if (removeFilter) {
				onToggleFilter(filter);
			} else {
				onChangeFilter(filter, isValid);
			}
		},
		[onToggleFilter, onChangeFilter]
	);

	const onFilterSelection = useCallback(
		(filterName: TFilterName) => {
			if (validFilters.has(filterName)) return;
			onToggleFilter(filterName);
		},
		[onToggleFilter, validFilters]
	);

	return (
		<div className={classNames(classes.container, className)} ref={innerRef}>
			<div className={classes.header}>
				<div className={classes.title}>
					<div className={classes.titleLeft}>
						<IconPrefix size="large" content={t("title")} Icon={FilterIcon} />
						<StaticChip size="small">{globalTranslation("number", { value: validFilters.size })}</StaticChip>
					</div>
					<IconButton onClick={onClose}>
						<CloseIcon />
					</IconButton>
				</div>
				<Divider horizontal />
			</div>
			<FilterSelectionSection filters={filterNames} type={type} selectFilter={onFilterSelection} />
			<FilterExpressionsSection onChangeFilter={handleFilterChange} filters={filters} onClear={onClear} type={type} />
		</div>
	);
};
