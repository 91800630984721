import React, { useCallback, useState } from "react";
import { Input } from "components/ui/Input";
import { CalendarIcon } from "components/ui/Icons/CalendarIcon";
import { DateRange } from "react-day-picker";
import { useTranslation } from "react-i18next";
import { FilterHeader } from "../FilterHeader";
import { useStyles } from "./styles";
import { TimePicker } from "./components/TimePicker";
import { DatePicker } from "./components/DatePicker";
import type { TDateTimeFilterProps } from "../../types";

const FROM_DEFULT_TIME = new Date(0, 0, 0, 9, 0);
const TO_DEFULT_TIME = new Date(0, 0, 0, 21, 0);
export const DateTimeFilter: FC<TDateTimeFilterProps> = ({
	className,
	innerRef,
	title,
	onReset,
	onRemoveFilter,
	onOptionSelect
}) => {
	const classes = useStyles();
	const { t } = useTranslation();
	const [dateRange, setDateRange] = useState<DateRange>();
	const [fromDateTime, setFromDateTime] = useState<{ hours: number; minutes: number }>();
	const [toDateTime, setToDateTime] = useState<{ hours: number; minutes: number }>();
	const [fromDateInputValue, setFromDateInputValue] = useState("");
	const [lastFromDateValidInput, setLastFromDateValidInput] = useState("");
	const [toDateInputValue, setToDateInputValue] = useState("");
	const [lastToDateValidInput, setLastToDateValidInput] = useState("");

	const handleDateChange = useCallback(
		(range?: DateRange) => {
			const newFromDate = range?.from ? new Date(range.from) : undefined;
			const newToDate = range?.to ? new Date(range.to) : undefined;

			newFromDate?.setHours(FROM_DEFULT_TIME.getHours());
			newFromDate?.setMinutes(FROM_DEFULT_TIME.getMinutes());

			newToDate?.setHours(TO_DEFULT_TIME.getHours());
			newToDate?.setMinutes(TO_DEFULT_TIME.getMinutes());

			setDateRange({ from: newFromDate, to: newToDate });
			onOptionSelect({ from: newFromDate, to: newToDate });

			if (range?.from === range?.to) {
				setFromDateInputValue("");
				setToDateInputValue(
					range?.to?.toLocaleDateString([], { month: "long", day: "2-digit", year: "numeric" }) || ""
				);
			} else {
				setFromDateInputValue(
					range?.from?.toLocaleDateString([], { month: "long", day: "2-digit", year: "numeric" }) || ""
				);
				setToDateInputValue(
					range?.to?.toLocaleDateString([], { month: "long", day: "2-digit", year: "numeric" }) || ""
				);
			}
		},
		[onOptionSelect]
	);

	const handleFromTimeChange = useCallback(
		(dateTime: Date) => {
			setDateRange(current => {
				const fromDate = current?.from ? new Date(current.from) : undefined;

				fromDate?.setHours(dateTime.getHours());
				fromDate?.setMinutes(dateTime.getMinutes());
				current?.to?.setHours(toDateTime?.hours || TO_DEFULT_TIME.getHours());
				current?.to?.setMinutes(toDateTime?.minutes || TO_DEFULT_TIME.getMinutes());

				setFromDateTime({
					hours: fromDate?.getHours() || FROM_DEFULT_TIME.getHours(),
					minutes: fromDate?.getMinutes() || FROM_DEFULT_TIME.getMinutes()
				});
				const newDateRange = { from: fromDate, to: current?.to };
				onOptionSelect(newDateRange);
				return newDateRange;
			});
		},
		[onOptionSelect, toDateTime?.hours, toDateTime?.minutes]
	);

	const handleToTimeChange = useCallback(
		(dateTime: Date) => {
			setDateRange(current => {
				const toDate = current?.to ? new Date(current.to) : undefined;

				toDate?.setHours(dateTime.getHours());
				toDate?.setMinutes(dateTime.getMinutes());
				current?.to?.setHours(fromDateTime?.hours || FROM_DEFULT_TIME.getHours());
				current?.to?.setMinutes(fromDateTime?.minutes || FROM_DEFULT_TIME.getMinutes());

				setToDateTime({
					hours: toDate?.getHours() || TO_DEFULT_TIME.getHours(),
					minutes: toDate?.getMinutes() || TO_DEFULT_TIME.getMinutes()
				});

				const newDateRange = { from: current?.from, to: toDate };
				onOptionSelect(newDateRange);
				return newDateRange;
			});
		},
		[fromDateTime?.hours, fromDateTime?.minutes, onOptionSelect]
	);

	const handleFromDateInputChange = useCallback(
		(event: React.FocusEvent<HTMLInputElement, Element>) => {
			const date = new Date(event.currentTarget.value);
			if (isNaN(date.getTime())) {
				setFromDateInputValue(lastFromDateValidInput);
			} else {
				setDateRange(current => {
					onOptionSelect({ from: date, to: current?.to });
					return { from: date, to: current?.to };
				});
				setLastFromDateValidInput(event.currentTarget.value);
			}
		},
		[lastFromDateValidInput, onOptionSelect]
	);

	const handleToDateInputChange = useCallback(
		(event: React.FocusEvent<HTMLInputElement, Element>) => {
			const date = new Date(event.currentTarget.value);
			if (isNaN(date.getTime())) {
				setToDateInputValue(lastToDateValidInput);
			} else {
				setDateRange(current => {
					if (current?.from && date < current?.from) {
						onOptionSelect({ from: current?.from, to: current?.from });

						return { from: current?.from, to: current?.from };
					}
					onOptionSelect({ from: current?.from, to: date });
					return { from: current?.from, to: date };
				});
				setLastToDateValidInput(event.currentTarget.value);
			}
		},
		[lastToDateValidInput, onOptionSelect]
	);

	return (
		<>
			<FilterHeader
				className={className}
				title={title}
				onRemoveFilter={onRemoveFilter}
				onReset={onReset}
				innerRef={innerRef}
			/>
			<DatePicker onRangeChange={handleDateChange} dateRange={dateRange} />
			<div className={classes.inputsContainer}>
				<div className={classes.timerAndInput}>
					<Input
						disabled={dateRange && dateRange?.from === dateRange?.to && dateRange.from !== undefined}
						value={fromDateInputValue}
						className={classes.inputs}
						onBlur={handleFromDateInputChange}
						placeholder={t("dateTime.from")}
						onValueChange={setFromDateInputValue}
						prefix={<CalendarIcon />}
					/>
					<TimePicker dateTime={FROM_DEFULT_TIME} onTimeChange={handleFromTimeChange} />
				</div>
				<div className={classes.timerAndInput}>
					<Input
						placeholder={t("dateTime.to")}
						className={classes.inputs}
						onBlur={handleToDateInputChange}
						value={toDateInputValue}
						onValueChange={setToDateInputValue}
						prefix={<CalendarIcon />}
					/>
					<TimePicker dateTime={TO_DEFULT_TIME} onTimeChange={handleToTimeChange} />
				</div>
			</div>
		</>
	);
};
