import React, { useCallback, useEffect, useState } from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { Select, TTargetValue } from "components/ui/Select";
import { TicketingIntegrationTicketModel } from "models/TicketingIntegrationTicketModel";
import { useAbortController } from "hooks/useAbortController";
import { useLoadingState } from "hooks/useLoadingState";
import { useOpenGlobalErrorModal } from "hooks/useGlobalError";
import { getTicketingIntegrationTicket, searchTicketingIntegrationTickets } from "api/ticketingIntegrationTickets";
import { useDebounceFn } from "hooks/useDebounce";
import { getTicketingIntegrationLogoByType } from "utils/tickets/ticketingIntegrationLogos";
import { Typography } from "components/ui/Typography";
import { Tooltip } from "components/ui/Tooltip";
import { useStyles } from "./styles";
import type { TIconPrefixOption } from "utils/ui/selectUtils";

const MIN_QUERY_LENGTH = 2;

type TProps = {
	placeholder: string;
	value: string | null;
	onChange: (value: string | null) => void;
	onTicketNotFound?: () => void;
	maxDropdownHeight?: number; // in px
};

const getOptionLabel = (option: TicketingIntegrationTicketModel): string => option.name;
const isOptionEqualToValue = (option: TicketingIntegrationTicketModel, value: TicketingIntegrationTicketModel) =>
	option.id === value.id;
const getOptionKey = (option: TicketingIntegrationTicketModel): string => {
	return option.id;
};

export const TicketingIntegrationSelect: FC<TProps> = ({
	className,
	innerRef,
	placeholder,
	value,
	onChange,
	onTicketNotFound,
	maxDropdownHeight = 576
}) => {
	const classes = useStyles();
	const { t } = useTranslation();
	const [query, setQuery] = useState("");
	const [selectedTicket, setSelectedTicket] = useState<TicketingIntegrationTicketModel | null>(null);
	const [options, setOptions] = useState<TicketingIntegrationTicketModel[]>([]);
	const { abortWrapper, removeAbortController } = useAbortController();
	const { isLoading, withLoader } = useLoadingState();
	const openGlobalErrorModal = useOpenGlobalErrorModal();

	const changeOrAbort = useCallback(
		async (newQuery: string) => {
			if (newQuery.length >= MIN_QUERY_LENGTH) {
				const runSearch = async () => {
					const results = await abortWrapper(
						async (abortSignal: AbortSignal) =>
							await searchTicketingIntegrationTickets(newQuery, value ? [value] : [], abortSignal)
					);

					if (results) {
						removeAbortController();
						setOptions(results);
					} else {
						removeAbortController();
						setOptions([]);
					}
				};
				withLoader(runSearch());
			} else {
				setOptions([]);
			}
		},
		[abortWrapper, removeAbortController, value, withLoader]
	);
	const [debouncedChangeOrAbort] = useDebounceFn(changeOrAbort, 150);

	const oncQueryChanged = useCallback(
		async (e: TTargetValue | React.ChangeEvent<HTMLInputElement>) => {
			const newQuery = e.target.value;
			setQuery(newQuery);
			await debouncedChangeOrAbort(newQuery);
		},
		[debouncedChangeOrAbort]
	);

	const handleChange = useCallback(
		(option: TicketingIntegrationTicketModel | null) => {
			setSelectedTicket(option);
			onChange(option ? option.id : null);
		},
		[onChange]
	);

	const renderLabel = useCallback(
		(option: TicketingIntegrationTicketModel) => {
			const Logo = getTicketingIntegrationLogoByType(option.ticketingIntegration);
			return (
				<Tooltip content={`${option.publicEuid} - ${option.name}`}>
					<div className={classes.optionLabelContainer}>
						{Logo && <Logo className={classNames(classes.logo, classes.labelLogo)} />}
						<Typography variant="text_title_sb" noWrap>{`${option.publicEuid} - ${option.name}`}</Typography>
					</div>
				</Tooltip>
			);
		},
		[classes.optionLabelContainer, classes.logo, classes.labelLogo]
	);

	useEffect(() => {
		if (value && !selectedTicket) {
			withLoader(
				(async () => {
					const ticket = await getTicketingIntegrationTicket(value);
					if (ticket) {
						setSelectedTicket(ticket);
					} else {
						onTicketNotFound?.();
					}
				})()
			);
		}
	}, [onTicketNotFound, handleChange, openGlobalErrorModal, selectedTicket, t, value, withLoader]);

	const getOptionType = useCallback((option: TicketingIntegrationTicketModel): TIconPrefixOption => {
		return {
			type: "iconPrefix",
			Icon: getTicketingIntegrationLogoByType(option.ticketingIntegration),
			text: `${option.publicEuid} - ${option.name}`
		};
	}, []);

	return (
		<Select
			className={className}
			filter={null}
			getOptionKey={getOptionKey}
			getOptionLabel={getOptionLabel}
			innerRef={innerRef}
			inputValue={query}
			isOptionEqualToValue={isOptionEqualToValue}
			loading={isLoading}
			maxDropdownHeight={maxDropdownHeight}
			noOptionsText={query.length >= 2 ? undefined : placeholder}
			onChange={handleChange}
			onInputChange={oncQueryChanged}
			options={options}
			placeholder={placeholder}
			renderLabel={renderLabel}
			sort={null}
			value={selectedTicket}
			optionType={getOptionType}
		/>
	);
};
