import React, { useCallback, useMemo } from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { Select } from "components/ui/Select";
import { sortByDisplayName } from "utils/sortUtils";
import { IntegrationActorModel } from "models/IntegrationActorModel";
import { useUserEntitlementsAccounts } from "../../myPermissionsHooks";
import { useStyles } from "./styles";

type TProps = {
	disabled?: boolean;
	integrationId: string | null;
	onChange: (accountId: string | null) => void;
	value: string | null;
};

const isEqual = (option: IntegrationActorModel, value: IntegrationActorModel) => option.id === value.id;
const getLabel = (option: IntegrationActorModel) => option.displayName;

export const AccountsSelect: FC<TProps> = ({
	className,
	disabled,
	innerRef,
	onChange: propOnChange,
	value: propValue,
	integrationId
}) => {
	const { t } = useTranslation();
	const classes = useStyles();
	const { accounts, isLoading } = useUserEntitlementsAccounts(integrationId);
	const options = useMemo(() => (accounts ? accounts.valueSeq().toArray() : []), [accounts]);

	const renderLabel = useCallback(
		(option: IntegrationActorModel) => <div className={classes.text}>{option.displayName}</div>,
		[classes]
	);

	const onChange = useCallback(
		(value: IntegrationActorModel | null) => {
			propOnChange(value?.id ?? null);
		},
		[propOnChange]
	);

	const value = useMemo(() => {
		return accounts?.get(propValue || "") ?? null;
	}, [accounts, propValue]);

	return (
		<Select
			className={classNames(classes.container, className)}
			disabled={disabled}
			getOptionLabel={getLabel}
			innerRef={innerRef}
			isOptionEqualToValue={isEqual}
			label={t("common.accountSelectInput.label")}
			limit={20}
			loading={isLoading}
			onChange={onChange}
			options={options}
			placeholder={t("common.accountSelectInput.placeholder")}
			renderLabel={renderLabel}
			size="medium"
			sort={sortByDisplayName}
			value={value}
			optionType="account"
		/>
	);
};
