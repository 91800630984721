import React, { useCallback, useMemo, useState } from "react";
import { List } from "immutable";
import { Typography } from "components/ui/legacy/Typography";
import { useTranslation } from "react-i18next";
import { CommonAccordion } from "components/common/CommonAccordion";
import { Table } from "components/ui/Table";
import { IntegrationModel } from "models/IntegrationModel";
import { Button } from "components/ui/Button";
import { AddIcon } from "components/ui/Icons/AddIcon";
import { useOpenGlobalErrorModal } from "hooks/useGlobalError";
import { HelpBlock } from "components/ui/Block";
import { MaintainersIcon } from "components/ui/Icons/MaintainersIcon";
import { UserModel } from "models/UserModel";
import { UserMaintainerModel } from "models/UserMaintainerModel";
import { DirectoryGroupMaintainerModel } from "models/DirectoryGroupMaintainerModel";
import { Maintainer } from "./components/Maintainer";
import { useStyles } from "./styles";
import type { DirectoryGroupModel } from "models/DirectoryGroupModel";
import type { IntegrationResourceModel } from "models/IntegrationResourceModel";

interface IProps {
	autoAssigned?: boolean;
	entity: IntegrationModel | IntegrationResourceModel;
	maintainers: List<TMaintainerModel> | null;
	onUpdate: (maintainers: List<TMaintainerModel>) => Promise<void>;
}

export const MaintainersBlock: FC<IProps> = ({ entity, maintainers, onUpdate, autoAssigned }) => {
	const [expanded, setExpanded] = useState(false);
	const { t } = useTranslation();
	const classes = useStyles();
	const [addMaintainerVisible, setAddMaintainerVisible] = useState(false);
	const openGlobalErrorModal = useOpenGlobalErrorModal();

	const showAddMaintainer = useCallback(
		(event: React.MouseEvent<HTMLButtonElement>) => {
			event.stopPropagation();
			if (!expanded) {
				setExpanded(true);
			}
			setAddMaintainerVisible(true);
		},
		[expanded]
	);
	const hideAddMaintainer = useCallback(() => setAddMaintainerVisible(false), []);

	const onRemove = useCallback(
		async (maintainer: TMaintainerModel) => {
			try {
				if (!maintainers) return;
				await onUpdate(maintainers.filter(({ id }) => id !== maintainer.id));
			} catch (err) {
				openGlobalErrorModal(err as Error);
			}
		},
		[maintainers, onUpdate, openGlobalErrorModal]
	);

	const onAdd = useCallback(
		async (maintainer: UserModel | DirectoryGroupModel) => {
			const newMaintainer =
				maintainer instanceof UserModel
					? UserMaintainerModel.fromEntity(maintainer)
					: DirectoryGroupMaintainerModel.fromEntity(maintainer);
			try {
				await onUpdate((maintainers || List<TMaintainerModel>()).push(newMaintainer));
				hideAddMaintainer();
			} catch (err) {
				openGlobalErrorModal(err as Error);
			}
		},
		[hideAddMaintainer, maintainers, onUpdate, openGlobalErrorModal]
	);

	const cantBeAdded = useMemo(() => {
		if (!maintainers) return [];
		return maintainers.map(({ entityId }) => entityId).toArray();
	}, [maintainers]);

	const maintainersIsEmpty = !maintainers || maintainers.size === 0;

	return (
		<CommonAccordion
			title={
				<div className={classes.titleContainer}>
					<Typography variant="h3" prefixIcon={<MaintainersIcon />}>
						{t("pages.integration.maintainers")}
					</Typography>

					<Button variant="secondary" size="medium" prefix={<AddIcon />} onClick={showAddMaintainer}>
						{t("buttons.add")}
					</Button>
				</div>
			}
			expanded={expanded}
			onChange={setExpanded}>
			{autoAssigned ? (
				<HelpBlock className={classes.autoAssignHelpBlock}>
					<Typography>{t("pages.integrationResource.maintainersAutoAssigned")}</Typography>
				</HelpBlock>
			) : null}

			<>
				{maintainersIsEmpty && <HelpBlock>{t("common.maintainersBlock.noMaintainersNotice")}</HelpBlock>}
				<Table gridColumns="1fr 15rem">
					<Table.Row>
						<Table.Header>
							<Typography>{t("common.maintainersBlock.maintainer")}</Typography>
						</Table.Header>
						<Table.Header></Table.Header>
					</Table.Row>
					{addMaintainerVisible && <Maintainer cancel={hideAddMaintainer} disabledIds={cantBeAdded} onSubmit={onAdd} />}
					{!maintainersIsEmpty &&
						maintainers.map(maintainer => (
							<Maintainer key={maintainer.id || maintainer.entityId} maintainer={maintainer} onRemove={onRemove} />
						))}
				</Table>
			</>
		</CommonAccordion>
	);
};
