import React, { useMemo } from "react";
import classNames from "classnames";
import { IconPrefix } from "components/ui/IconPrefix";
import { Tooltip } from "components/ui/Tooltip";
import { TitleBody } from "components/ui/TitleBody";
import { RoleIcon } from "components/ui/Icons/RoleIcon";
import { useTranslation } from "react-i18next";
import { RoleBarTag } from "../RoleBarTag";
import { ROLE_BAR_ROLE_MIN_WIDTH_PX, useStyles } from "./styles";

export const ROLE_BAR_ROLE_WIDTH = `minmax(${ROLE_BAR_ROLE_MIN_WIDTH_PX}px, 400px)`;

type TSingleRoleProps = {
	name: string;
	amount?: never;
};

type TMultipleRoleProps = {
	amount: number;

	name?: never;
};

type TProps = (TSingleRoleProps | TMultipleRoleProps) & {
	tags?: string[];
};

export const RoleBarRole: FC<TProps> = ({ className, innerRef, tags, ...restProps }) => {
	const classes = useStyles();
	const { t } = useTranslation("translation", { keyPrefix: "common.roleBar.roleBarRole" });
	const isAmount = "amount" in restProps;
	const amount = restProps.amount || 0;

	const tooltipContent = useMemo(() => {
		return (
			<div className={classes.tooltipContainer}>
				<TitleBody size="small" title={isAmount ? t("amount") : t("name")} body={isAmount ? amount : restProps.name} />
			</div>
		);
	}, [amount, classes.tooltipContainer, isAmount, restProps.name, t]);

	return (
		<div className={classNames(classes.container, className)} ref={innerRef}>
			<div className={classes.innerContainer}>
				<Tooltip content={tooltipContent}>
					<IconPrefix Icon={RoleIcon} content={isAmount ? amount : restProps.name} />
				</Tooltip>
				{tags ? <RoleBarTag tags={tags} /> : null}
			</div>
		</div>
	);
};
