import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useAuthenticatedUser } from "hooks/useAuthenticatedUser";
import { useLoadingState } from "hooks/useLoadingState";
import { respondToTicket, adminRespondToTicket, cancelTicket } from "api/tickets";
import { Button } from "components/ui/Button";
import { CloseIcon } from "components/ui/Icons/CloseIcon";
import { GrantedIcon } from "components/ui/Icons/GrantedIcon";
import { TicketExtraOptionsButton } from "components/common/TicketExtraOptionsButton";
import { CancelCircleIcon } from "components/ui/Icons/CancelCircleIcon";
import { useOpenGlobalErrorModal } from "hooks/useGlobalError";
import { useTicketUserPermissions } from "hooks/useTicketUserPermissions";
import { TicketRenewalButton } from "../TicketRenewalButton";
import { useStyles } from "./styles";
import type { TFullTicket } from "./types";
import type { TicketModel } from "models/TicketModel";

export const RequestDetailsFooter: FC<{
	ticket: TFullTicket;
	reloadTicket: (ticket?: TicketModel) => Promise<void>;
	closeSidebar: () => void;
}> = ({ ticket, reloadTicket, closeSidebar }) => {
	const { user } = useAuthenticatedUser();
	const classes = useStyles();
	const { t } = useTranslation();
	const { isLoading, withLoader } = useLoadingState();
	const openGlobalErrorModal = useOpenGlobalErrorModal();

	const { hasApproverActions, hasReceiverActions, allowAdminApprove, isApprover, hasCancel } = useTicketUserPermissions(
		ticket,
		user,
		true
	);

	const updateApprovalStatus = useCallback(
		async (approved: boolean) => {
			if (!hasApproverActions) return;
			const respondAction = allowAdminApprove ? adminRespondToTicket : respondToTicket;
			const updatedRequest = await withLoader(respondAction(ticket.id, approved));
			await reloadTicket(updatedRequest);
			closeSidebar();
		},
		[hasApproverActions, allowAdminApprove, withLoader, ticket.id, reloadTicket, closeSidebar]
	);

	const onCancel = useCallback(async () => {
		try {
			const cancelAndReload = async (id: string) => {
				await cancelTicket(id);
				return reloadTicket();
			};
			await withLoader(cancelAndReload(ticket.id));
		} catch (error) {
			openGlobalErrorModal(new Error(`Could not cancel ticket: ${error}`));
		}
		closeSidebar();
	}, [closeSidebar, openGlobalErrorModal, reloadTicket, ticket.id, withLoader]);

	const setApproved = useCallback(async () => {
		updateApprovalStatus(true);
	}, [updateApprovalStatus]);

	const setDeclined = useCallback(async () => {
		updateApprovalStatus(false);
	}, [updateApprovalStatus]);

	if (!hasApproverActions && !hasReceiverActions && !hasCancel) return null;

	return (
		<div className={classes.bottomBar}>
			{hasApproverActions && (
				<>
					{isApprover && <TicketExtraOptionsButton position="top-end" ticket={ticket} onClose={closeSidebar} />}
					<Button
						size="medium"
						variant="secondary"
						loading={isLoading}
						disabled={isLoading}
						onClick={setDeclined}
						suffix={<CloseIcon />}>
						{allowAdminApprove ? t("common.ticketActions.adminDeny") : t("common.ticketSidebar.deny")}
					</Button>
					<Button size="medium" loading={isLoading} disabled={isLoading} onClick={setApproved} suffix={<GrantedIcon />}>
						{allowAdminApprove ? t("common.ticketActions.adminApprove") : t("common.ticketSidebar.approve")}
					</Button>
				</>
			)}
			{hasReceiverActions && <TicketRenewalButton ticket={ticket} />}
			{hasCancel && !hasApproverActions ? (
				<Button
					size="medium"
					variant="secondary"
					loading={isLoading}
					disabled={isLoading}
					onClick={onCancel}
					suffix={<CancelCircleIcon />}>
					{t("buttons.cancel")}
				</Button>
			) : null}
		</div>
	);
};
