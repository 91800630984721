import React, { useCallback, useMemo } from "react";
import classNames from "classnames";
import { IconButton } from "components/ui/IconButton";
import { CloseIcon } from "components/ui/Icons/CloseIcon";
import { TooltipOnOverflow } from "components/ui/TooltipOnOverflow";
import { useTranslation } from "react-i18next";
import { RolesTitle } from "../helperComponents";
import { useStyles } from "./styles";
import type { TCartResourceCardProps } from "../../types";
import type { IntegrationResourceRoleModel } from "models/IntegrationResourceRoleModel";

type TRoleRowProps = Omit<TCartResourceCardProps, "resource" | "selectedRoles" | "onRemoveResource"> & {
	role: IntegrationResourceRoleModel;
};

const RoleRow: FC<TRoleRowProps> = ({ onRemoveRole, role }) => {
	const classes = useStyles();

	const removeRole = useCallback(() => onRemoveRole(role.id), [onRemoveRole, role.id]);

	return (
		<>
			<div className={classes.roleRow}>
				<TooltipOnOverflow textVariant="text_reg" content={role.name} />
				<IconButton size="small" onClick={removeRole}>
					<CloseIcon />
				</IconButton>
			</div>
		</>
	);
};

export const CartResourceCardContent: FC<Omit<TCartResourceCardProps, "resource">> = ({
	className,
	innerRef,
	selectedRoles,
	...restProps
}) => {
	const classes = useStyles();
	const { t } = useTranslation();
	const rows = useMemo(() => {
		return selectedRoles.valueSeq().map(role => <RoleRow key={role.id} role={role} {...restProps} />);
	}, [restProps, selectedRoles]);

	return (
		<div className={classNames(classes.container, className)} ref={innerRef}>
			<RolesTitle chipContent={t("number", { value: selectedRoles.size })} />
			<div className={classes.rolesList}>{rows}</div>
		</div>
	);
};
