import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Typography } from "components/ui/Typography";
import { Section } from "components/ui/Section";
import { CloseIcon } from "components/ui/Icons/CloseIcon";
import { IconButton } from "components/ui/IconButton";
import { Divider } from "components/ui/Divider";
import { BasicCard } from "components/ui/BasicCard";
import { Button } from "components/ui/Button";
import { FilterIcon } from "components/ui/Icons/FilterIcon";
import { StaticChip } from "components/ui/chips/StaticChip";
import { FilterSection } from "components/ui/filters/FilterSection";
import { useSessionAuditLogsContext } from "components/pages/AuditLogsPage/sessionAuditLogContext";
import { IconPrefix } from "components/ui/IconPrefix";
import classNames from "classnames";
import { FilterExpressionFactory } from "./components/filterExpressions/FilterExpressionFactory";
import { useStyles } from "./styles";

type TIntegrationLogsFilterProps = {
	closeDrawer?: () => void;
};

export const IntegrationLogsFilters: FC<TIntegrationLogsFilterProps> = ({ className, innerRef, closeDrawer }) => {
	const { t } = useTranslation();
	const classes = useStyles();
	const [selectedFilters, setSelectedFilters] = useState<string[]>([]);

	const {
		state: { filters, totalFilters, dateRange },
		actions: { setSearchFilters, handleDateRangeChange, setTotalFilters }
	} = useSessionAuditLogsContext();

	const filterOptions = useMemo(() => {
		return [
			{
				value: "Date & Time",
				label: t("pages.auditLog.integrationLogs.filter.dateTime")
			},
			{
				value: "action",
				label: t("pages.auditLog.integrationLogs.filter.action")
			},
			{
				value: "subject",
				label: t("pages.auditLog.integrationLogs.filter.subject")
			},
			{
				value: "target",
				label: t("pages.auditLog.integrationLogs.filter.target")
			},
			{
				value: "status",
				label: t("pages.auditLog.integrationLogs.filter.status")
			},
			{
				value: "sessionAuditLogIntegrationId",
				label: t("pages.auditLog.integrationLogs.filter.integration")
			},
			{
				value: "userId",
				label: t("pages.auditLog.integrationLogs.filter.user")
			},
			{
				value: "account",
				label: t("pages.auditLog.integrationLogs.filter.account")
			},
			{
				value: "source",
				label: t("pages.auditLog.integrationLogs.filter.entitleLogs")
			}
		];
	}, [t]);

	const filterTitle = useMemo(() => {
		return (
			<div className={classes.titleContainer}>
				<div className={classes.filterTitleContainer}>
					<div className={classes.filterTitle}>
						<IconPrefix size="large" content={t("pages.auditLog.integrationLogs.filter.title")} Icon={FilterIcon} />
						<StaticChip size="small" variant="regular">
							{t("number", {
								value: totalFilters.length > 0 ? totalFilters.length : 0
							})}
						</StaticChip>
					</div>
					<IconButton onClick={closeDrawer}>
						<CloseIcon />
					</IconButton>
				</div>
				<Divider horizontal />
			</div>
		);
	}, [classes.titleContainer, classes.filterTitleContainer, classes.filterTitle, t, totalFilters.length, closeDrawer]);

	const onRemoveFilter = useCallback((filter: string) => {
		setSelectedFilters(current => current.filter(selectedFilter => selectedFilter !== filter));
	}, []);

	const checkIfFilterIsSelected = useCallback(
		(option: { value: string; label: string }) => {
			return selectedFilters.includes(option.label);
		},
		[selectedFilters]
	);

	const handleOnCardClick = useCallback(
		(option: { value: string; label: string }) => {
			if (!checkIfFilterIsSelected(option)) {
				setSelectedFilters([...selectedFilters, option.label]);
			} else if (!filters.get(option.value)) {
				onRemoveFilter(option.label);
			}
		},
		[checkIfFilterIsSelected, filters, onRemoveFilter, selectedFilters]
	);

	const filterCards = useMemo(() => {
		return (
			<div className={classes.cardsContainer}>
				{filterOptions
					? filterOptions?.map(option => {
							return (
								<BasicCard
									key={option.label}
									selected={checkIfFilterIsSelected(option)}
									size="medium"
									onClick={() => handleOnCardClick(option)}>
									<Typography variant="body_sb">{option.label}</Typography>
								</BasicCard>
							);
						})
					: null}
			</div>
		);
	}, [classes.cardsContainer, filterOptions, checkIfFilterIsSelected, handleOnCardClick]);

	const resetFilters = useCallback(() => {
		if (filters.size !== 0) {
			setSearchFilters(new URLSearchParams());
		}
		if (dateRange?.from !== undefined && dateRange?.to !== undefined) {
			handleDateRangeChange({ from: undefined, to: undefined });
		}
		setTotalFilters([]);
		setSelectedFilters([]);
	}, [dateRange?.from, dateRange?.to, filters.size, handleDateRangeChange, setSearchFilters, setTotalFilters]);

	const filterExpressions = useMemo(() => {
		return selectedFilters.map(filter => (
			<FilterExpressionFactory key={filter} expressionType={filter} onRemove={() => onRemoveFilter(filter)} />
		));
	}, [onRemoveFilter, selectedFilters]);

	return (
		<div className={classes.drawerContent}>
			{filterTitle}
			{filterCards}
			<Divider horizontal />
			<Section
				className={classNames(classes.filterExpressionsContainer, className)}
				title={t("pages.auditLog.integrationLogs.filter.filterExpression")}
				titleActions={
					<Button variant="secondary" size="small" onClick={resetFilters} disabled={selectedFilters.length === 0}>
						{t("pages.auditLog.integrationLogs.filter.clearAll")}
					</Button>
				}
				noDivider
				innerRef={innerRef}>
				{selectedFilters.length > 0 && (
					<FilterSection
						relation="and"
						filterOptions={filterOptions}
						isFilterOptionSelected={checkIfFilterIsSelected}
						onFilterOptionSelected={handleOnCardClick}
						filters={filterExpressions}
					/>
				)}
			</Section>
		</div>
	);
};
