import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { LoadingSpinner } from "components/ui/LoadingSpinner";
import { AddIcon } from "components/ui/Icons/AddIcon";
import { Typography } from "components/ui/legacy/Typography";
import { useApprovalAlgorithms } from "hooks/useApprovalAlgorithms";
import { IntegrationResourceModel } from "models/IntegrationResourceModel";
import { DESC, usePagination } from "hooks/usePagination";
import { LoadingDots } from "components/ui/LoadingDots";
import { CommonAccordion } from "components/common/CommonAccordion";
import { getIntegrationResourceRoles } from "api/integrationResourceRoles";
import { Button } from "components/ui/Button";
import { useIsOpenState } from "hooks/useIsOpenState";
import { Table } from "components/ui/Table";
import { PageSelect } from "components/ui/PageSelect";
import { SortableTableHeader } from "components/common/SortableTableHeader";
import { SortTableProvider, useSortTableContext } from "context/sortingTableContext";
import { getIntegrationResourceRoleFilters } from "utils/api/filters";
import { useStopPropagation } from "hooks/useStopPropagation";
import { useCompany } from "hooks/useCompany";
import { EditIcon } from "components/ui/Icons/EditIcon";
import { RoleIcon } from "components/ui/Icons/RoleIcon";
import { IntegrationResourceRoleIntegrationResourceIdFilter } from "filters/integrationResourceRole";
import { RoleRow } from "./RoleRow";
import { useStyles } from "./styles";
import { CreateRoleFormModal } from "./CreateRoleFormModal";
import type { IPaginatedSearchOptions } from "utils/searchUtils";

interface IProps {
	integrationResource: IntegrationResourceModel;
	onChange?: () => Promise<void>;
	manual: boolean;
	virtual: boolean;
	withUnmanaged?: boolean;
}

const RolesBlockContent: FC<IProps> = ({ integrationResource, onChange, manual, virtual, withUnmanaged = false }) => {
	const {
		state: { sortFields, sortOrder }
	} = useSortTableContext();

	const fetchRoles = useCallback(
		({ sort = {}, pagination = {} }: IPaginatedSearchOptions) => {
			return getIntegrationResourceRoles({
				...pagination,
				...sort,
				withUnmanaged,
				filters: getIntegrationResourceRoleFilters({
					integrationResourceId: [integrationResource.id]
				})
			});
		},
		[integrationResource.id, withUnmanaged]
	);

	const [expanded, setExpanded] = useState(false);
	const { open, isOpen, close } = useIsOpenState();
	const { t } = useTranslation();
	const classes = useStyles();
	const approvalAlgorithms = useApprovalAlgorithms();
	const company = useCompany();
	const navigate = useNavigate();

	const { getPage, totalPages, items, currentPageNumber, page, setCurrentPageNumber, setPartialItem } = usePagination({
		fetchItems: fetchRoles,
		perPage: 10,
		sortOrder,
		sortFields
	});

	useEffect(() => {
		if (currentPageNumber > totalPages) getPage(totalPages > 0 ? totalPages : currentPageNumber);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [totalPages]);

	const currentPageItems = useMemo(() => {
		return page?.toArray() || [];
	}, [page]);

	const changePage = useCallback(
		(pageIndex: number) => {
			setCurrentPageNumber(pageIndex + 1);
		},
		[setCurrentPageNumber]
	);

	const handleOnAdd = useStopPropagation(open);

	const onSave = useCallback(async () => {
		onChange && (await onChange());
	}, [onChange]);

	const handleBulkActionsClick = useCallback(() => {
		const integrationResourceFilter = new IntegrationResourceRoleIntegrationResourceIdFilter({
			value: [integrationResource.id]
		});
		navigate({
			pathname: "/bulkActions",
			search: `?tab=role&${integrationResourceFilter.toURLSearchParams().toString()}`
		});
	}, [integrationResource.id, navigate]);

	if (!(approvalAlgorithms && items)) {
		return <LoadingSpinner className={classes.spinner} />;
	}

	return (
		<CommonAccordion
			title={
				<div className={classes.header}>
					<div className={classes.titlePart}>
						<RoleIcon />
						<Typography variant="h3">{t("pages.integration.resource.roles")}</Typography>
						<Typography variant="small">{`(${integrationResource.rolesCount || 0})`}</Typography>
					</div>
					<div className={classes.titlePart}>
						{company?.enableBulkActions ? (
							<Button
								prefix={<EditIcon size="small" />}
								size="medium"
								variant="secondary"
								onClick={handleBulkActionsClick}>
								{t("pages.integration.resource.bulkActions")}
							</Button>
						) : null}
						{(manual || virtual) && (
							<Button variant="secondary" size="medium" prefix={<AddIcon />} onClick={handleOnAdd}>
								{t("buttons.add")}
							</Button>
						)}
					</div>
				</div>
			}
			expanded={expanded}
			onChange={setExpanded}>
			<CreateRoleFormModal resource={integrationResource} onSubmit={onSave} isOpen={isOpen} onClose={close} />
			{items && items.size && currentPageItems ? (
				<>
					<Table gridColumns="repeat(3, 2fr) 1fr">
						<Table.Row>
							<SortableTableHeader
								className={classes.headerCell}
								title={t("pages.integration.resource.role.name")}
								field="name"
							/>
							<SortableTableHeader
								className={classes.headerCell}
								title={t("pages.integration.resource.role.workflow")}
								field="approvalAlgorithm"
							/>
							<SortableTableHeader
								className={classes.headerCell}
								title={t("pages.integration.resource.role.allowedDurations")}
							/>
							<SortableTableHeader
								className={classes.headerCell}
								title={t("pages.integration.resource.role.requestable")}
							/>
						</Table.Row>
						{currentPageItems.map(role => {
							return (
								<RoleRow
									key={role.id}
									role={role}
									integrationResource={integrationResource}
									onRoleChanged={setPartialItem}
								/>
							);
						})}
					</Table>
					{totalPages > 1 && (
						<PageSelect
							pagesCount={totalPages}
							pagesShownAmount={3}
							currentPageNumber={currentPageNumber}
							changePage={num => changePage(num - 1)}
						/>
					)}
				</>
			) : (
				<LoadingDots />
			)}
		</CommonAccordion>
	);
};

const MemoizedRolesBlockContent = React.memo(RolesBlockContent);

export const RolesBlock: FC<IProps> = props => (
	<SortTableProvider defaultSortField="id" defaultSortOrder={DESC} secondaryField="id">
		<MemoizedRolesBlockContent {...props} />
	</SortTableProvider>
);
