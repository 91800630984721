import React, { useCallback, useMemo } from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { IntegrationResourceRoleModel } from "models/IntegrationResourceRoleModel";
import { Select } from "components/ui/Select";
import { sortByName } from "utils/sortUtils";
import { useGraphRoles } from "components/pages/IdentityGraphPage/graphHooks";
import { useStyles } from "./styles";

type TBaseProps = {
	disabled?: boolean;
	resourceId: string | null;
};

type TIdProps = TBaseProps & {
	onChange: (roleId: string | null) => void;
	value: string | null;
	byId: true;
};

type TValueProps = TBaseProps & {
	onChange: (role: IntegrationResourceRoleModel | null) => void;
	value: IntegrationResourceRoleModel | null;
	byId?: false;
};
type TProps = TIdProps | TValueProps;

const isEqual = (option: IntegrationResourceRoleModel, value: IntegrationResourceRoleModel) => option.id === value.id;
const getLabel = (option: IntegrationResourceRoleModel) => option.name;

export const RolesSelect: FC<TProps> = ({
	className,
	disabled,
	innerRef,
	onChange: propOnChange,
	value: propValue,
	resourceId,
	byId
}) => {
	const { t } = useTranslation();
	const classes = useStyles();
	const { roles, isLoading } = useGraphRoles(resourceId, true);
	const options = useMemo(() => (roles ? roles.valueSeq().toArray() : []), [roles]);

	const renderLabel = useCallback(
		(option: IntegrationResourceRoleModel) => <div className={classes.text}>{option.name}</div>,
		[classes]
	);

	const onChange = useCallback(
		(value: IntegrationResourceRoleModel | null) => {
			if (byId) {
				propOnChange(value?.id ?? null);
			} else {
				propOnChange(value);
			}
		},
		[byId, propOnChange]
	);

	const value = useMemo(() => {
		if (byId) {
			return roles?.get(propValue || "") ?? null;
		} else {
			return propValue;
		}
	}, [byId, roles, propValue]);

	return (
		<Select
			className={classNames(classes.container, className)}
			disabled={disabled}
			getOptionLabel={getLabel}
			innerRef={innerRef}
			label={t("common.roleSelectInput.label")}
			isOptionEqualToValue={isEqual}
			limit={20}
			loading={isLoading}
			onChange={onChange}
			options={options}
			placeholder={t("common.roleSelectInput.placeholder")}
			renderLabel={renderLabel}
			sort={sortByName}
			value={value}
			optionType="role"
		/>
	);
};
