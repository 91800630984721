import React, { useCallback } from "react";
import classNames from "classnames";
import { IconPrefix } from "components/ui/IconPrefix";
import { useControlled } from "hooks/useControlled";
import { useStyles } from "./styles";

export interface IButtonOptions<ButtonId extends string> {
	id: ButtonId;
	header: string;
	Icon?: IconComponent;
}

interface IButtonsBaseProps<ButtonId extends string> {
	buttons: IButtonOptions<ButtonId>[];
	disabled?: boolean;
	headerClassName?: string;
	contentClassName?: string;
	size: "medium" | "large";
}

interface TButtonsProps<ButtonId extends string> extends IButtonsBaseProps<ButtonId> {
	defaultActiveButton?: ButtonId;
	activeButton: ButtonId | null;
	onActivateButton: (id: ButtonId) => void;
}

type TButtonContainerProps<ButtonId extends string> = {
	onActivateButton: (id: ButtonId) => void;
	id: ButtonId;
	header: string;
	Icon?: IconComponent;
	activeButton: ButtonId;
	disabled?: boolean;
	size: "medium" | "large";
};

const ButtonContainer = <ButtonId extends string = string>({
	onActivateButton,
	id,
	header,
	Icon,
	activeButton,
	disabled = false,
	size
}: TProps<TButtonContainerProps<ButtonId>>) => {
	const classes = useStyles();
	const isSelected = activeButton === id;

	const activateButton = useCallback(() => {
		if (!disabled) onActivateButton(id);
	}, [disabled, id, onActivateButton]);

	return (
		<IconPrefix
			Icon={Icon}
			content={header}
			onClick={activateButton}
			size="small"
			semibold={isSelected}
			className={classNames(classes.button, {
				[classes.selected]: isSelected,
				[classes.disabled]: disabled,
				[classes.large]: size === "large",
				[classes.medium]: size === "medium"
			})}
		/>
	);
};

export const ButtonGroup = <ButtonId extends string = string>({
	buttons,
	disabled = false,
	className,
	activeButton: propActiveButton,
	defaultActiveButton,
	onActivateButton,
	size
}: TProps<TButtonsProps<ButtonId>>) => {
	const classes = useStyles();
	const [activeButtonId, setActiveButtonId] = useControlled<ButtonId>({
		controlled: propActiveButton,
		default: defaultActiveButton
	});

	const activateButton = useCallback(
		(id: ButtonId) => {
			if (!disabled) {
				setActiveButtonId(id);
				onActivateButton?.(id);
			}
		},
		[disabled, onActivateButton, setActiveButtonId]
	);

	return (
		<div className={classNames(classes.buttonsContainer, className)}>
			{buttons.map(button => (
				<ButtonContainer
					size={size}
					key={button.id}
					id={button.id}
					Icon={button.Icon}
					activeButton={activeButtonId || buttons[0].id}
					header={button.header}
					onActivateButton={activateButton}
					disabled={disabled}
				/>
			))}
		</div>
	);
};
