import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { FilterExpression } from "components/ui/filters/FilterExpression";
import { IntegrationResourceTypeFilter } from "filters/integrationResource";
import { IntegrationResourceRoleIntegrationResourceTypeFilter } from "filters/integrationResourceRole";
import { notEmpty } from "utils/comparison";
import { getIntegrationResourceTypes } from "api/integrationResources";
import { useFetchedState } from "hooks/useFetchedState";
import { TagChip } from "components/ui/chips/TagChip";
import { FilterExpressionEmptyState } from "components/common/FilterExpressionEmptyState";
import { useFilterFormExpression } from "./filter.hooks";
import type { Constructor } from "types/utilTypes";
import type { TFilterOperator } from "types/filters";

type TTypesFilters = IntegrationResourceTypeFilter | IntegrationResourceRoleIntegrationResourceTypeFilter;

type TTypesFilterProps = {
	filter: TTypesFilters;
	onChange: (filter: TTypesFilters | undefined, isValid: boolean) => void;
};

function getFilter(filterName: TTypesFilters["name"]): Constructor<TTypesFilters> {
	switch (filterName) {
		case IntegrationResourceTypeFilter.filterName:
			return IntegrationResourceTypeFilter;
		default:
			return IntegrationResourceRoleIntegrationResourceTypeFilter;
	}
}

const isValidOperator = (operator: TFilterOperator): operator is TTypesFilters["operator"] =>
	operator === "is" || operator === "isNot";

const OPERATORS = ["is", "isNot"] as TFilterOperator[];

export const ResourceTypeFilterExpression: FC<TTypesFilterProps> = ({ className, innerRef, filter, onChange }) => {
	const { t } = useTranslation("translation", { keyPrefix: "pages.bulkActions.filters" });
	const [searchQuery, setSearchQuery] = useState<string>();

	const fetchTypes = useCallback(async () => {
		return getIntegrationResourceTypes(searchQuery);
	}, [searchQuery]);

	const { data: types, isLoading, loadData } = useFetchedState(fetchTypes);

	useEffect(() => {
		loadData();
	}, [loadData]);

	const options = useMemo(() => {
		return types || [];
	}, [types]);

	const selectedResources = useMemo(() => {
		if (!filter) return [];
		return filter.value.filter(notEmpty);
	}, [filter]);

	const { clearFilter, removeFilter } = useFilterFormExpression<TTypesFilters>({
		filterName: filter.name,
		onChange,
		getFilter
	});

	const onOperatorSelect = useCallback(
		(operator: TFilterOperator) => {
			if (!filter) return;
			if (!isValidOperator(operator)) return;
			onChange(filter.set("operator", operator), filter.value.length > 0);
		},
		[filter, onChange]
	);

	const onOptionSelect = useCallback(
		(option: string) => {
			if (!filter || !option) return;
			const currentValue = filter.value;
			const newValue = currentValue.includes(option)
				? currentValue.filter(current => current !== option)
				: [...currentValue, option];
			onChange(filter.set("value", newValue), newValue.length > 0);
		},
		[filter, onChange]
	);

	const renderSelected = useCallback(
		(option: string) => (
			<TagChip size="large" selected onDelete={() => onOptionSelect(option)}>
				{option}
			</TagChip>
		),
		[onOptionSelect]
	);

	return (
		<FilterExpression
			className={className}
			emptyState={<FilterExpressionEmptyState text={t("values.emptyResourceTypes")} />}
			filter={null}
			getMoreOptions={setSearchQuery}
			innerRef={innerRef}
			inputPlaceholder={t("placeholders.resourceType")}
			isLoading={isLoading}
			onOperatorSelect={onOperatorSelect}
			onOptionSelect={onOptionSelect}
			onRemoveFilter={removeFilter}
			onReset={clearFilter}
			operators={OPERATORS}
			options={options}
			optionType="text"
			renderSelected={renderSelected}
			selected={selectedResources}
			selectedOperator={filter.operator}
			title={t(`title.${filter.name}`)}
			type="multiSelect"
		/>
	);
};
