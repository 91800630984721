import React, { useState, useCallback, useEffect, useMemo } from "react";
import classNames from "classnames";
import { Description, Errors, FieldTitle } from "../fieldHelpers";
import { useStyles } from "./styles";
import type { IInputState } from "../fieldHelpers/types";

export interface ITextAreaProps {
	actions?: JSX.Element;
	autoCapitalize?: string;
	autoCorrect?: string;
	autoFocus?: boolean;
	autoResize?: boolean;
	description?: string;
	disabled?: boolean;
	errors?: string[];
	inputClassName?: string;
	inputRef?: React.Ref<HTMLTextAreaElement>;
	isRequired?: boolean;
	label?: string;
	labelIcon?: React.ReactNode | IconComponent;
	labelInfo?: React.ReactNode;
	maxLength?: number;
	minHeight?: number;
	onBlur?: (event: React.FocusEvent<HTMLTextAreaElement>) => void;
	onChange?: (event: React.ChangeEvent<HTMLTextAreaElement>) => React.ChangeEvent<HTMLInputElement>;
	onClick?: (event: React.MouseEvent<HTMLTextAreaElement>) => void;
	onError?: (errors: string[] | null) => void;
	onFocus?: (event: React.FocusEvent<HTMLTextAreaElement>) => void;
	onKeyDown?: (event: React.KeyboardEvent<HTMLTextAreaElement>) => void;
	onKeyUp?: (event: React.KeyboardEvent<HTMLTextAreaElement>) => void;
	onStateChange?: (state: IInputState) => void;
	onValueChange?: (value: string) => void;
	placeholder?: string;
	privateField?: boolean;
	readonly?: boolean;
	spellCheck?: boolean;
	textAreaClassName?: string;
	validators?: ((value: string) => string | null)[];
	value?: string;
}

export const TextAreaInput: FC<ITextAreaProps> = ({
	actions = undefined,
	autoCapitalize = "false",
	autoCorrect = "false",
	autoFocus = false,
	autoResize = false,
	className,
	description,
	disabled,
	errors = null,
	innerRef,
	inputClassName,
	inputRef: propInputRef,
	isRequired = false,
	label,
	labelIcon,
	labelInfo,
	maxLength,
	minHeight,
	onBlur: userOnBlur,
	onChange: userOnChange,
	onClick: userOnClick,
	onError: userOnError,
	onFocus: userOnFocus,
	onKeyDown: userOnKeyDown,
	onKeyUp: userOnKeyUp,
	onStateChange: userOnStateChange,
	onValueChange: userOnValueChange,
	placeholder,
	privateField = false,
	readonly: readOnly = false,
	spellCheck = false,
	textAreaClassName,
	validators = null,
	value: userValue
}) => {
	const classes = useStyles();
	const [textareaValue, setTextareaValue] = useState<string>(userValue || "");
	const [isDirty, setIsDirty] = useState(false);
	const [isTouched, setIsTouched] = useState(false);
	const [isFocused, setIsFocused] = useState(false);
	const [isError, setIsError] = useState(errors ? errors.length > 0 && errors.every(Boolean) : undefined);
	const [errorMessages, setErrorMessages] = useState(errors);
	const textAreaRef = React.useRef<HTMLTextAreaElement>(null);
	const inputRef: React.RefObject<HTMLTextAreaElement> = useMemo(
		() => (propInputRef as React.RefObject<HTMLTextAreaElement>) || textAreaRef,
		[propInputRef, textAreaRef]
	);

	useEffect(() => {
		setIsError(errors ? errors.length > 0 && errors.every(Boolean) : undefined);
		setErrorMessages(errors);
	}, [errors]);

	const handleStateChange = useCallback(() => {
		if (userOnStateChange) {
			userOnStateChange({
				dirty: isDirty,
				focused: isFocused,
				touched: isTouched
			});
		}
	}, [isDirty, isTouched, isFocused, userOnStateChange]);

	useEffect(() => handleStateChange, [handleStateChange]);

	const validate = useCallback(
		(value: string) => {
			const validationErrors = [
				...(validators?.map(validator => validator(value)).filter((err): err is string => typeof err === "string") ||
					[]),
				...(errors || [])
			];
			return validationErrors?.length ? validationErrors : null;
		},
		[errors, validators]
	);

	const onFocus = useCallback(
		(event: React.FocusEvent<HTMLTextAreaElement>) => {
			setIsFocused(true);
			setIsTouched(true);
			if (userOnFocus) userOnFocus(event);
		},
		[userOnFocus]
	);

	const onBlur = useCallback(
		(event: React.FocusEvent<HTMLTextAreaElement>) => {
			setIsFocused(false);
			if (userOnBlur) userOnBlur(event);
		},
		[userOnBlur]
	);

	const onChange = useCallback(
		(event: React.ChangeEvent<HTMLTextAreaElement>) => {
			const newValue =
				maxLength !== undefined && event.target.value.length > maxLength
					? event.target.value.substring(0, maxLength)
					: event.target.value;
			const errors = validate(newValue);
			setIsDirty(true);
			if (errors) {
				setIsError(true);
				setErrorMessages(errors);
				userOnError && userOnError(errors);
			} else {
				setIsError(false);
				setErrorMessages(null);
				userOnError && userOnError(null);
			}
			setTextareaValue(newValue);
			if (userOnValueChange) {
				userOnValueChange(newValue);
			}
			if (userOnChange) {
				userOnChange(event);
			}
		},
		[userOnChange, userOnError, validate, userOnValueChange, maxLength]
	);

	useEffect(() => {
		setTextareaValue(userValue || "");
	}, [userValue]);

	useEffect(() => {
		if (autoResize) {
			if (inputRef.current) {
				inputRef.current.style.height = `${inputRef.current.scrollHeight}px`;
			}
		}
	}, [inputRef, autoResize, textareaValue]);

	const title = useMemo(() => {
		if (!label) return null;
		if (typeof label === "string") {
			return <FieldTitle title={label} icon={labelIcon} info={labelInfo} required={isRequired} />;
		}
		return label;
	}, [label, labelIcon, labelInfo, isRequired]);

	return (
		<div className={classNames(classes.container, className)} ref={innerRef as React.Ref<HTMLDivElement>}>
			{title || description ? (
				<div className={classNames(classes.top, { [classes.disabled]: disabled })}>
					{title}
					<Description description={description} />
				</div>
			) : null}
			<div
				className={classNames(classes.textAreaContainer, textAreaClassName, {
					[classes.error]: isError,
					[classes.focused]: isFocused,
					[classes.disabled]: disabled,
					[classes.autoResize]: autoResize
				})}>
				<textarea
					autoCapitalize={autoCapitalize}
					autoCorrect={autoCorrect}
					autoFocus={autoFocus}
					className={classNames(classes.textAreaHTMLComponent, inputClassName, { [classes.disabled]: disabled })}
					data-private={privateField}
					disabled={disabled}
					onBlur={onBlur}
					onChange={onChange}
					onClick={userOnClick}
					onFocus={onFocus}
					onKeyDown={userOnKeyDown}
					onKeyUp={userOnKeyUp}
					placeholder={placeholder}
					readOnly={readOnly}
					ref={inputRef}
					required={isRequired}
					spellCheck={spellCheck}
					value={textareaValue}
					style={{
						minHeight: typeof minHeight === "number" ? `${minHeight}px` : undefined
					}}
				/>
				<div className={classes.actions}>{actions}</div>
			</div>
			<Errors errorMessages={errorMessages} />
		</div>
	);
};
