import React, { useMemo } from "react";
import { BundleModel } from "models/BundleModel";
import { BundleIcon } from "components/ui/Icons/BundleIcon";
import { Tooltip } from "components/ui/Tooltip";
import { TitleBody } from "components/ui/TitleBody";
import { useTranslation } from "react-i18next";
import { Typography } from "components/ui/Typography";
import { Card, TCardProps } from "../Card";
import { ResourceAndRoleCountCardBody, TResourceAndRoleCountCardBodyProps } from "../ResourceAndRoleCountCardBody";
import { useStyles } from "./styles";

type TBundleCardProps = Omit<TCardProps, "header" | "content"> &
	TResourceAndRoleCountCardBodyProps & {
		bundle: BundleModel;
	};

export const BundleCard: FC<TBundleCardProps> = props => {
	const { bundle, resourcesCount, resourcesLabel, rolesCount, rolesLabel, size, ...cardProps } = props;
	const { t } = useTranslation();
	const classes = useStyles();

	const isLarge = size === "large";

	const headerContent = useMemo(() => {
		if (bundle) {
			return (
				<>
					<BundleIcon size={isLarge ? 40 : 28} />
					<Tooltip
						content={
							<div className={classes.tooltipContainer}>
								<TitleBody size="small" title={t("common.roleBar.roleBarBundle.bundleName")} body={bundle.name} />
								{bundle.description ? (
									<TitleBody
										size="small"
										title={t("common.roleBar.roleBarBundle.bundleDescription")}
										body={bundle.description}
									/>
								) : null}
							</div>
						}>
						<Typography noWrap variant={isLarge ? "title_sb" : "body_sb"}>
							{bundle.name}
						</Typography>
					</Tooltip>
				</>
			);
		}
		return null;
	}, [bundle, classes.tooltipContainer, isLarge, t]);

	return (
		<Card
			size={size}
			content={
				<ResourceAndRoleCountCardBody
					resourcesCount={resourcesCount}
					resourcesLabel={resourcesLabel}
					rolesCount={rolesCount}
					rolesLabel={rolesLabel}
					size={size}
				/>
			}
			header={headerContent}
			{...cardProps}
		/>
	);
};
