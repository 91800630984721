import React, { useCallback, useMemo } from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { useStyles } from "./styles";
import { ResourceCardHeader, RolesTitle } from "./components/helperComponents";
import { ExpandedResourceCardContent } from "./components/ExpandedResourceCardContent";
import { CartResourceCardContent } from "./components/CartResourceCardContent";
import type {
	TExpandedResourceCardProps,
	TCartResourceCardProps,
	TRegularResourceCardProps,
	TResourceCardProps
} from "./types";

const isExpandedProps = (props: TResourceCardProps): props is TExpandedResourceCardProps => {
	return "expanded" in props && !!props.expanded;
};

const isCartProps = (props: TResourceCardProps): props is TCartResourceCardProps => {
	return "onRemoveResource" in props;
};

const isRegularProps = (props: TResourceCardProps): props is TRegularResourceCardProps => {
	return !isExpandedProps(props) && !isCartProps(props);
};

const RegularResourceCardContent: FC<TRegularResourceCardProps> = ({ totalRoles, selectedRoleIds }) => {
	const { t } = useTranslation();
	const selectedAmount = useMemo(() => selectedRoleIds?.size || 0, [selectedRoleIds]);
	const total = useMemo(() => totalRoles || 0, [totalRoles]);
	const chipContent = useMemo(
		() =>
			selectedAmount
				? `${t("number", { value: selectedAmount })}/${t("number", { value: total })}`
				: t("number", { value: total }),
		[selectedAmount, total, t]
	);
	return <RolesTitle chipContent={chipContent} />;
};

export const ResourceCard: FC<TResourceCardProps> = props => {
	const classes = useStyles();
	const { className, innerRef, resource } = props;

	const isExpanded = isExpandedProps(props);
	const isCart = isCartProps(props);
	const isRegular = isRegularProps(props);
	// isExpanded also relies on the styles of isSelected
	const isSelected = (isExpanded || isRegular) && (props.selectedRoleIds?.size || 0) > 0;

	const onClick = useCallback(
		(event: React.MouseEvent) => {
			event.stopPropagation();
			if (isCart) return;
			return props.onClick();
		},
		[isCart, props]
	);

	const bodyComponent = useMemo(() => {
		if (isRegular) return <RegularResourceCardContent {...props} />;
		if (isExpanded) return <ExpandedResourceCardContent {...props} />;
		if (isCart) return <CartResourceCardContent {...props} />;
		return null;
	}, [isCart, isExpanded, isRegular, props]);

	return (
		<div
			className={classNames(
				classes.container,
				{
					[classes.expanded]: isExpanded,
					[classes.cart]: isCart,
					[classes.regular]: isRegular,
					[classes.selected]: isSelected,
					[classes.clickable]: isRegular && props.onClick
				},
				className
			)}
			ref={innerRef}
			onClick={isRegular ? onClick : undefined}>
			<ResourceCardHeader
				resource={resource}
				onRemoveResource={(props as TCartResourceCardProps)?.onRemoveResource}
				onClick={onClick}
			/>
			<div className={classes.body}>{bodyComponent}</div>
		</div>
	);
};
