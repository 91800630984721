import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { MultipleSelect, type TTargetValue } from "components/ui/MultipleSelect";
import { GroupChip } from "components/ui/chips/GroupChip";
import { UserChip } from "components/ui/chips/UserChip";
import { DirectoryGroupModel } from "models/DirectoryGroupModel";
import { useCustomerEntitiesSelect } from "hooks/useCustomerEntitiesSelect";
import type { UserModel } from "models/UserModel";
import type { TMaintainerOption } from "../../../../utils";
import type { TDirectoryGroupOption, TUserOption } from "utils/ui/selectUtils";

type TProps = {
	value: TMaintainerOption[] | null;
	onChange: (value: TMaintainerOption[] | null) => void;
	withDeleted: boolean;
};

const isUserModel = (option: TMaintainerOption): option is UserModel => "role" in option;

const isGroup = (option: TMaintainerOption): option is DirectoryGroupModel => option instanceof DirectoryGroupModel;

const getLabel = (option: TMaintainerOption) => (isUserModel(option) ? option.fullName : option.name);

const cleanLabel = (label: string) => label.toLowerCase().trim();

export const MaintainersSelect: FC<TProps> = ({ className, innerRef, value, onChange, withDeleted = false }) => {
	const { t } = useTranslation("translation", { keyPrefix: "pages.bulkActions.bulkActions.inputs.toThis" });

	const [query, setQuery] = useState("");
	const directoryGroupIds = useMemo(() => value?.filter(isGroup).map(({ id }) => id) || [], [value]);
	const userIds = useMemo(() => value?.filter(isUserModel).map(({ id }) => id) || [], [value]);
	const { items, isLoading: isLoadingEntities } = useCustomerEntitiesSelect(query, {
		entities: ["directoryGroup", "user"],
		selectedIdsByType: { directoryGroup: directoryGroupIds, user: userIds }
	});

	const onInputChange = useCallback((event: TTargetValue | React.ChangeEvent<HTMLInputElement>) => {
		setQuery(event.target.value);
	}, []);

	const maintainerOptions = useMemo(() => {
		if (isLoadingEntities) return [];
		const labels = new Map<string, string>();
		const entities: TMaintainerOption[] = [];
		(items.toArray() as TMaintainerOption[]).forEach(entity => {
			if (withDeleted || !entity.isDeleted) {
				labels.set(entity.id, cleanLabel(getLabel(entity)));
				entities.push(entity);
			}
		});
		return entities.sort((a, b) => labels.get(a.id)!.localeCompare(labels.get(b.id)!));
	}, [isLoadingEntities, items, withDeleted]);

	const getOptionType = useCallback((option: TMaintainerOption): TUserOption | TDirectoryGroupOption => {
		return isUserModel(option) ? { type: "userWithEmail", option } : { type: "directoryGroup", option };
	}, []);

	const removeValue = useCallback(
		(option: TMaintainerOption) => {
			onChange(value?.filter(val => val.id !== option.id) || null);
		},
		[onChange, value]
	);

	const renderChip = useCallback(
		({ option }: { option: TMaintainerOption }) => {
			const onRemove = () => removeValue(option);
			return isUserModel(option) ? (
				<UserChip size="medium" selected value={option} onDelete={onRemove} />
			) : (
				<GroupChip size="medium" selected value={option} onDelete={onRemove} />
			);
		},
		[removeValue]
	);

	return (
		<MultipleSelect
			className={className}
			innerRef={innerRef}
			value={value}
			onChange={onChange}
			onInputChange={onInputChange}
			loading={isLoadingEntities}
			inputValue={query}
			options={maintainerOptions}
			label={t("value")}
			placeholder={t("placeholder", { context: "maintainer" })}
			renderChip={renderChip}
			getOptionLabel={getLabel}
			optionType={getOptionType}
		/>
	);
};
