import React, { useMemo } from "react";
import { Typography } from "components/ui/legacy/Typography";
import { Trans, useTranslation } from "react-i18next";
import classNames from "classnames";
import { InfoIcon } from "components/ui/Icons/InfoIcon";
import { Separator } from "components/ui/Separator";
import { UserModel } from "models/UserModel";
import { Chips } from "components/ui/MultipleSelect/components/Chips";
import { getNameValidators } from "utils/validation/validationUtils";
import { UserTooltip } from "components/common/UserTooltip";
import { Tooltip } from "components/ui/Tooltip";
import { LogoAvatar } from "components/common/LogoAvatar";
import { Entity } from "components/common/Entity";
import { EditableInput } from "components/common/EditableInput";
import { useIntegrations } from "hooks/useIntegrations";
import { useUser } from "hooks/useUser";
import { useStyles } from "./styles";

interface IProps {
	applicationName?: string;
	childrenCount?: number;
	description?: string | null;
	// imageTimestamp is used to force re-rendering of the image when it is changed, as the imageUrl is the same
	imageTimestamp?: number;
	imageUrl?: string | null;
	inheritOwner?: boolean;
	integrationId?: string;
	lastSync?: Date | null;
	name: string;
	onRename?: (value: string) => Promise<void>;
	ownerId?: string | null;
	requestsDisabled?: boolean;
	resourceType: "integration" | "resource" | "role";
	showOwner?: boolean;
	tags?: string[] | null;
	type?: string | null;
}

const UserWithTooltip: FC<{ user: UserModel; inherit?: boolean }> = ({ user, inherit = false }) => {
	const { t } = useTranslation();
	const classes = useStyles();
	return (
		<UserTooltip user={user}>
			<div className={classes.ownerContainer}>
				{inherit && (
					<Typography className={classes.inheritOwner} variant="tiny">
						{t("shared.integrationDefault")}
					</Typography>
				)}
				<Entity content={user.fullName} isDeleted={user.isDeleted} />
			</div>
		</UserTooltip>
	);
};

export const ResourceHeader: FC<IProps> = ({
	applicationName,
	childrenCount = null,
	description,
	imageTimestamp,
	imageUrl,
	inheritOwner = false,
	integrationId,
	lastSync,
	name,
	onRename,
	ownerId,
	requestsDisabled,
	resourceType,
	showOwner,
	tags,
	type
}) => {
	const classes = useStyles();
	const { t } = useTranslation();
	const integrations = useIntegrations();
	const integration = integrations?.get(integrationId || "");

	const userId = useMemo(() => ownerId || integration?.ownerId, [integration, ownerId]);
	const owner = useUser(userId);

	const validators = useMemo(
		() => getNameValidators(`${t(`shared.resourceTypes.${resourceType}`)} ${t("shared.name")}`),
		[resourceType, t]
	);

	const ownerComponents = useMemo(() => {
		if (!showOwner || !owner) return null;
		return { user: <UserWithTooltip user={owner} inherit={inheritOwner} /> };
	}, [inheritOwner, owner, showOwner]);

	return (
		<div>
			<div className={classes.container}>
				<div className={classes.displayName}>
					<div className={classes.name}>
						{imageUrl && (
							<LogoAvatar key={imageTimestamp} size="large">
								<img src={imageUrl} />
							</LogoAvatar>
						)}
						<EditableInput
							value={name}
							resourceType={resourceType}
							onEdit={onRename}
							inputType="name"
							validators={validators}
						/>
					</div>
					{childrenCount !== null && (
						<Typography className={classes.lighterText}>({t("number", { value: childrenCount })})</Typography>
					)}
				</div>
				{applicationName && (
					<>
						<Separator />
						<Typography className={classes.lighterText}>
							{t("common.resourceHeader.applicationName", { applicationName })}
						</Typography>
					</>
				)}
				{ownerComponents ? (
					<>
						<Separator />
						<div className={classNames(classes.lighterText, classes.entityContainer)}>
							<Trans t={t} i18nKey="common.resourceHeader.ownerName" components={ownerComponents} />
						</div>
					</>
				) : null}
				{lastSync && (
					<>
						<Separator />
						<Typography className={classes.lighterText}>
							{t("common.resourceHeader.lastSync", { date: lastSync })}
						</Typography>
					</>
				)}
				{type && (
					<>
						<Separator />
						<div className={classes.typeContainer}>
							<Typography className={classes.type}>{t("common.resourceHeader.type")}</Typography>
							<Typography>{type}</Typography>
						</div>
					</>
				)}
				{requestsDisabled && (
					<Typography variant="tiny" className={classes.hiddenResource}>
						({t("pages.integration.requestsDisabled")}
						<Tooltip
							content={
								<Trans t={t} values={{ resourceType }} i18nKey="pages.integration.requestsDisabledDescription" />
							}>
							<InfoIcon className={classes.infoIcon} size="medium" />
						</Tooltip>
						)
					</Typography>
				)}
			</div>
			{description && (
				<div className={classes.descriptionContainer}>
					<Typography variant="h3">{description}</Typography>
				</div>
			)}
			{!!tags?.length && (
				<div className={classes.tagsContainer}>
					<Chips values={tags} limit={8} getOptionLabel={opt => opt} type="tag" size="small" />
				</div>
			)}
		</div>
	);
};
