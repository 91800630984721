import React, { useMemo } from "react";
import { IntegrationResourceModel } from "models/IntegrationResourceModel";
import { ResourcesIcon } from "components/ui/Icons/ResourcesIcon";
import { getDynamicSizeIcon } from "components/ui/dynamicSizeIcon";
import { TooltipOnOverflow } from "components/ui/TooltipOnOverflow";
import { SelectOption } from "../SelectOption/SelectOption";

export interface IResourceOptionProps {
	disabled?: boolean;
	onSelect: (event: React.SyntheticEvent, value: IntegrationResourceModel) => void;
	selected?: boolean;
	resource: IntegrationResourceModel;
}

export const ResourceOption: FC<IResourceOptionProps> = ({
	disabled = false,
	onSelect,
	resource,
	selected = false
}) => {
	const Icon = useMemo(() => {
		return resource.integration?.imageUrl
			? getDynamicSizeIcon(<img src={resource.integration?.imageUrl} />)
			: ResourcesIcon;
	}, [resource.integration?.imageUrl]);

	return (
		<SelectOption onSelect={onSelect} selected={selected} disabled={disabled} value={resource} PrefixIcon={Icon}>
			<TooltipOnOverflow textVariant="body_reg" content={resource.name} />
		</SelectOption>
	);
};
