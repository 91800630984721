import React from "react";
import { TooltipOnOverflow } from "components/ui/TooltipOnOverflow";
import { IntegrationActorModel } from "models/IntegrationActorModel";
import { AccountIcon } from "components/ui/Icons/AccountIcon";
import { SelectOption } from "../SelectOption/SelectOption";

export interface IAccountOptionProps {
	disabled?: boolean;
	onSelect: (event: React.SyntheticEvent, value: IntegrationActorModel) => void;
	selected?: boolean;
	account: IntegrationActorModel;
}

export const AccountOption: FC<IAccountOptionProps> = ({ disabled = false, onSelect, account, selected = false }) => {
	return (
		<SelectOption onSelect={onSelect} selected={selected} disabled={disabled} value={account} PrefixIcon={AccountIcon}>
			<TooltipOnOverflow textVariant="body_reg" content={account.displayName} />
		</SelectOption>
	);
};
