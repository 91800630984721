import React, { useCallback, useEffect } from "react";
import classNames from "classnames";
import { PageTemplate } from "components/templates/PageTemplate";
import { useTranslation } from "react-i18next";
import { useTasks } from "hooks/useTasks";
import { RequestDetails } from "components/common/RequestDetails";
import { IconButton } from "components/ui/IconButton";
import { ArrowLeftIcon } from "components/ui/Icons/ArrowLeftIcon";
import { useNavigate } from "react-router-dom";
import { useAuthenticatedUser } from "hooks/useAuthenticatedUser";
import { useStyles } from "./styles";
import { TasksStatusColumns } from "./components/TaskStatusColumns";

const INTERVAL_MILLISECONDS = 1000 * 3 * 60;

export const TasksPage: FC = ({ className }) => {
	const { t } = useTranslation();
	const { reloadTasks } = useTasks();
	const classes = useStyles();
	const { user } = useAuthenticatedUser();

	const navigate = useNavigate();
	const handleBack = useCallback(() => navigate(-1), [navigate]);

	useEffect(() => {
		const intervalId = setInterval(reloadTasks, INTERVAL_MILLISECONDS);
		return () => clearInterval(intervalId);
	}, [reloadTasks]);

	return (
		<PageTemplate className={classNames(classes.tasksPage, className)}>
			<RequestDetails />
			<PageTemplate.Title className={classes.titleContainer}>
				{!user?.isAdmin && (
					<div className={classes.backButtonContainer}>
						<IconButton size="large" onClick={handleBack}>
							<ArrowLeftIcon />
						</IconButton>
					</div>
				)}
				<span>{t("pages.tasks.title")}</span>
			</PageTemplate.Title>
			<PageTemplate.Content className={classes.pageContent}>
				<TasksStatusColumns />
			</PageTemplate.Content>
		</PageTemplate>
	);
};
