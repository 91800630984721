import React from "react";
import { RoleIcon } from "components/ui/Icons/RoleIcon";
import { IntegrationResourceRoleModel } from "models/IntegrationResourceRoleModel";
import { TooltipOnOverflow } from "components/ui/TooltipOnOverflow";
import { NoEditIcon } from "components/ui/Icons/NoEditIcon";
import { SelectOption } from "../SelectOption/SelectOption";

export interface IRoleOptionProps {
	disabled?: boolean;
	onSelect: (event: React.SyntheticEvent, value: IntegrationResourceRoleModel) => void;
	selected?: boolean;
	role: IntegrationResourceRoleModel;
}

export const RoleOption: FC<IRoleOptionProps> = ({ disabled = false, onSelect, role, selected = false }) => {
	return (
		<SelectOption
			onSelect={onSelect}
			selected={selected}
			disabled={disabled}
			value={role}
			PrefixIcon={!role.managed ? NoEditIcon : RoleIcon}>
			<TooltipOnOverflow textVariant="body_reg" content={role.name} />
		</SelectOption>
	);
};
