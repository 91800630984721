import { UserModel } from "models/UserModel";
import { TicketModel } from "models/TicketModel";
import { getPaginatedSearchQueryString, IPaginatedSearchOptions } from "utils/searchUtils";
import { IPaginationResponse, withPagination } from "utils/pagination";
import { IntegrationModel } from "models/IntegrationModel";
import { Map } from "immutable";
import { apiReq } from "../utils/api/apiReq";
import { TSearchTicketsParams } from "./tickets";

export async function getUser() {
	const { data } = await apiReq("GET", "/v1/user");
	return UserModel.fromServerData(data);
}

export async function getUserTickets(
	paginationOptions?: IPaginatedSearchOptions,
	searchParams?: TSearchTicketsParams
): Promise<IPaginationResponse<TicketModel>> {
	const paginationQs = getPaginatedSearchQueryString(paginationOptions);
	return withPagination(
		ticket => TicketModel.fromServerData(ticket),
		() => apiReq("POST", "/v1/user/tickets/myPending?" + paginationQs, searchParams || {})
	);
}

export async function getUserPendingTickets(
	paginationOptions?: IPaginatedSearchOptions
): Promise<IPaginationResponse<TicketModel>> {
	const paginationQs = getPaginatedSearchQueryString(paginationOptions);
	return withPagination(
		ticket => TicketModel.fromServerData(ticket),
		() => apiReq("GET", "/v1/user/tickets/pendingApproval?" + paginationQs)
	);
}

export async function getUserRespondedTickets(
	paginationOptions?: IPaginatedSearchOptions,
	searchParams?: TSearchTicketsParams
): Promise<IPaginationResponse<TicketModel>> {
	const paginationQs = getPaginatedSearchQueryString(paginationOptions);
	const qs = paginationQs ? paginationQs + "&includeRespondedTo=true" : "includeRespondedTo=true";
	return withPagination(
		ticket => TicketModel.fromServerData(ticket),
		() => apiReq("POST", "/v1/user/tickets/past?" + qs, searchParams || {})
	);
}

export async function getUserPastTickets(
	paginationOptions?: IPaginatedSearchOptions,
	searchParams?: TSearchTicketsParams
): Promise<IPaginationResponse<TicketModel>> {
	const paginationQs = getPaginatedSearchQueryString(paginationOptions);
	const qs = paginationQs ? paginationQs + "&includeOwnRequests=true" : "includeOwnRequests=true";

	return withPagination(
		ticket => TicketModel.fromServerData(ticket),
		() => apiReq("POST", "/v1/user/tickets/past?" + qs, searchParams || {})
	);
}

export async function getUserAllPastTickets(
	paginationOptions?: IPaginatedSearchOptions,
	searchParams?: TSearchTicketsParams
): Promise<IPaginationResponse<TicketModel>> {
	const paginationQs = getPaginatedSearchQueryString(paginationOptions);
	const filterQs = "includeOwnRequests=true&includeRespondedTo=true";
	const qs = paginationQs ? paginationQs + "&" + filterQs : filterQs;
	return withPagination(
		ticket => TicketModel.fromServerData(ticket),
		() => apiReq("POST", "/v1/user/tickets/past?" + qs, searchParams || {})
	);
}

export async function getUserIntegrationsStatistics(): Promise<Map<string, IntegrationModel>> {
	const { data } = await apiReq("GET", "/v1/user/integrationsStatistics");
	return Map<string, IntegrationModel>(
		data
			.map((integration: unknown) => IntegrationModel.fromServerData(integration))
			.map((integration: IntegrationModel) => [integration.id, integration])
	);
}

interface IProfileData {
	givenName?: string;
	familyName?: string;
	integrationActors?: string[];
}

export async function changeProfile(creationData: IProfileData): Promise<UserModel> {
	const { data } = await apiReq("PUT", "/v1/user", creationData);
	return UserModel.fromServerData(data);
}
