import React, { useEffect, useMemo, useState } from "react";
import { Set } from "immutable";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { TicketModel } from "models/TicketModel";
import { Require } from "types/utilTypes";
import { notEmpty } from "utils/comparison";
import { UserNode } from "components/common/Nodes/UserNode";
import { UserCard } from "components/common/UserCard";
import { TooltipOnOverflow } from "components/ui/TooltipOnOverflow";
import { useApprovalFlowsWebhooksMap } from "hooks/useApprovalFlowsWebhooksMap";
import { WebhookChip } from "components/common/WebhookChip";
import { TitleTooltip } from "components/ui/TitleTooltip";
import { StaticChip } from "components/ui/chips/StaticChip";
import { useMultiUsers } from "hooks/useMultiUsers";
import { useStyles } from "./styles";

type TUsersProps = {
	userIds: string[];
};

type TRequestProps = {
	request: Require<TicketModel, "approvalRequests">;
};

type TProps = TUsersProps | TRequestProps;

const useApprovers = (props: TProps) => {
	const [approverUserIds, setApproverUserIds] = useState<Set<string>>(Set());
	const [approverWebhookIds, setApproverWebhookIds] = useState<Set<string>>(Set());
	const approvalFlowsWebhooks = useApprovalFlowsWebhooksMap();
	const users = useMultiUsers(approverUserIds.toArray());

	useEffect(() => {
		let ids: string[] = [];
		if ("userIds" in props) {
			ids = ids.concat(props.userIds);
		}

		if ("request" in props) {
			const activeRequest = props.request.approvalRequests.find(approvalRequest => approvalRequest.active);
			if (activeRequest?.approvalEntities) {
				const approversIds = activeRequest.approvalEntities.flatMap(approvalEntity =>
					approvalEntity.approverIds.toArray()
				);
				ids = ids.concat(approversIds.toArray());

				setApproverWebhookIds(
					activeRequest.approvalEntities
						.filter(entity => {
							if (entity.type !== "Webhook" || !entity.identifier) return null;
							return !(entity.approverIds.size && !entity.approverIds.includes(entity.identifier));
						})
						.map(entity => entity.identifier!)
						.toSet()
				);
			}
		}

		setApproverUserIds(Set(ids));
	}, [props]);

	const approverUsers = useMemo(() => {
		if (!users?.size || !approverUserIds.size) return [];
		return Array.from(approverUserIds)
			.map(userId => users.get(userId))
			.filter(notEmpty);
	}, [approverUserIds, users]);

	const approverWebhooks = useMemo(() => {
		if (!approvalFlowsWebhooks || !approverWebhookIds.size) return [];
		return Array.from(approverWebhookIds)
			.map(webhookId => approvalFlowsWebhooks.get(webhookId))
			.filter(notEmpty);
	}, [approvalFlowsWebhooks, approverWebhookIds]);

	return { approverUsers, approverWebhooks };
};

const DEFAULT_SHOWN = 2;

export const RequestApprovers: FC<TProps> = props => {
	const { className, innerRef } = props;
	const [shownApprovers] = useState(DEFAULT_SHOWN);
	const { t } = useTranslation();
	const { approverUsers, approverWebhooks } = useApprovers(props);
	const classes = useStyles();

	const shownApproverNodes = useMemo(() => {
		const approversCount = approverUsers.length + approverWebhooks.length;
		const detailedUserNodes = approverUsers.map(user => <UserCard key={user.id} user={user} />);
		const detailedWebhookNodes = approverWebhooks.map(webhook => (
			<WebhookChip key={webhook.id} displayName={webhook.name} variant="regular" />
		));
		const detailedNodes = [...detailedUserNodes, ...detailedWebhookNodes];
		const maxSlice = approversCount > shownApprovers ? shownApprovers - 1 : shownApprovers;
		const shownDetailedNodes = detailedNodes.slice(0, maxSlice);
		const hiddenDetailedNodes = detailedNodes.slice(maxSlice);

		let tooltipHeader = null;

		if (hiddenDetailedNodes.length !== 0) {
			tooltipHeader = (
				<div className={classes.tooltipHeader}>
					<TooltipOnOverflow textVariant="body_sb" content={t("common.requestBar.requestApprovers.title")} />
					<StaticChip size="small" variant="regular">
						{t("number", { value: hiddenDetailedNodes.length })}
					</StaticChip>
				</div>
			);
		}
		const tooltipContent =
			hiddenDetailedNodes.length > 0 ? <div className={classes.tooltipContent}>{hiddenDetailedNodes}</div> : null;
		const hiddenApproversCount = approversCount - maxSlice;
		const hiddenUsersNode =
			hiddenApproversCount > 0 ? (
				<TitleTooltip key="tooltip" header={tooltipHeader} body={tooltipContent}>
					<UserNode content={`+${hiddenApproversCount}`} />
				</TitleTooltip>
			) : null;
		return [...shownDetailedNodes, hiddenUsersNode];
	}, [approverUsers, approverWebhooks, shownApprovers, classes.tooltipContent, t, classes.tooltipHeader]);

	return (
		<div className={classNames(classes.container, className)} ref={innerRef}>
			<TooltipOnOverflow textVariant="text_sm_sb" content={t("common.requestBar.requestApprovers.title")} />
			{shownApproverNodes}
		</div>
	);
};
