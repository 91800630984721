import { Trans, useTranslation } from "react-i18next";
import React, { useCallback, useMemo } from "react";
import { Map } from "immutable";
import { Typography } from "components/ui/legacy/Typography";
import { usePrerequisitePermission } from "hooks/usePrerequisitePermission";
import { Button } from "components/ui/Button";
import { AddIcon } from "components/ui/Icons/AddIcon";
import { CommonAccordion } from "components/common/CommonAccordion";
import { useIsOpenState } from "hooks/useIsOpenState";
import { useOpenGlobalErrorModal } from "hooks/useGlobalError";
import { LoadingDots } from "components/ui/LoadingDots";
import { PrerequisitePermissionClauseModel } from "models/PrerequisitePermissionClauseModel";
import { PrerequisiteIcon } from "components/ui/Icons/PrerequisiteIcon";
import { useStopPropagation } from "hooks/useStopPropagation";
import { useStyles } from "./styles";
import { PrerequisitePermissionClause } from "./PrerequisitePermissionClause";
import { AddRoleFormModal } from "./AddRoleFormModal";
import type { TAssetTypes, TClauseCreation } from "types/prerequisitePermissions";

interface IProps {
	prerequisitePermissionId: string | null;
	afterAction: (id: string | null) => void;
	asset: {
		prohibitedIds?: {
			integrationResourceIds?: string[] | null;
			integrationResourceRoleIds?: string[] | null;
		};
		id: string;
		name: string;
		type: TAssetTypes;
	};
}

export const PrerequisitePermissionsBlock: FC<IProps> = ({ prerequisitePermissionId, asset, afterAction }) => {
	const classes = useStyles();
	const { t } = useTranslation();
	const {
		loading,
		prerequisitePermissionClauses = Map<string, PrerequisitePermissionClauseModel>(),
		createPrerequisitePermission,
		createPrerequisiteClause,
		loadPrerequisitePermission,
		deletePrerequisitePermission,
		createPrerequisiteRole,
		updatePrerequisiteRole,
		deletePrerequisiteRole,
		deletePrerequisiteClause
	} = usePrerequisitePermission(prerequisitePermissionId);
	const createClauseModal = useIsOpenState();
	const openGlobalErrorModal = useOpenGlobalErrorModal();

	const handleOnAdd = useStopPropagation(createClauseModal.open);

	const onSubmit = useCallback(
		async (data: TClauseCreation) => {
			try {
				if (prerequisitePermissionId) {
					await createPrerequisiteClause(data);
				} else {
					const permission = await createPrerequisitePermission({ clauses: [data] }, asset.id, asset.type);
					await afterAction(permission.id);
				}
			} catch (error) {
				openGlobalErrorModal(error as Error);
			}
		},
		[
			prerequisitePermissionId,
			createPrerequisiteClause,
			createPrerequisitePermission,
			asset,
			afterAction,
			openGlobalErrorModal
		]
	);

	const onRemove = useCallback(
		async (prerequisitePermissionClauseId: string) => {
			if (prerequisitePermissionClauses && prerequisitePermissionClauses.size > 0) {
				if (prerequisitePermissionClauses.size === 1) {
					await deletePrerequisitePermission(asset.id, asset.type);
					await afterAction(null);
				} else {
					await deletePrerequisiteClause(prerequisitePermissionClauseId);
				}
			}
		},
		[afterAction, asset, deletePrerequisiteClause, deletePrerequisitePermission, prerequisitePermissionClauses]
	);

	const clauses = useMemo(() => {
		const values = prerequisitePermissionClauses?.values();
		return values ? Array.from(values) : [];
	}, [prerequisitePermissionClauses]);

	return (
		<CommonAccordion
			rounded
			title={
				<div className={classes.titleContainer}>
					<Typography variant="h3" prefixIcon={<PrerequisiteIcon />}>
						{t("prerequisitePermissions.title")}
					</Typography>
					<Button variant="secondary" size="medium" prefix={<AddIcon />} onClick={handleOnAdd}>
						{t("buttons.add")}
					</Button>
				</div>
			}
			detailsClassname={classes.detailsContainer}>
			<AddRoleFormModal
				onSavePermission={onSubmit}
				type="clause"
				isOpen={createClauseModal.isOpen}
				onClose={createClauseModal.close}
				prohibitedIds={asset.prohibitedIds}
			/>
			<div className={classes.description}>
				<Typography>
					<Trans
						i18nKey={`prerequisitePermissions.${asset.type}.description`}
						values={{
							name: asset.name
						}}
						components={{ bold: <b /> }}
					/>
				</Typography>
				<Typography variant="small">{t("prerequisitePermissions.setAsDefaultDescription")}</Typography>
			</div>

			{loading ? (
				<LoadingDots className={classes.loading} />
			) : (
				<div>
					{clauses.map(clause => (
						<PrerequisitePermissionClause
							key={clause.id}
							clause={clause}
							onUpdate={loadPrerequisitePermission}
							className={classes.clauseRow}
							singleClause={clauses.length === 1}
							onRoleCreation={createPrerequisiteRole}
							onRoleUpdate={updatePrerequisiteRole}
							onClauseRemove={onRemove}
							onRoleRemove={deletePrerequisiteRole}
							prohibitedIds={asset.prohibitedIds}
						/>
					))}
				</div>
			)}
		</CommonAccordion>
	);
};
