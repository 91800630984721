import React from "react";
import get from "lodash/get";
import { ChevronUpIcon } from "components/ui/Icons/ChevronUpIcon";
import { CloseIcon } from "components/ui/Icons/CloseIcon";
import { LoadingSpinner } from "components/ui/LoadingSpinner";
import { ChevronDownIcon } from "components/ui/Icons/ChevronDownIcon";
import { IconButton } from "components/ui/IconButton";
import { IntegrationOption } from "components/ui/Select/components/selectOptions/IntegrationOption";
import { RoleOption } from "components/ui/Select/components/selectOptions/RoleOption";
import { UserOption } from "components/ui/Select/components/selectOptions/UserOption";
import { TextOption } from "components/ui/Select/components/selectOptions/TextOption";
import { ResourceOption } from "components/ui/Select/components/selectOptions/ResourceOption";
import { IntegrationResourceModel } from "models/IntegrationResourceModel";
import { DirectoryGroupModel } from "models/DirectoryGroupModel";
import { GroupNodeOption } from "components/ui/Select/components/selectOptions/GroupNodeOption";
import { DirectoryGroupOption } from "components/ui/Select/components/selectOptions/DirectoryGroupOption";
import { UserModel } from "models/UserModel";
import { UserNodeOption } from "components/ui/Select/components/selectOptions/UserNodeOption";
import { ApprovalAlgorithmModel } from "models/ApprovalAlgorithmModel";
import { WorkflowOption } from "components/ui/Select/components/selectOptions/WorkflowOption";
import { IntegrationResourceRoleModel } from "models/IntegrationResourceRoleModel";
import { ApplicationModel } from "models/ApplicationModel";
import { IntegrationModel } from "models/IntegrationModel";
import { ApplicationOption } from "components/ui/Select/components/selectOptions/ApplicationOption";
import { OnCallIntegrationScheduleModel } from "models/OnCallIntegrationScheduleModel";
import { OnCallIntegrationScheduleOption } from "components/ui/Select/components/selectOptions/OnCallIntegrationScheduleOption";
import { IntegrationActorModel } from "models/IntegrationActorModel";
import { AccountOption } from "components/ui/Select/components/selectOptions/AccountOption/AccountOption";
import { BundleModel } from "models/BundleModel";
import { BundleOption } from "components/ui/Select/components/selectOptions/BundleOption";
import { PermissionOption } from "components/ui/Select/components/selectOptions/PermissionOption";
import { basicSort } from "../sortUtils";
import type { TInherit } from "components/common/tables/IntegrationResourcesTable";
import type { TNewTicketOption } from "components/pages/NewTicketPage/components/NewTicketForm/types";

type TIdObject = { id: string };
type TIdentifierObject = { identifier: string };
type TWithId = TIdObject | TIdentifierObject | (TIdObject & TIdentifierObject);

export type TTextOption = "text" | { type: "text"; option: string };
export type TIntegrationOption = "integration" | { type: "integration"; option: IntegrationModel };
export type TIconPrefixOption = { type: "iconPrefix"; Icon?: IconComponent; text: string };
export type TUserOption =
	| "userNode"
	| "userWithEmail"
	| { type: "userWithEmail"; option: UserModel }
	| { type: "userNode"; option: UserModel | string };

export type TWorkflowOption = "workflow" | { type: "workflow"; option: ApprovalAlgorithmModel };

export type TRoleOption = "role" | { type: "role"; option: IntegrationResourceRoleModel };

export type TResourceOption = "resource" | { type: "resource"; option: IntegrationResourceModel };

export type TApplicationOption = "application" | { type: "application"; option: ApplicationModel };

export type TDirectoryGroupOption =
	| "directoryGroupNode"
	| "directoryGroup"
	| { type: "directoryGroup"; option: DirectoryGroupModel | string }
	| { type: "directoryGroupNode"; option: DirectoryGroupModel | string };

export type TOnCallSchedule =
	| "onCallSchedule"
	| { type: "onCallSchedule"; option: OnCallIntegrationScheduleModel | string };

export type TAccountOption = "account" | { type: "account"; option: IntegrationActorModel };
export type TBundleOption = "bundle" | { type: "bundle"; option: BundleModel };
export type TPermissionOption = "permission" | { type: "permission"; query: string | null; option: TNewTicketOption };

export type TFunctionOption<T> = (
	option: T
) =>
	| TTextOption
	| TIntegrationOption
	| TUserOption
	| TIconPrefixOption
	| TWorkflowOption
	| TRoleOption
	| TApplicationOption
	| TResourceOption
	| TOnCallSchedule
	| TAccountOption
	| TBundleOption
	| TPermissionOption
	| TDirectoryGroupOption;

export type TOptionType<T> =
	| TTextOption
	| TIntegrationOption
	| TUserOption
	| TIconPrefixOption
	| TWorkflowOption
	| TRoleOption
	| TApplicationOption
	| TResourceOption
	| TOnCallSchedule
	| TBundleOption
	| TAccountOption
	| TDirectoryGroupOption
	| TPermissionOption
	| TFunctionOption<T>;

export type TRenderOptionProps<T> = {
	option: T;
	optionType?: TOptionType<T>;
	disabled: boolean;
	optionKey: string;
	onSelect: (event: React.SyntheticEvent, value: T) => void;
	isSelected?: boolean;
};

const isWithLabel = (object: unknown): object is { label: string } =>
	typeof object === "object" && object !== null && "label" in object;

const isWithValue = (object: unknown): object is { value: string } =>
	typeof object === "object" && object !== null && "value" in object;

const isWithId = (object: unknown): object is TWithId =>
	typeof object === "object" && object !== null && ("id" in object || "identifier" in object);

const getId = (object: TWithId): string => get(object, "id", get(object, "identifier", ""));

export const getLabel = <T,>(option: T) => {
	if (typeof option === "string") {
		return option;
	}
	if (isWithLabel(option)) {
		return option.label;
	}
	throw new Error(
		"could not get label from option, please try to provide the object label property, or add your own `getOptionLabel` function"
	);
};

export const checkEquality = (a: unknown, b: unknown): boolean => {
	if (a === b) return true;
	if (isWithId(a) && isWithId(b)) {
		return getId(a) === getId(b);
	}
	if (isWithValue(a) && isWithValue(b)) {
		return a.value === b.value;
	}
	return false;
};

const isStringArray = (value: unknown[]): value is string[] => {
	return !!value?.[0] && typeof value[0] === "string";
};

export const sortOptions = (options: string[] | { label: string }[] | { value: string }[] | unknown[]) => {
	if (!options.length) return options;
	if (isStringArray(options)) {
		return basicSort(options, []);
	}
	if (isWithLabel(options[0])) {
		return basicSort(options as { label: string }[], ["label"]);
	}
	if (isWithValue(options[0])) {
		return basicSort(options as { value: string }[], ["value"]);
	}
	return null;
};

export const cleanString = (value: string) => value?.trim()?.toLowerCase() ?? "";

export const getOptionKey = <T,>(option: T, getOptionLabel?: (option: T) => string) => {
	if (typeof option === "string") return option;
	if (isWithId(option)) return getId(option);
	const label = getOptionLabel ? getOptionLabel(option) : undefined;
	return label || String(option);
};

export const getGroups = <T,>(options: T[], groupBy: (option: T) => string): Map<string, T[]> => {
	const groups = new Map<string, T[]>();
	options.forEach(option => {
		const group = groupBy(option);
		if (!groups.has(group)) {
			groups.set(group, []);
		}
		groups.get(group)?.push(option);
	});
	return groups;
};

export const renderOptionByType = <T,>({
	option,
	optionType,
	disabled,
	optionKey,
	onSelect,
	isSelected
}: TProps<TRenderOptionProps<T>>): React.JSX.Element | null => {
	switch (optionType) {
		case "integration":
			return (
				<IntegrationOption
					onSelect={onSelect as (event: React.SyntheticEvent, value: IntegrationModel) => void}
					integration={option as IntegrationModel}
					disabled={disabled}
					selected={isSelected}
					key={optionKey}
				/>
			);
		case "application":
			return (
				<ApplicationOption
					onSelect={onSelect as (event: React.SyntheticEvent, value: ApplicationModel) => void}
					application={option as ApplicationModel}
					disabled={disabled}
					selected={isSelected}
					key={optionKey}
				/>
			);
		case "role":
			return (
				<RoleOption
					onSelect={onSelect as (event: React.SyntheticEvent, value: IntegrationResourceRoleModel) => void}
					role={option as IntegrationResourceRoleModel}
					disabled={disabled}
					selected={isSelected}
					key={optionKey}
				/>
			);
		case "onCallSchedule":
			return (
				<OnCallIntegrationScheduleOption
					onSelect={onSelect}
					onCallIntegrationSchedule={option as OnCallIntegrationScheduleModel}
					disabled={disabled}
					value={option}
					selected={isSelected}
					key={optionKey}
				/>
			);
		case "workflow":
			return (
				<WorkflowOption
					onSelect={onSelect as (event: React.SyntheticEvent, value: ApprovalAlgorithmModel | TInherit) => void}
					workflow={option as ApprovalAlgorithmModel | TInherit}
					disabled={disabled}
					selected={isSelected}
					key={optionKey}
				/>
			);
		case "bundle":
			return (
				<BundleOption
					onSelect={onSelect}
					bundle={option as BundleModel}
					disabled={disabled}
					selected={isSelected}
					value={option}
					key={optionKey}
				/>
			);
		case "userNode":
			return (
				<UserNodeOption
					onSelect={onSelect}
					user={option as UserModel | string}
					disabled={disabled}
					value={option}
					selected={isSelected}
					key={optionKey}
				/>
			);
		case "userWithEmail":
			return (
				<UserOption
					onSelect={onSelect}
					user={option as UserModel}
					disabled={disabled}
					value={option}
					selected={isSelected}
					key={optionKey}
				/>
			);
		case "directoryGroup":
			return (
				<DirectoryGroupOption
					onSelect={onSelect}
					directoryGroup={option as DirectoryGroupModel | string}
					disabled={disabled}
					value={option}
					selected={isSelected}
					key={optionKey}
				/>
			);
		case "directoryGroupNode":
			return (
				<GroupNodeOption
					onSelect={onSelect}
					group={option as DirectoryGroupModel | string}
					disabled={disabled}
					selected={isSelected}
					value={option}
					key={optionKey}
				/>
			);
		case "resource":
			return (
				<ResourceOption
					onSelect={onSelect as (event: React.SyntheticEvent, value: IntegrationResourceModel) => void}
					resource={option as IntegrationResourceModel}
					disabled={disabled}
					selected={isSelected}
					key={optionKey}
				/>
			);
		case "account":
			return (
				<AccountOption
					onSelect={onSelect as (event: React.SyntheticEvent, value: IntegrationActorModel) => void}
					account={option as IntegrationActorModel}
					disabled={disabled}
					selected={isSelected}
					key={optionKey}
				/>
			);
		case "text":
			return (
				<TextOption
					onSelect={onSelect}
					option={option as string}
					disabled={disabled}
					value={option}
					selected={isSelected}
					key={optionKey}
				/>
			);

		default:
			return null;
	}
};

export const renderOptionByObject = <T,>({
	option,
	optionType,
	disabled,
	optionKey,
	onSelect,
	isSelected
}: TProps<TRenderOptionProps<T>>): React.JSX.Element | null => {
	if (typeof optionType !== "object") return null;
	switch (optionType.type) {
		case "integration":
			return (
				<IntegrationOption
					onSelect={onSelect as (event: React.SyntheticEvent, value: IntegrationModel) => void}
					integration={optionType.option || (option as IntegrationModel)}
					disabled={disabled}
					selected={isSelected}
					key={optionKey}
				/>
			);
		case "application":
			return (
				<ApplicationOption
					onSelect={onSelect as (event: React.SyntheticEvent, value: ApplicationModel) => void}
					application={optionType.option || (option as ApplicationModel)}
					disabled={disabled}
					selected={isSelected}
					key={optionKey}
				/>
			);
		case "role":
			return (
				<RoleOption
					onSelect={onSelect as (event: React.SyntheticEvent, value: IntegrationResourceRoleModel) => void}
					role={optionType.option || (option as IntegrationResourceRoleModel)}
					disabled={disabled}
					selected={isSelected}
					key={optionKey}
				/>
			);
		case "onCallSchedule":
			return (
				<OnCallIntegrationScheduleOption
					onSelect={onSelect as (event: React.SyntheticEvent, value: T) => void}
					onCallIntegrationSchedule={optionType.option || (option as OnCallIntegrationScheduleModel)}
					disabled={disabled}
					selected={isSelected}
					value={option}
					key={optionKey}
				/>
			);
		case "workflow":
			return (
				<WorkflowOption
					onSelect={onSelect as (event: React.SyntheticEvent, value: ApprovalAlgorithmModel | TInherit) => void}
					workflow={optionType.option || (option as ApprovalAlgorithmModel | TInherit)}
					disabled={disabled}
					selected={isSelected}
					key={optionKey}
				/>
			);
		case "bundle":
			return (
				<BundleOption
					onSelect={onSelect}
					bundle={optionType.option || (option as BundleModel)}
					disabled={disabled}
					selected={isSelected}
					value={option}
					key={optionKey}
				/>
			);
		case "userNode":
			return (
				<UserNodeOption
					onSelect={onSelect}
					user={optionType.option || (option as UserModel | string)}
					disabled={disabled}
					selected={isSelected}
					value={option}
					key={optionKey}
				/>
			);
		case "userWithEmail":
			return (
				<UserOption
					onSelect={onSelect}
					user={optionType.option || (option as UserModel)}
					disabled={disabled}
					value={option}
					selected={isSelected}
					key={optionKey}
				/>
			);
		case "directoryGroup":
			return (
				<DirectoryGroupOption
					onSelect={onSelect}
					directoryGroup={optionType.option || (option as DirectoryGroupModel | string)}
					disabled={disabled}
					value={option}
					selected={isSelected}
					key={optionKey}
				/>
			);
		case "directoryGroupNode":
			return (
				<GroupNodeOption
					onSelect={onSelect}
					group={optionType.option || (option as DirectoryGroupModel | string)}
					disabled={disabled}
					selected={isSelected}
					value={option}
					key={optionKey}
				/>
			);
		case "resource":
			return (
				<ResourceOption
					onSelect={onSelect as (event: React.SyntheticEvent, value: IntegrationResourceModel) => void}
					resource={optionType.option || (option as IntegrationResourceModel)}
					disabled={disabled}
					selected={isSelected}
					key={optionKey}
				/>
			);
		case "account":
			return (
				<AccountOption
					onSelect={onSelect as (event: React.SyntheticEvent, value: IntegrationActorModel) => void}
					account={optionType.option || (option as IntegrationActorModel)}
					disabled={disabled}
					selected={isSelected}
					key={optionKey}
				/>
			);
		case "iconPrefix":
			return (
				<TextOption
					onSelect={onSelect}
					option={optionType.text || (option as string)}
					PrefixIcon={optionType.Icon}
					disabled={disabled}
					value={option}
					selected={isSelected}
					key={optionKey}
				/>
			);
		case "permission":
			return (
				<PermissionOption
					onSelect={onSelect as (event: React.SyntheticEvent, value: TNewTicketOption) => void}
					option={optionType.option || (option as TNewTicketOption)}
					selected={isSelected}
					disabled={disabled}
					query={optionType.query}
					key={optionKey}
				/>
			);

		case "text":
			return (
				<TextOption
					onSelect={onSelect}
					option={optionType.option || (option as string)}
					disabled={disabled}
					value={option}
					selected={isSelected}
					key={optionKey}
				/>
			);

		default:
			return null;
	}
};

export const renderOption = <T,>({
	option,
	optionType,
	disabled,
	optionKey,
	onSelect,
	isSelected
}: TProps<TRenderOptionProps<T>>): React.JSX.Element | null => {
	if (typeof optionType === "function") {
		const functionType = optionType(option) as TOptionType<T>;
		const onSelectFunction = onSelect as (event: React.SyntheticEvent, value: T) => void;

		return renderOption({
			option,
			optionType: functionType,
			disabled,
			optionKey,
			onSelect: onSelectFunction,
			isSelected
		});
	}

	return typeof optionType !== "object"
		? renderOptionByType({ option, optionType, disabled, optionKey: optionKey, onSelect, isSelected })
		: renderOptionByObject({ option, optionType, disabled, optionKey: optionKey, onSelect, isSelected });
};

interface ISuffixOptions {
	disabled?: boolean;
	handleClear?: (event: React.MouseEvent) => void;
	handleClose?: () => void;
	handleOpen?: () => void;
	loading?: boolean;
	open?: boolean;
	showClear?: boolean;
	suffixClassName?: string;
	suffixClearClassName?: string;
	suffix?: React.ReactNode;
}

export const getSuffix = (options: ISuffixOptions) => {
	const {
		disabled,
		handleClear,
		handleClose,
		handleOpen,
		loading,
		open,
		showClear,
		suffixClassName,
		suffixClearClassName,
		suffix
	} = options;

	let suffixElement: React.ReactNode = (
		<IconButton size="small" disabled={disabled} onClick={handleOpen}>
			<ChevronDownIcon />
		</IconButton>
	);
	if (suffix || suffix === null) {
		suffixElement = suffix;
	}
	if (open && suffix !== null) {
		suffixElement = (
			<IconButton size="small" disabled={disabled} onClick={handleClose}>
				<ChevronUpIcon />
			</IconButton>
		);
	}
	if (loading) {
		suffixElement = <LoadingSpinner inline />;
	}

	let clearIcon = null;
	if (showClear && !disabled) {
		clearIcon = (
			<IconButton size="small" className={suffixClearClassName} onClick={handleClear}>
				<CloseIcon />
			</IconButton>
		);
	}
	return (
		<div className={suffixClassName}>
			{clearIcon}
			{suffixElement}
		</div>
	);
};

export type TRenderOption<T> = (option: T, index: number, disabled?: boolean) => React.ReactNode;
