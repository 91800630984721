import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	emptyState: {
		color: "var(--color-grey-600)",
		padding: "var(--spacing-x2, 8px) var(--spacing-x3, 12px)",
		border: "2px solid transparent",
		minHeight: "56px"
	}
});
